// @flow

import { validateZipCA } from 'src/helpers/formValidation'

const MAX_INTEGER = 2147483647

const normalizePhone = (value: any) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)})-${onlyNums.slice(3)}`
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`
}

const normalizeZip = (value: any) => {
  if (!value) {
    return value
  }

  const onlyStrings = value.replace(/[^\d-]/g, '')
  if (onlyStrings.length >= 10) {
    return onlyStrings.slice(0, 10)
  }
  return onlyStrings
}

// Canada
const normalizeZipCA = (value: any) => {
  if (!value) {
    return value
  }

  value = value.toUpperCase()

  if (value.length === 6 && validateZipCA(value) === '') {
    // to format: "A1A 1A1"
    value = value.slice(0, 3) + ' ' + value.slice(3)
  }

  if (value.length >= 7) {
    return value.slice(0, 7)
  }
  return value
}

const normalizeText = (value: string) => {
  if (!value) return value

  return value.replace(/^\s+/g, '').replace(/\s\s+/g, ' ')
}

const normalizeSize = (value: any) => {
  if (!value) {
    return value
  }
  return value.replace(/[^\d]/g, '')
}

const onlyDecimal = (value: any) => {
  value = value
    .replace(/[^0-9.]/g, '') // Remove all chars except numbers and .

  // Create an array with sections split by .
  const sections = value.split('.')

  // Remove any leading 0s apart from single 0
  if (sections[0] !== '0' && sections[0] !== '00' && sections[0] !== '') {
    sections[0] = sections[0].replace(/^0+/, '')
  } else {
    sections[0] = '0'
  }

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return sections[0] + '.' + sections[1].slice(0, 2)
    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf('.') !== -1) {
    return sections[0] + '.'
    // Otherwise, return only section
  } else {
    return sections[0]
  }
}

const onlyPositiveInteger = (value: any) => {
  let res = String(value).replace(/[^0-9.]/g, '').length > 0 ? onlyDecimal(value) : '0'
  res = parseInt(res, 10)
  return res > MAX_INTEGER ? MAX_INTEGER : res
}

const lessOrEqualThan = (max: any) => (value: any, previousValue: any, allValues: any) => {
  const res = String(value).replace(/[^0-9.]/g, '').length > 0 ? onlyDecimal(value) : '0'

  return +res >= +max ? +max : res
}

const normalizeWebsite = (value: any) => {
  if (!value && value.length === 0) {
    return null
  } else {
    return value
  }
}

export { normalizePhone, normalizeZip, normalizeSize, normalizeWebsite, normalizeText, onlyDecimal, onlyPositiveInteger, normalizeZipCA, lessOrEqualThan }
