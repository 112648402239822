// @flow
import styled from 'styled-components'

export const StyledPopoverContent = styled.div`
  max-width: 250px;
  padding: 20px;
  font-size: 14px;
  background: ${props => props.theme.main.colors.adminMessageBackground};

  .heading {
    margin-bottom: 5px;
  }
`
