// @flow

import React from 'react'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

// type
import type { FormSelectFieldPropsType } from './FormSelectFieldTypes'

const renderFromHelper = ({ touched, error }) => {
  if (touched && error) {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

const FormSelectField = ({
  // $FlowFixMe
  input,
  label,
  meta: { touched, error },
  children,
  animatedLabel,
  size,
  ...custom
}: FormSelectFieldPropsType) => (
  <FormControl error={touched && error} size={size}>
    {animatedLabel && <InputLabel
      htmlFor={custom.id}
      variant={custom.variant}
    >{animatedLabel}</InputLabel>}

    <Select
      native
      label={animatedLabel || label}
      {...input}
      {...custom}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
)
export default FormSelectField
