// @flow
import type { BasicRedux } from 'src/redux/actions/adminAccounts/adminAccountsTypes'
import {
  GET_ALL_ADMINS,
  GET_ADMIN,
  ENABLE_ADMIN,
  DISABLE_ADMIN,
  CREATE_ADMIN,
} from 'src/redux/actions/adminAccounts/adminAccounts'

export type AdminData = {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  zip: string,
  country: string,
  state: string,
  created_at: string,
  deleted_at: string
};

export type AdminsState = {
  isLoading: boolean,
  adminsData: AdminData[],
  adminsErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  adminsData: [],
  adminsErrors: null,
}

const adminAccounts = (state: AdminsState = initialState, action: BasicRedux): AdminsState => {
  switch (action.type) {
    case GET_ALL_ADMINS.REQUEST:
    case GET_ADMIN.REQUEST:
    case ENABLE_ADMIN.REQUEST:
    case DISABLE_ADMIN.REQUEST:
    case CREATE_ADMIN.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        adminsErrors: null,
      }
    }

    case GET_ADMIN.SUCCESS:
    {
      return {
        ...state,
        adminsData: updateArrayData(state.adminsData, action.payload),
        isLoading: false,
        adminsErrors: null,
      }
    }

    case DISABLE_ADMIN.SUCCESS:
    case ENABLE_ADMIN.SUCCESS:
    case CREATE_ADMIN.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        adminsErrors: null,
      }
    }

    case GET_ALL_ADMINS.SUCCESS:
    {
      return {
        ...state,
        adminsData: action.payload,
        isLoading: false,
        adminsErrors: null,
      }
    }

    case GET_ADMIN.FAILURE:
    case GET_ALL_ADMINS.FAILURE:
    case ENABLE_ADMIN.FAILURE:
    case DISABLE_ADMIN.FAILURE:
    case CREATE_ADMIN.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        adminsErrors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

function updateArrayData(arr: AdminData[], newData: AdminData) {
  arr = [...arr]
  let isNew = true
  arr.map(data => {
    if (data.id === newData.id) {
      data = newData
      isNew = false
    }
    return data
  })
  if (isNew) arr.push(newData)
  return arr
}

export default adminAccounts
