// @flow
import React, { useEffect, useState } from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'

import {
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core'

import { humanize } from 'src/helpers/valuesHumanizer'

import DataDifferenceTimelineItem from './fragments/DataDifferenceTimelineItem'
// styles
import { StyledWrapper } from './DataDifferenceTimelineStyles'

// types
import type { DataDifferenceTimelineTypes } from './DataDifferenceTimelineTypes'

const DataDifferenceTimeline = (props: DataDifferenceTimelineTypes) => {
  const { trackProps, data, dateProp } = props

  const [formattedRows, updateFormattedRows] = useState([])
  useEffect(() => {
    const result = []

    for (let i = 0; i < data.length - 1; i++) {
      const curr = data[i]
      const prev = data[i + 1]
      const changes = trackProps.map(({ prop, alias, format }) => {
        if (curr[prop] === prev[prop]) {
          return null
        }

        return {
          prop,
          name: alias || humanize(prop),
          from: format ? format(prev[prop]) : prev[prop],
          to: format ? format(curr[prop]) : curr[prop],
        }
      }).filter(Boolean)
      result.push({
        id: curr.id,
        changes,
        date: curr[dateProp],
      })
    }

    updateFormattedRows(result)
  }, [trackProps, data, dateProp])

  const [availablePropsForSelect, updateAvailablePropsForSelect] = useState([])
  useEffect(() => {
    const props = new Set()
    formattedRows.forEach(({ changes }) => {
      changes.forEach(({ prop }) => props.add(prop))
    })

    const availableProps = trackProps.filter(({ prop }) => props.has(prop)).map(({ prop, alias }) => ({
      prop,
      name: alias || humanize(prop),
    }))

    updateAvailablePropsForSelect(availableProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedRows])

  const [selectedFilterValues, updSelectedFilterValues] = useState([])
  const handleChangeMultiple = event => {
    const { value } = event.target
    const result = []
    for (let i = 0, l = value.length; i < l; i += 1) {
      result.push(value[i])
    }
    updSelectedFilterValues(result)
  }

  const [filteredRows, updFilteredRows] = useState([])
  useEffect(() => {
    if (!selectedFilterValues.length) {
      updFilteredRows(formattedRows)
      return
    }

    const propsToShow = new Set()
    selectedFilterValues.forEach(({ prop }) => {
      propsToShow.add(prop)
    })

    const rowsToShow = formattedRows.filter(({ changes }) => changes.some(({ prop }) => propsToShow.has(prop)))

    updFilteredRows(rowsToShow)
  }, [formattedRows, selectedFilterValues])

  return (
    <StyledWrapper>

      <div className="data-diff-filter">
        <FormControl>
          <InputLabel id="data-diff-checkbox-label">Filter By Properties</InputLabel>
          <Select
            labelId="data-diff-checkbox-label"
            id="data-diff-checkbox"
            multiple
            value={selectedFilterValues}
            onChange={handleChangeMultiple}
            input={<Input />}
            renderValue={selected => selected.map(v => v.name).join(', ')}
            style={{ width: 250 }}
          >
            {availablePropsForSelect.map(data => (
              <MenuItem key={data.prop} value={data}>
                <Checkbox color="primary" checked={selectedFilterValues.indexOf(data) > -1} />
                <ListItemText primary={data.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <Timeline align="left">
        {filteredRows.map(({ id, date, changes }) => (
          <TimelineItem key={id}>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <DataDifferenceTimelineItem
                date={date}
                changesList={changes}
              />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>

    </StyledWrapper>
  )
}

export default DataDifferenceTimeline
