// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import config from 'src/config/formsConfig'
import { INVITE_MEMBER, inviteMember, getMembersListByLeagueId } from 'src/redux/actions/members/members'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchInviteMember(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { league_id, ...values },
    } = yield take(INVITE_MEMBER.REQUEST)
    const formId = config.inviteUserForm.id

    try {
      yield call(http, 'admin/league/member', {
        body: JSON.stringify({
          ...values,
          league_id: +league_id,
        }),
        method: 'POST',
      })

      yield put(inviteMember.success())
      yield put(getMembersListByLeagueId.request({ league_id }))
    } catch (e) {
      yield put(inviteMember.error(e.message))
      yield put(stopSubmit(formId, {}))
    }
  }
}

export default [fork(watchInviteMember)]
