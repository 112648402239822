// @flow

import getPaymentMethods from './getPaymentMethods'
import deletePaymentMethod from './deletePaymentMethod'
import getCurrencyRate from './getCurrencyRate'

export default [
  ...getPaymentMethods,
  ...deletePaymentMethod,
  ...getCurrencyRate,
]
