// @flow

import { stopSubmit, SubmissionError } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import { SUBMIT_INSURANCE_STATUS, submitInsuranceStatus, getInsurance } from 'src/redux/actions/insurances/insurances'
import http from 'src/services/http'
import { showToast } from 'src/redux/actions/ui/ui'
import moment from 'moment'
import config from 'src/config/formsConfig'
import { cloneDeep } from 'lodash'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSubmitInsuranceStatus(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { values, insuranceId },
    } = yield take(SUBMIT_INSURANCE_STATUS.REQUEST)
    const formId = config.insuranceForm.id
    try {
      const formValues = cloneDeep(values)
      formValues.start_date = moment.utc(formValues.start_date).startOf('day')
      formValues.end_date = moment.utc(formValues.end_date).endOf('day')
      const { leagueId, ...sendData } = formValues
      if (moment(formValues.start_date) > moment(formValues.end_date)) {
        const message = 'The insurance can\'t start after it expires'
        yield put(showToast.success({ title: message, messageType: 'error' }))
        yield put(stopSubmit(formId))
        throw new SubmissionError({
          start_date: message,
        })
      }
      yield call(http, `admin/insurance/${insuranceId}`, {
        body: JSON.stringify({
          ...sendData,
        }),
        method: 'PUT',
      })
      yield put(getInsurance.request({ insuranceId }))
      yield put(submitInsuranceStatus.success())
    } catch (e) {
      yield put(submitInsuranceStatus.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchSubmitInsuranceStatus)]
