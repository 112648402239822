// @flow
import React from 'react'
import { StyledCheckbox, StyledError } from './checkboxStyles'
import type { CheckboxTypes } from './CheckboxTypes'

const Checkbox = ({
  labelText, input,
  meta: { touched, error },
  placeholder,
  validationErrors,
  id,
  // $FlowFixMe
  ...rest
}: CheckboxTypes) => {
  let isChecked = false
  let errorBlock = null
  if (typeof input.value === 'boolean') {
    isChecked = input.value
  } else if (input.value === 'true') {
    isChecked = true
  }

  if (touched && (error || (validationErrors && validationErrors.errors[id]))) {
    errorBlock = (
      <div>
        <StyledError className="error-message">{error || validationErrors.message}</StyledError>
      </div>
    )
  }
  return (
    <>
      <StyledCheckbox>
        <label className="checkbox-container">
          {labelText}
          <input onChange={input.onChange} checked={isChecked} type="checkbox" {...rest} />
          <span className="checkmark" />
        </label>
      </StyledCheckbox>
      {errorBlock}
    </>
  )
}

export const SimpleCheckbox = ({ labelText, onChange, isChecked, isDisabled, ...rest }: any) => {
  return (
    <StyledCheckbox>
      <label className="checkbox-container">
        {labelText}
        <input onChange={onChange} checked={isChecked} disabled={isDisabled} type="checkbox" {...rest} />
        <span className="checkmark" />
      </label>
    </StyledCheckbox>
  )
}

export default Checkbox
