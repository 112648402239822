import styled from 'styled-components'

export const StyledLayout = styled.div`
    .layout {
        display: flex;
        
        >div {
          &:first-child {
            min-width: 280px;
          }
        }
    }
    
    .wrapper {
        flex-grow: 1;
    }
    
    .content {
        padding: 20px;
    }
    
    @media (max-width: 959px) {
        .layout {
            > div:first-child {
                min-width: 0;
            }
        }
    }
`
