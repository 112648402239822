// @flow

import React from 'react'
import { Box } from '@material-ui/core'
import { Sidebar, Header, ToastMessage } from 'src/common/components'
import { withUi } from 'src/decorators'
// styles
import { StyledLayout } from './layoutStyles'

// types
import type { LayoutPropTypes } from './LayoutTypes'

const Layout = ({ children, ui: { toast } }: LayoutPropTypes) => {
  const { title, messageType } = toast || {}
  return (
    <StyledLayout>
      <Box className="layout">
        <Sidebar />
        <main className="wrapper">
          <Header />
          <div className="content">
            {children}
          </div>
        </main>
      </Box>
      {title && messageType && (
        <ToastMessage
          title={title}
          messageType={messageType}
        />
      )}
    </StyledLayout>
  )
}

export default withUi(Layout)
