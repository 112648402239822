// @flow
import type { PublicLeaguesActionTypes } from 'src/redux/actions/publicLeagues/publicLeaguesTypes'
import { GET_PUBLIC_LEAGUES, UDATE_PUBLIC_LEAGUE } from 'src/redux/actions/publicLeagues/publicLeagues'

export type LeagueActiveSeasonType = {
    id: number,
    end_date: string,
    start_date: string,
    registration_start_date: string,
    registration_end_date: string
};

export type PublicLeaguesDataItem = {
    has_boys: boolean,
    has_coed: boolean,
    has_girls: boolean,
    has_insurance: string,
    id: number,
    latitude: string,
    longitude: string,
    max_age: number,
    min_age: number,
    min_grade: number,
    max_grade: number,
    name: string,
    roles: string,
    website: ?string,
    phone: ?string,
    distance: string,
    league_zip: string,
    league_city: string,
    league_state: string,
    league_address_1: string,
    league_county: string,
    billing_address_1: string,
    billing_city: string,
    billing_state: string,
    billing_zip: string,
    billing_county: string,
    shipping_address_1: string,
    shipping_city: string,
    shipping_state: string,
    shipping_zip: string,
    shipping_county: string,
    practice_address_id: number,
    approved: string,
    updated_at: string,
    active: ?boolean,
    active_seasons: LeagueActiveSeasonType[],
    program_logo?: string
};
export type PublicLeaguesData = PublicLeaguesDataItem[];

export type PublicLeaguesState = {
    isLoading: boolean,
    publicLeaguesData: PublicLeaguesData,
    myLeaguesErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  publicLeaguesData: [],
  publicLeaguesErrors: null,
}

const publicLeagues = (state: PublicLeaguesState = initialState, action: PublicLeaguesActionTypes): PublicLeaguesState => {
  switch (action.type) {
    case GET_PUBLIC_LEAGUES.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }
    case GET_PUBLIC_LEAGUES.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        publicLeaguesData: action.payload,

      }
    }
    case UDATE_PUBLIC_LEAGUE:
    {
      const updatedLeaguees = Array.isArray(state.publicLeaguesData)
        ? state.publicLeaguesData.map(item => {
          if (item.id !== action.payload.id) {
            return {
              ...item,
              active: false,
            }
          }

          return {
            ...item,
            active: true,
          }
        })
        : []
      return {
        ...state,
        isLoading: false,
        publicLeaguesData: updatedLeaguees,

      }
    }

    case GET_PUBLIC_LEAGUES.FAILURE:
    {
      return { ...state, isLoading: false, publicLeaguesErrors: action.payload }
    }
    default: {
      return state
    }
  }
}

export default publicLeagues
