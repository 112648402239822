// @flow

import type { ShowToastActionTypes } from 'src/redux/actions/ui/uiTypes'
import { SHOW_TOAST, HIDE_TOAST } from 'src/redux/actions/ui/ui'

export type ToastData = {
    title: string,
    messageType: string
};

export type UiState = {
  toast?: ToastData
};

const initialState = {
  toast: {},
}

const ui = (state: UiState = initialState, action: ShowToastActionTypes): UiState => {
  switch (action.type) {
    case SHOW_TOAST.SUCCESS:
    {
      return {
        ...state,
        toast: { ...action.payload },
      }
    }
    case HIDE_TOAST.SUCCESS:
    {
      return {
        ...state,
        toast: {},
      }
    }
    default:
      return state
  }
}

export default ui
