// @flow
import React from 'react'
import { StyledOutlineButton } from './buttonOutlineStyles'
import type { ButtonOutlinePropsType } from './ButtonOutlineTypes'

const ButtonOutline = ({
  buttonText,
  disabled,
  size,
  type = 'button',
  action = () => {},
}: ButtonOutlinePropsType) => {
  return (
    <StyledOutlineButton type={type} onClick={action} size={size} disabled={disabled}>
      {buttonText}
    </StyledOutlineButton>
  )
}

export default ButtonOutline
