import http from 'src/services/http'

export const fetchLeaguePrograms = async() => {
  const response = await http('admin/programs', {
    method: 'GET',
  })
  return response?.data
}

export const fetchLeagueItemTypes = async() => {
  const response = await http('admin/itemTypes', {
    method: 'GET',
  })
  return response?.data
}
