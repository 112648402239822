// @flow
import { EDIT_SEASON_FREE_SHIPPING_COUNT, GET_LEAGUE_SEASONS, EDIT_LEAGUE_SEASON, DELETE_LEAGUE_SEASON } from 'src/redux/actions/seasons/seasons'

import type { SeasonsActionTypes } from 'src/redux/actions/seasons/seasonsTypes'
import type { VendorType, OrderModeType } from 'src/common/constants.types'

export type UnpaidOrdersType = {
  id: number,
  vendor: VendorType,
  mode: OrderModeType
};

export type SeasonData = {
  id: number,
  league_id: number,
  name: string,
  start_date: string,
  end_date: string,
  registration_start_date: string,
  registration_end_date: string,
  min_age: number,
  max_age: number,
  max_grade: number,
  min_grade: number,
  age_grade_range: boolean,
  has_boys: boolean,
  has_coed: boolean,
  has_girls: boolean,
  unpaid_orders: UnpaidOrdersType[]
};

export type SeasonState = {
  isLoadingSeasons: boolean,
  seasonData?: SeasonData,
  seasonErrors?: ?{ [key: string]: any } | ?string,
  seasonsData: SeasonData[]
};

const initialState = {
  isLoadingSeasons: false,
  seasonsErrors: null,
  seasonsData: [],
}

const seasons = (state: SeasonState = initialState, action: SeasonsActionTypes): SeasonState => {
  switch (action.type) {
    case EDIT_SEASON_FREE_SHIPPING_COUNT.REQUEST:
    case EDIT_LEAGUE_SEASON.REQUEST:
    case DELETE_LEAGUE_SEASON.REQUEST:
    {
      return {
        ...state,
        isLoadingSeasons: true,
        seasonsErrors: null,
      }
    }

    case GET_LEAGUE_SEASONS.SUCCESS:
    {
      return {
        ...state,
        isLoadingSeasons: false,
        seasonsData: action.payload,
        seasonsErrors: null,
      }
    }

    case EDIT_LEAGUE_SEASON.SUCCESS:
    case DELETE_LEAGUE_SEASON.SUCCESS:
    {
      return {
        ...state,
        isLoadingSeasons: false,
        seasonsErrors: null,
      }
    }

    case EDIT_SEASON_FREE_SHIPPING_COUNT.FAILURE:
    case EDIT_LEAGUE_SEASON.FAILURE:
    case DELETE_LEAGUE_SEASON.FAILURE:
    {
      return {
        ...state,
        isLoadingSeasons: false,
        seasonsErrors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default seasons
