// @flow

import { requestTypeCreator } from 'src/helpers'

import type {
  BasicRedux,
} from './globalSettingsTypes.js'

export const GET_LATEST_AGREEMENT_VERSION = requestTypeCreator('GET_LATEST_AGREEMENT_VERSION')
export const RELEASE_NEW_AGREEMENT = requestTypeCreator('RELEASE_NEW_AGREEMENT')

export const getLatestAgreementData = {
  error: (payload: any): BasicRedux => ({ type: GET_LATEST_AGREEMENT_VERSION.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_LATEST_AGREEMENT_VERSION.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_LATEST_AGREEMENT_VERSION.SUCCESS, payload }),
}

export const releaseNewAgreementVersion = {
  error: (payload: any): BasicRedux => ({ type: RELEASE_NEW_AGREEMENT.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: RELEASE_NEW_AGREEMENT.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: RELEASE_NEW_AGREEMENT.SUCCESS, payload }),
}
