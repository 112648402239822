// @flow
import React from 'react'
import insuranceApprovalStatuses from 'src/config/insuranceApprovalStatuses'
import FilesList from '../filesList'
import RenderIf from '../renderIf'
import AdminInfo from '../adminInfo'

// styles
import { StyledInsurance } from './insuranceCardStyles'

// types
import type { InsuranceCardTypes } from './InsuranceCardTypes'

const InsuranceCard = (props: InsuranceCardTypes) => {
  const { last_updated_admin, status_updated_at } = props
  return (
    <StyledInsurance status={props.cardColor ? props.cardColor : props.status && props.status.toLowerCase()}>
      <div className="insurance-layout" onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        props.history.push(props.link)
      }}>
        <div className="insurance-left">
          <div className="heading">
            <h4>{props.leagueName}</h4>
            {props.statusesNode
              ? <span className="status">{props.status && props.statusesNode}</span>
              : <span className="status">{props.status && props.status.toLowerCase()}</span>
            }
            <RenderIf condition={last_updated_admin}>
              <div className="info-wrapper">
                <AdminInfo
                  last_updated_admin={last_updated_admin}
                  status_updated_at={status_updated_at}
                />
              </div>
            </RenderIf>
          </div>
          { Boolean(props.error) && <div className="d-flex">
            {props.error.renderError}
          </div> }
          { !props.error && <div className="d-inline-block"
            onClick={async e => {
              e.preventDefault()
              e.stopPropagation()
              await props.checkFile({ id: props.id })
            }}>
            <FilesList
              files={props.files}
              checkFile={props.checkFile}
              insuranceId={props.id}
            />
          </div> }
          <div className="dates">
            {Boolean(props.vendor) &&
            <div className="date">
              <span className="label">Type: </span>
              <span className="value capitalize">{props.type.toLowerCase()}</span>
            </div>
            }
            <div className="date">
              <span className="label">From </span>
              <span className="value">{props.startDate}</span>
            </div>
            <div className="date">
              <span className="label">to </span>
              <span className="value">{props.endDate}</span>
            </div>
          </div>
          <div className="dates">
            <div className="date">
              <span className="label">Submitted date:</span>
              <span className="value">{props.submittedDate}</span>
            </div>
            {props.approvedDate && props.status !== insuranceApprovalStatuses.PENDING && (
              <div className="date">
                <span className="label">{props.status === insuranceApprovalStatuses.APPROVED ? 'Approved date:' : 'Declined date:'}</span>
                <span className="value">{props.approvedDate}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledInsurance>
  )
}

export default InsuranceCard
