// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import {
  GET_LATEST_AGREEMENT_VERSION,
  RELEASE_NEW_AGREEMENT,
  getLatestAgreementData,
  releaseNewAgreementVersion,
} from 'src/redux/actions/globalSettings/globalSettings'
import http from 'src/services/http'
// import { history } from "src/services/history"

import { showToast } from 'src/redux/actions/ui/ui'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetLatestAgreement(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_LATEST_AGREEMENT_VERSION.REQUEST)
    try {
      const { data } = yield call(
        http,
        'admin/agreement-version/latest-agreement',
        {
          method: 'GET',
        },
      )
      yield put(getLatestAgreementData.success(data))
    } catch (e) {
      yield put(getLatestAgreementData.error())
      yield put(
        showToast.success({
          title: e.message || 'Network Error',
          messageType: 'error',
        }),
      )
    }
  }
}

function * watchReleaseNewAgreement(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(RELEASE_NEW_AGREEMENT.REQUEST)
    try {
      yield call(http, 'admin/agreement-version', {
        method: 'POST',
      })
      yield put(getLatestAgreementData.request())
      yield put(releaseNewAgreementVersion.success())
    } catch (e) {
      yield put(releaseNewAgreementVersion.error())
      yield put(
        showToast.success({
          title: e.message || 'Network Error',
          messageType: 'error',
        }),
      )
    }
  }
}

export default [fork(watchGetLatestAgreement), fork(watchReleaseNewAgreement)]
