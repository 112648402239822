// @flow
import { requestTypeCreator } from 'src/helpers'
import type { BasicReduxActionTypes } from './resourcesTypes'

export const GET_RESOURCES = requestTypeCreator('GET_RESOURCES')
export const UPDATE_RESOURCES = requestTypeCreator('UPDATE_RESOURCES')

export const getResources = {
  error: (payload: any): BasicReduxActionTypes => ({ type: GET_RESOURCES.FAILURE, payload }),
  request: (payload: any): BasicReduxActionTypes => ({ type: GET_RESOURCES.REQUEST, payload }),
  success: (payload: any): BasicReduxActionTypes => ({ type: GET_RESOURCES.SUCCESS, payload }),
}

export const updateResources = {
  error: (payload: any): BasicReduxActionTypes => ({ type: UPDATE_RESOURCES.FAILURE, payload }),
  request: (payload: any): BasicReduxActionTypes => ({ type: UPDATE_RESOURCES.REQUEST, payload }),
  success: (payload: any): BasicReduxActionTypes => ({ type: UPDATE_RESOURCES.SUCCESS, payload }),
}
