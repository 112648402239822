// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import {
  CHECK_FILE_INSURANCE,
  checkFileInsurance,
} from 'src/redux/actions/insurances/insurances'
import http from 'src/services/http'
import { showToast } from 'src/redux/actions/ui/ui'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchCheckFileInsurance(): Generator<IOEffect, void, any> {
  while (true) {
    const data = yield take(CHECK_FILE_INSURANCE.REQUEST)
    const { payload: { insuranceId, fileId, name } } = data
    try {
      const { data } = yield call(http, `admin/insurance/${insuranceId}/file/${fileId}`, {
        method: 'GET',
      })

      const storeData = {
        ...data,
        name,
      }

      yield put(checkFileInsurance.success(storeData))
    } catch (e) {
      yield put(checkFileInsurance.error(e.message))
      yield put(showToast.success({ title: e.message, messageType: 'error' }))
    }
  }
}

export default [fork(watchCheckFileInsurance)]
