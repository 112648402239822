
import { useState, useCallback } from 'react'

const getInitState = () => ({
  data: null,
  errorData: null,
  isError: false,
  isSuccessful: false,
  isLoading: false,
})

// mb add concurrency resolving / canceling feature?
export default function() {
  const [state, setState] = useState(getInitState())

  const requestHandler = useCallback(async request => {
    setState({
      ...getInitState(),
      isLoading: true,
    })
    try {
      const { data } = await request()

      setState({
        data,
        errorData: null,
        isError: false,
        isSuccessful: true,
        isLoading: false,
      })
    } catch (err) {
      setState({
        errorData: err,
        data: null,
        isError: true,
        isSuccessful: false,
        isLoading: false,
      })
    }
  }, [])

  const resetState = useCallback(() => {
    setState(getInitState())
  }, [])

  return [state, requestHandler, resetState]
}
