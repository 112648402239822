// @flow
import { all } from 'redux-saga/effects'
import toastMessage from './toastMessage'
import login from './login'
import logout from './logout'
import connectTo from './connectTo'
import editUser from './editUser'
import setPassword from './setPassword'
import changePassword from './changePassword'
import getAccounts from './getAccounts'
import getAdminAccounts from './getAdminAccounts'
import manageAdminAccount from './manageAdminAccount'
import getUserRoles from './getUserRoles'
import getLeagues from './getLeagues'
import getUserLeagues from './getUserLeagues'
import getAccount from './getAccount'
import editAccount from './editAccount'
import changeUserEmail from './changeUserEmail'
import getAccountChanges from './getAccountChanges'
import getInsurances from './getInsurances'
import getInsurancesStatuses from './getInsurancesStatuses'
import getLeaguesStatuses from './getLeaguesStatuses'
import getInsurance from './getInsurance'
import submitInsuranceStatus from './submitInsuranceStatus'
import getLeagueOrganizer from './getLeagueOrganizer'
import getOrderSettings from './getOrderSettings'
import editOrderSettings from './editOrderSettings'
import makeOrder from './makeOrder'
import getLeague from './getLeague'
import getLeaguesOrganizations from './getLeaguesOrganizations'
import editLeague from './editLeague'
import deleteLeague from './deleteLeague'
import getLeagueOrders from './getLeagueOrders'
import getLeagueInsurance from './getLeagueInsurance'
import getLeagueMembersListByLeagueId from './getMembersListByLeagueId'
import inviteMember from './inviteMember'
import removeMembers from './removeMembers'
import getPaymentToken from './getPaymentToken'
import submitVirtualPayment from './submitVirtualPayment'
import submitPendingOrderCardPayment from './submitPendingOrderCardPayment'
import getPublicLeagues from './getPublicLeagues'
import getOrder from './getOrder'
import cancelOrder from './cancelOrder'
import submitOrderToVendor from './submitOrderToVendor'
import deleteAccount from './deleteAccount'
import getLeagueInfo from './getLeagueInfo'
import getUserAllLeagues from './getUserAllLeagues'
import getOrders from './getOrders'
import getOrdersStatuses from './getOrdersStatuses'
import getShippingMethods from './getShippingMethods'
import getRefunds from './getRefunds'
import changeLeagueOrganizer from './changeLeagueOrganizer'
import NFLTeams from './NFLTeams'
import editSeason from './editSeason'
import globalSettings from './globalSettings'
import bindInsurance from './bindInsurance'
import checkFileInsurance from './checkFileInsurance'
import messageCenter from './messageCenter'
import resources from './resources'
import discount from './discount'
import paymentSagas from './payments'
import featureManagementSagas from './featureManagement'
import exemptionCertificatesSagas from './exemptionCertificates'
import insurancesSagas from './insurances'
import seasonsSagas from './seasons'
import bulkOrderSagas from './bulkOrder'
import ordersSagas from './orders'
import leaguesSagas from './leagues'
import deleteOrder from './deleteOrder'

// types
import type { IOEffect } from 'redux-saga/effects'

export default function * rootSaga(): Generator<IOEffect, void, any> {
  const sagas = [
    ...login,
    ...logout,
    ...connectTo,
    ...toastMessage,
    ...editUser,
    ...setPassword,
    ...changePassword,
    ...getLeagues,
    ...getUserLeagues,
    ...getAccounts,
    ...getAdminAccounts,
    ...manageAdminAccount,
    ...getUserRoles,
    ...getAccount,
    ...editAccount,
    ...changeUserEmail,
    ...getAccountChanges,
    ...getInsurances,
    ...getInsurancesStatuses,
    ...getLeagueOrganizer,
    ...getLeaguesStatuses,
    ...getInsurance,
    ...submitInsuranceStatus,
    ...getOrderSettings,
    ...editOrderSettings,
    ...makeOrder,
    ...getLeague,
    ...getLeaguesOrganizations,
    ...editLeague,
    ...deleteLeague,
    ...getLeagueOrders,
    ...getLeagueInsurance,
    ...getLeagueMembersListByLeagueId,
    ...inviteMember,
    ...removeMembers,
    ...getPaymentToken,
    ...submitVirtualPayment,
    ...submitPendingOrderCardPayment,
    ...getPublicLeagues,
    ...getOrder,
    ...cancelOrder,
    ...deleteOrder,
    ...submitOrderToVendor,
    ...deleteAccount,
    ...getLeagueInfo,
    ...getUserAllLeagues,
    ...getOrders,
    ...getOrdersStatuses,
    ...getRefunds,
    ...getShippingMethods,
    ...changeLeagueOrganizer,
    ...NFLTeams,
    ...editSeason,
    ...globalSettings,
    ...bindInsurance,
    ...checkFileInsurance,
    ...messageCenter,
    ...resources,
    ...discount,
    ...paymentSagas,
    ...featureManagementSagas,
    ...exemptionCertificatesSagas,
    ...insurancesSagas,
    ...seasonsSagas,
    ...bulkOrderSagas,
    ...ordersSagas,
    ...leaguesSagas,
  ]

  yield all(sagas)
}
