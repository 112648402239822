// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { DELETE_ORDER, deleteOrder } from '../actions/bulkOrder/bulkOrder'
import http from 'src/services/http'
import { history } from 'src/services/history'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchDeleteOrder(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { orderId },
    } = yield take(DELETE_ORDER.REQUEST)
    try {
      yield call(http, `admin/order/delete/${orderId}`, {
        method: 'DELETE',
      })

      yield put(deleteOrder.success())
      yield call(history.push, '/orders-list')
    } catch (e) {
      yield put(deleteOrder.error(e.message))
    }
  }
}

export default [fork(watchDeleteOrder)]
