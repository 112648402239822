// @flow

const roles = [
  {
    name: 'PARENT',
    labelText: 'Parent',
    title: 'Parent',
  },
  {
    name: 'COACH',
    labelText: 'Coach',
    title: 'Coach',
  },
  {
    name: 'OFFICIAL',
    labelText: 'Official',
    title: 'Official',
  },
  {
    name: 'LEAGUE_OWNER',
    labelText: 'I am interested in starting a league',
    title: 'League Organizer',
  },
  {
    name: 'CO_OWNER',
    labelText: 'Co-organizer',
    title: 'Co-organizer',
  },
]

export default roles
