// @flow

import React from 'react'
import TextField from '@material-ui/core/TextField'

// type
import type { FormTextFieldPropsType } from './FormTextFieldTypes'

const FormTextField = ({
  label,
  // $FlowFixMe
  input,
  meta: { touched, invalid, error },
  ...custom
}: FormTextFieldPropsType) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
)
export default FormTextField
