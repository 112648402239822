// @flow
import React from 'react'

import {
  FormTextField,
  FormWrapper,
} from 'src/common/components'

import { Field } from 'redux-form'
import Button from '@material-ui/core/Button'

// types
import type { OrdersFiltersFormProps } from './OrdersFiltersFormTypes'

// styles
import { StyledOrdersFilters } from './ordersFiltersStyles'

const OrdersFiltersForm = ({
  handleSubmit,
  formVals,
  onSubmit,
}: OrdersFiltersFormProps) => {
  return (
    <StyledOrdersFilters>
      <FormWrapper>
        <form>
          <div className="form-row">
            <div className="form-group">
              <div className="input-wrapper">
                <Field
                  name="name"
                  id="name"
                  placeholder="Filter Set Name ..."
                  size="small"
                  variant="outlined"
                  component={FormTextField}
                />
              </div>
            </div>
            <div className="form-group">
              <Button
                disabled={!formVals?.name?.length}
                className="margin-slot"
                variant="contained"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Save Filters Set
              </Button>
            </div>
          </div>
        </form>
      </FormWrapper>
    </StyledOrdersFilters>
  )
}

export default OrdersFiltersForm

