// @flow
import React, { Component } from 'react'

import {
  FormTextField,
} from 'src/common/components'
import { Field } from 'redux-form'
import { number } from 'src/helpers/formValidation'
import { onlyDecimal } from 'src/helpers/formNormalizers'

import LocalOfferIcon from '@material-ui/icons/LocalOffer'

// styles
import { StyledOrderItemPrices } from './orderItemPricesStyles'

// types
import type { OrderItemPricesPropsTypes } from './OrderItemPricesTypes'

class OrderItemPrices extends Component<OrderItemPricesPropsTypes> {
  shapeData() {
    const { formFields } = this.props
    return formFields.reduce((acc, cur) => {
      const { key, premium } = cur

      const search = premium && key.split('premium_')

      if (search.length > 1) {
        const searchKey = search.pop()
        return {
          ...acc,
          [searchKey]: acc?.[searchKey] ? [...acc?.[searchKey], cur] : [cur],
        }
      }

      return {
        ...acc,
        [key]: acc?.[cur] ? [...acc?.[cur], cur] : [cur],
      }
    }, {})
  }

  render() {
    const shapedData = this.shapeData()

    return (
      <StyledOrderItemPrices>
        {Object.keys(shapedData).map(k => (
          <div className="row-wrapper" key={k}>
            {shapedData[k].map(({ id, name, key, premium }) => (
              <div key={id} className="row">
                <span className="label"><LocalOfferIcon/>{name}:</span>
                <div className="value">
                  <span>$</span>
                  <Field
                    name={key}
                    id={key}
                    placeholder="0"
                    component={FormTextField}
                    variant="outlined"
                    required
                    validate={[number]}
                    normalize={onlyDecimal}
                  />
                </div>
              </div>
            ))}
          </div>))}
      </StyledOrderItemPrices>
    )
  }
}

export default OrderItemPrices
