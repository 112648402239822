import styled from 'styled-components'

export const StyledWrapper = styled.div`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${props => props.zIndex};
`

export const StyledModal = styled.div`
    background-color: #fff;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.5;
    margin: 0 auto;
    max-width: 600px;
    padding: 24px;
    text-align: center;
    width: 90%;    
    max-height: 100vh;
    overflow-y: auto;
    
    .modal-action {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;
        position: relative;
    }
    
    .modal-title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    
    .list {
      max-width: 60%;
      margin: 15px auto;
    
      li {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid ${props => props.theme.main.colors.inputBorderColor};
        font-size: 14px;
        
        &:first-child {
          font-weight: bold;
        }
        
        &:last-child {
          border-bottom: none;
        }
      }
      
    }
`

export const StyledCancelButton = styled.button`
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 0;
    color: ${props => props.theme.main.colors.secondaryColor};
    display: flex;
    font-size: 15px;
    justify-content: center;
    margin-left: 20px;
    ${props => props.absolute && `
        position: absolute;
        right: 0;
        margin-top: 15px;
    `}
  
    :hover {
        text-decoration: underline;
    }
    
    svg {
        height: 11px;
        margin-right: 6px;
        width: 11px;
        
        path {
            fill: ${props => props.theme.main.colors.secondaryColor};
        }
    }
`
