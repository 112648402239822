// @flow
import { call, put } from 'redux-saga/effects'

import http from 'src/services/http'
import { showToast } from 'src/redux/actions/ui/ui'

import type { IOEffect } from 'redux-saga/effects'

export function * validateActualData({
  order_amount,
  order_id,
}: {
  order_amount: number,
  order_id: number
}): Generator<IOEffect, void, any> {
  // TODO: what does this check actually ?
  const { data: actualData } = yield call(http, `admin/order/${order_id}/state`, {
    method: 'GET',
  })
  if (+order_amount !== +actualData.order.amount_to_pay) {
    const message = 'Order doesn\'t have actual data. Reload page'
    yield put(showToast.success({ title: message, messageType: 'warning' }))
    throw new Error(message)
  }
}
