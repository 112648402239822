// @flow
import roles from 'src/config/roles'

const getRole = (roleName: string) => {
  if (!roleName) return
  const { title } = roles.find(item => item.name === roleName) || {}
  return title
}

export default getRole
