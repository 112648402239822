// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_ORDER_SETTINGS, getOrderSettings } from 'src/redux/actions/orderSettings/orderSettings'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetOrderSettings(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: owner_id,
    } = yield take(GET_ORDER_SETTINGS.REQUEST)
    try {
      const { data } = yield call(http, `admin/owner/${owner_id}`, {
        method: 'GET',
      })

      yield put(getOrderSettings.success(data))
    } catch (e) {
      yield put(getOrderSettings.error(e.message))
    }
  }
}

export default [fork(watchGetOrderSettings)]
