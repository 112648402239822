// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_ORDERS, getOrders } from 'src/redux/actions/orders/orders'
import http from 'src/services/http'
import config from '../../config/formsConfig'
import { stopAsyncValidation, stopSubmit } from 'redux-form'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetOrders(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(GET_ORDERS.REQUEST)

    const formId = config.ordersSearchForm.id

    try {
      let { data } = yield call(http, 'admin/orders', {
        method: 'GET',
        params: values,
      })

      // WHY?
      data = {
        ...data,
        search: values.search,
        page: values.page || 1,
        sort_by: values.sort_by || '',
        sort_order: values.sort_order || '',
        refunds_only: values.refunds_only || false,
        payment_status: values.payment_status || '',
        shipping_methods: values.shipping_methods || [],
        paid_start_date: values.paid_start_date || '',
        paid_end_date: values.paid_end_date || '',
        order_modes: values.order_modes || [],
        program_id: values.program_id || [],
      }

      yield put(getOrders.success(data))
    } catch (e) {
      yield put(getOrders.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(stopAsyncValidation(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchGetOrders)]
