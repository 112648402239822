// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { GetPaymentTokenActionTypes, SetPaymentPayloadActionTypes, BasicRedux } from './paymentsTypes'

export const GET_PAYMENT_TOKEN = requestTypeCreator('GET_PAYMENT_TOKEN')
export const SET_PAYMENT_PAYLOAD = requestTypeCreator('SET_PAYMENT_PAYLOAD')
export const SUBMIT_VIRTUAL_PAYMENT = requestTypeCreator('SUBMIT_VIRTUAL_PAYMENT')
export const SUBMIT_PENDING_ORDER_CARD_PAYMENT = requestTypeCreator('SUBMIT_PENDING_ORDER_CARD_PAYMENT')
export const GET_PAYMENT_METHODS = requestTypeCreator('GET_PAYMENT_METHODS')
export const DELETE_PAYMENT_METHOD = requestTypeCreator('DELETE_PAYMENT_METHOD')
export const GET_CURRENCY_RATE = requestTypeCreator('GET_CURRENCY_RATE')
export const SHOW_PAYMENT_LOADER = 'SHOW_PAYMENT_LOADER'
export const RESET_PAYMENTS_ERROR = 'RESET_PAYMENTS_ERROR'

export const getPaymentToken = {
  error: (payload: any): GetPaymentTokenActionTypes => ({ type: GET_PAYMENT_TOKEN.FAILURE, payload }),
  request: (payload: any): GetPaymentTokenActionTypes => ({ type: GET_PAYMENT_TOKEN.REQUEST, payload }),
  success: (payload: any): GetPaymentTokenActionTypes => ({ type: GET_PAYMENT_TOKEN.SUCCESS, payload }),
}

export const setPaymentPayload = {
  error: (payload: any): SetPaymentPayloadActionTypes => ({ type: SET_PAYMENT_PAYLOAD.FAILURE, payload }),
  request: (payload: any): SetPaymentPayloadActionTypes => ({ type: SET_PAYMENT_PAYLOAD.REQUEST, payload }),
  success: (payload: any): SetPaymentPayloadActionTypes => ({ type: SET_PAYMENT_PAYLOAD.SUCCESS, payload }),
}

export const showPaymnentLoader = {
  success: (payload: any) => ({ type: SHOW_PAYMENT_LOADER, payload }),
}

export const submitVirtualPayment = {
  error: (payload: any): SetPaymentPayloadActionTypes => ({ type: SUBMIT_VIRTUAL_PAYMENT.FAILURE, payload }),
  request: (payload: any): SetPaymentPayloadActionTypes => ({ type: SUBMIT_VIRTUAL_PAYMENT.REQUEST, payload }),
  success: (payload: any): SetPaymentPayloadActionTypes => ({ type: SUBMIT_VIRTUAL_PAYMENT.SUCCESS, payload }),
}

export const submitPendingOrderCardPayment = {
  error: (payload: any): BasicRedux => ({ type: SUBMIT_PENDING_ORDER_CARD_PAYMENT.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: SUBMIT_PENDING_ORDER_CARD_PAYMENT.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: SUBMIT_PENDING_ORDER_CARD_PAYMENT.SUCCESS, payload }),
}

export const getPaymentMethods = {
  error: (payload: any): BasicRedux => ({ type: GET_PAYMENT_METHODS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_PAYMENT_METHODS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_PAYMENT_METHODS.SUCCESS, payload }),
}

export const deletePaymentMethod = {
  error: (payload: any): BasicRedux => ({ type: DELETE_PAYMENT_METHOD.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: DELETE_PAYMENT_METHOD.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: DELETE_PAYMENT_METHOD.SUCCESS, payload }),
}

export const resetPaymentsError = {
  success: (payload: any): BasicRedux => ({ type: RESET_PAYMENTS_ERROR, payload }),
}

export const getCurrencyRate = {
  error: (payload: any): BasicRedux => ({ type: GET_CURRENCY_RATE.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_CURRENCY_RATE.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_CURRENCY_RATE.SUCCESS, payload }),
}
