// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { BasicRedux } from './NFLTeamsTypes'

export const GET_NFL_TEAMS = requestTypeCreator('GET_NFL_TEAMS')

export const getNFLTeams = {
  error: (payload: any): BasicRedux => ({ type: GET_NFL_TEAMS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_NFL_TEAMS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_NFL_TEAMS.SUCCESS, payload }),
}
