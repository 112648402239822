// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { reset, stopSubmit } from 'redux-form'
import { SAVE_ORDERS_EXPORT_FILTER, saveOrdersExportFilter, getOrdersExportFilters, setOrdersExportFilter, GET_ORDERS_EXPORT_FILTERS } from 'src/redux/actions/orders/orders'
import { fetchSaveOrdersExportFilter } from 'src/services/orders'
import config from 'src/config/formsConfig'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSaveOrdersExportFilter(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { values, outputArray } } = yield take(SAVE_ORDERS_EXPORT_FILTER.REQUEST)
    const formId = config.ordersFiltersForm.id

    try {
      const fetchData = {
        name: values.name,
        fields: outputArray,
      }
      const data = yield call(fetchSaveOrdersExportFilter, fetchData)

      yield put(saveOrdersExportFilter.success())
      yield put(reset(formId))
      yield put(getOrdersExportFilters.request())

      yield take([GET_ORDERS_EXPORT_FILTERS.SUCCESS, GET_ORDERS_EXPORT_FILTERS.FAILURE])

      yield put(setOrdersExportFilter.success({ id: data.id }))
    } catch (e) {
      yield put(saveOrdersExportFilter.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchSaveOrdersExportFilter)]
