// @flow
import React from 'react'
import { StyledPrimaryButton } from './buttoPrimaryStyles'
import type { ButtonPrimaryPropsType } from './ButtonPrimaryTypes'

const ButtonPrimary = ({
  buttonText,
  disabled,
  size,
  center,
  type = 'button',
  action = () => {},
  onClick,
  className,
  id,
}: ButtonPrimaryPropsType) => {
  return (
    <StyledPrimaryButton center={center} type={type} onClick={onClick || action} size={size} disabled={disabled} className={className} id={id}>
      {buttonText}
    </StyledPrimaryButton>
  )
}

export default ButtonPrimary
