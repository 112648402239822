// @flow

import { stopSubmit, stopAsyncValidation } from 'redux-form'
import { fork, put, take, race, select } from 'redux-saga/effects'
import config from 'src/config/formsConfig'
import {
  EDIT_ORDER_SETTINGS,
  editOrderSettings,
  UPDATE_OWNER_LEAGUES_PRICES,
  updateOwnerLeaguesPrices,
  UPDATE_OWNER_LEAGUES_VENDOR,
  updateOwnerLeaguesVendor,
  FORCE_LEAGUE_VENDOR_UPDATE,
  forceLeagueVendorUpdate,
} from 'src/redux/actions/orderSettings/orderSettings'
import {
  editLeagueVendor,
  EDIT_LEAGUE_VENDOR,
} from 'src/redux/actions/leagues/leagues'

import http from 'src/services/http'
import { each } from 'lodash'

// types
import type { IOEffect } from 'redux-saga/effects'

// id
function * watchEditOrderSettings(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(EDIT_ORDER_SETTINGS.REQUEST)

    const {
      owner_id,
      values,
    } = payload
    const formId = config.orderSettingsForm.id

    const { leagues: { itemTypes } } = yield select()

    itemTypes.forEach(({ key, premium }) => {
      if (!premium) {
        values[key] = +values[key]
      }
    })

    try {
      yield http(`admin/owner/${owner_id}`, {
        body: JSON.stringify(values),
        method: 'PUT',
      })

      yield put(editOrderSettings.success())
      yield put(stopSubmit(formId))
    } catch (e) {
      yield put(editOrderSettings.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(stopAsyncValidation(formId, e.errors ? e.errors : {}))
    }
  }
}

function * watchUpdateOwnerLeaguesPrices(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { owner_id, prices },
    } = yield take(UPDATE_OWNER_LEAGUES_PRICES.REQUEST)

    each(prices, (val, key) => {
      prices[key] = +val
    })
    try {
      yield http(`admin/owner/${owner_id}/leagues-prices`, {
        body: JSON.stringify(prices),
        method: 'PUT',
      })

      yield put(updateOwnerLeaguesPrices.success())
    } catch (e) {
      yield put(updateOwnerLeaguesPrices.error(e.message))
    }
  }
}

function * watchUpdateOwnerLeaguesVendor(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { owner_id, default_vendor },
    } = yield take(UPDATE_OWNER_LEAGUES_VENDOR.REQUEST)

    try {
      yield http(`admin/owner/${owner_id}/leagues-vendor`, {
        body: JSON.stringify({ default_vendor }),
        method: 'PUT',
      })

      yield put(updateOwnerLeaguesVendor.success())
    } catch (e) {
      yield put(updateOwnerLeaguesVendor.error(e.errors || []))
    }
  }
}

function * watchForceLeagueVendorUpdate(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { league_id, default_vendor },
    } = yield take(FORCE_LEAGUE_VENDOR_UPDATE.REQUEST)

    yield put(editLeagueVendor.request({
      league_id,
      default_vendor,
      force: true,
    }))

    const result = yield race({
      success: take(EDIT_LEAGUE_VENDOR.SUCCESS),
      failed: take(EDIT_LEAGUE_VENDOR.FAILURE),
    })

    if (result.success) {
      yield put(forceLeagueVendorUpdate.success(league_id))
    }
  }
}

export default [
  fork(watchEditOrderSettings),
  fork(watchUpdateOwnerLeaguesPrices),
  fork(watchUpdateOwnerLeaguesVendor),
  fork(watchForceLeagueVendorUpdate),
]
