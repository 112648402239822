import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledSidebar = styled.div`
    .sidebar {
        color: #fff;
        flex-shrink: 0;
        height: 100%;
    }
    
    .menu-button {
        background-color: ${props => props.theme.main.colors.sidebarColor};
        left: 30px;
        position: absolute;
        top: 5px;
        display: block;
        z-index: 99;
    }
    
    .logo {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 30px;
    }
    
    .nav, .nav-wrapper, .MuiDrawer-paper, .MuiPaper-root  {
        background-color: ${props => props.theme.main.colors.sidebarColor};
    }
    
    .drawer-paper {
        width: 280px;
    }
    
    .logo {
        background-color: ${props => props.theme.main.colors.sidebarColor};
    }
    
    @media (min-width: 959px) {
        .nav-wrapper {
            flex-shrink: 0;
            width: 280px;
        }
        
        .menu-button {
            display: none;
        }
        
        .MuiDrawer-paper {
            width: 280px;
        }
    }
    
    @media ${device.tablet} {
        .menu-button {
            top: 3px;
        }
    }
`
