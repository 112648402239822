// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type {
  GetLeagueOrdersActionTypes,
  GetOrdersActionTypes,
  GetOrdersStatusesActionType,
  GetShippingMethodsActionType,
  BasicRedux,
} from './ordersTypes'

export const GET_LEAGUE_ORDERS = requestTypeCreator('GET_LEAGUE_ORDERS')
export const GET_ORDERS = requestTypeCreator('GET_ORDERS')
export const GET_ORDERS_STATUSES = requestTypeCreator('GET_ORDERS_STATUSES')
export const GET_SHIPPING_METHODS = requestTypeCreator('GET_SHIPPING_METHODS')
export const GET_ORDERS_EXPORT_AVAILABLE_COLUMNS = requestTypeCreator('GET_ORDERS_EXPORT_AVAILABLE_COLUMNS')
export const SAVE_ORDERS_EXPORT_FILTER = requestTypeCreator('SAVE_ORDERS_EXPORT_FILTER')
export const GET_ORDERS_EXPORT_FILTERS = requestTypeCreator('GET_ORDERS_EXPORT_FILTERS')
export const DELETE_ORDERS_EXPORT_FILTER = requestTypeCreator('DELETE_ORDERS_EXPORT_FILTER')
export const SET_ORDERS_EXPORT_FILTER = 'SET_ORDERS_EXPORT_FILTER'

export const getLeagueOrders = {
  error: (payload: any): GetLeagueOrdersActionTypes => ({ type: GET_LEAGUE_ORDERS.FAILURE, payload }),
  request: (payload: any): GetLeagueOrdersActionTypes => ({ type: GET_LEAGUE_ORDERS.REQUEST, payload }),
  success: (payload: any): GetLeagueOrdersActionTypes => ({ type: GET_LEAGUE_ORDERS.SUCCESS, payload }),
}

export const getOrders = {
  error: (payload: any): GetOrdersActionTypes => ({ type: GET_ORDERS.FAILURE, payload }),
  request: (payload: any): GetOrdersActionTypes => ({ type: GET_ORDERS.REQUEST, payload }),
  success: (payload: any): GetOrdersActionTypes => ({ type: GET_ORDERS.SUCCESS, payload }),
}

export const getOrdersStatuses = {
  error: (payload: any): GetOrdersStatusesActionType => ({ type: GET_ORDERS_STATUSES.FAILURE, payload }),
  request: (payload: any): GetOrdersStatusesActionType => ({ type: GET_ORDERS_STATUSES.REQUEST, payload }),
  success: (payload: any): GetOrdersStatusesActionType => ({ type: GET_ORDERS_STATUSES.SUCCESS, payload }),
}

export const getShippingMethods = {
  error: (payload: any): GetShippingMethodsActionType => ({ type: GET_SHIPPING_METHODS.FAILURE, payload }),
  request: (payload: any): GetShippingMethodsActionType => ({ type: GET_SHIPPING_METHODS.REQUEST, payload }),
  success: (payload: any): GetShippingMethodsActionType => ({ type: GET_SHIPPING_METHODS.SUCCESS, payload }),
}

export const getOrdersExportAvailableColumns = {
  error: (payload: any): BasicRedux => ({ type: GET_ORDERS_EXPORT_AVAILABLE_COLUMNS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_ORDERS_EXPORT_AVAILABLE_COLUMNS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_ORDERS_EXPORT_AVAILABLE_COLUMNS.SUCCESS, payload }),
}

export const saveOrdersExportFilter = {
  error: (payload: any): BasicRedux => ({ type: SAVE_ORDERS_EXPORT_FILTER.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: SAVE_ORDERS_EXPORT_FILTER.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: SAVE_ORDERS_EXPORT_FILTER.SUCCESS, payload }),
}

export const getOrdersExportFilters = {
  error: (payload: any): BasicRedux => ({ type: GET_ORDERS_EXPORT_FILTERS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_ORDERS_EXPORT_FILTERS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_ORDERS_EXPORT_FILTERS.SUCCESS, payload }),
}

export const deleteOrdersExportFilter = {
  error: (payload: any): BasicRedux => ({ type: DELETE_ORDERS_EXPORT_FILTER.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: DELETE_ORDERS_EXPORT_FILTER.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: DELETE_ORDERS_EXPORT_FILTER.SUCCESS, payload }),
}

export const setOrdersExportFilter = {
  success: (payload: any): BasicRedux => ({ type: SET_ORDERS_EXPORT_FILTER, payload }),
}
