// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  setUser,
  resetUser,
  editUser,
  setUserPassword,
  editUserPassword,
  editUserGeoLocationZip,
} from 'src/redux/actions/user/user'

const withUser = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithUserComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ user }) => ({ user })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    setUser: values =>
      dispatch(setUser.request({ ...values, redirectCb: ownProps.history.push })),
    resetUserState: () => dispatch(resetUser.success()),
    editUser: values => dispatch(editUser.request({ ...values })),
    setUserPassword: values =>
      dispatch(setUserPassword.request({ ...values, token: ownProps.match.params.token, redirectCb: ownProps.history.push })),
    editUserPassword: values =>
      dispatch(editUserPassword.request({ ...values })),
    editUserGeoLocationZip: values =>
      dispatch(editUserGeoLocationZip.success({ ...values })),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithUserComponent))
}

export default withUser

