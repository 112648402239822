// @flow

import { requestTypeCreator } from 'src/helpers'

import type {
  BasicRedux,
} from './featureManagementTypes.js'

export const GET_FEATURES = requestTypeCreator('GET_FEATURES')
export const UPDATE_FEATURE = requestTypeCreator('UPDATE_FEATURE')
export const GET_FEATURE = requestTypeCreator('GET_FEATURE')

export const getFeatures = {
  error: (payload: any): BasicRedux => ({ type: GET_FEATURES.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_FEATURES.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_FEATURES.SUCCESS, payload }),
}

export const updateFeature = {
  error: (payload: any): BasicRedux => ({ type: UPDATE_FEATURE.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: UPDATE_FEATURE.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: UPDATE_FEATURE.SUCCESS, payload }),
}

export const getFeature = {
  error: (payload: any): BasicRedux => ({ type: GET_FEATURE.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_FEATURE.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_FEATURE.SUCCESS, payload }),
}

