// @flow
import React, { useLayoutEffect, useState } from 'react'
import type { Node } from 'react'
import { Confirm, DashboardBlock } from 'src/common/components'
import CardForm from '../cardForm'

// types
import type { ACHMethod } from 'src/redux/reducers/payments'

type OptionsPropsType = {
    checked: ?ACHMethod,
    editable: ?boolean,
    deletePaymentMethod: (data: {token: string}) => void,
    children?: Node,
    onSubmit: (data: any) => void,
    setChecked: (data: any) => void,
    buttonText: string,
    addButtonText: string,
    paymentToken: string,
    showSaveCard: boolean,
    canAddCard: boolean
};

const Options = ({
  checked,
  editable,
  deletePaymentMethod,
  children = null,
  onSubmit,
  buttonText,
  paymentToken,
  setChecked,
  showSaveCard,
  canAddCard,
  addButtonText,
}: OptionsPropsType) => {
  const [open, setOpen] = useState(false)

  useLayoutEffect(() => {
    if (open) {
      setChecked()
    }
  }, [open])

  useLayoutEffect(() => {
    if (checked) {
      setOpen(false)
    }
  }, [checked])

  const handleAddCardClick = () => {
    setOpen(!open)
  }

  return (
    <div className="heading">
      <DashboardBlock title="Credit cards">
        <div className="top-container">
          {editable && (
            <div className="crud-container">
              <div className="crud">
                {checked && deletePaymentMethod && (
                  <Confirm
                    title="Confirm"
                    description={'Are you sure you wish to delete this Payment Method?'}
                    confirmText="yes"
                    cancelText="no"
                  >
                    {confirm => (
                      <div className="text-center">
                        <span
                          className="edit-link"
                          onClick={confirm({
                            handleSubmit: deletePaymentMethod,
                            valid: true,
                          })}
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </Confirm>
                )}
              </div>
            </div>
          )}
        </div>
        {children}
        {canAddCard && (
          <>
            <div
              onClick={handleAddCardClick}
              className="edit-link button-wrapper"
            >
              <span>{addButtonText}</span>
            </div>
            {open && (
              <CardForm
                onSubmit={onSubmit}
                buttonText={buttonText}
                paymentToken={paymentToken}
                showSaveCard={showSaveCard}
                setOpen={setOpen}
              />
            )}
          </>
        )}
      </DashboardBlock>
    </div>
  )
}

export default Options
