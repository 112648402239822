import http from 'src/services/http'

export const fetchOrdersExportAvailableColumns = async() => {
  const response = await http('admin/orders-export/available-columns', {
    method: 'GET',
  })
  return response?.data
}

export const fetchOrdersExportFilters = async() => {
  const response = await http('admin/orders-export/saved-columns', {
    method: 'GET',
  })
  return response?.data
}

export const fetchDeleteOrdersExportFilter = async id => {
  const response = await http(`admin/orders-export/saved-columns/${id}`, {
    method: 'DELETE',
  })
  return response?.data
}

export const fetchSaveOrdersExportFilter = async values => {
  const response = await http('admin/orders-export/saved-columns', {
    method: 'POST',
    body: JSON.stringify(values),
  })
  return response?.data
}
