// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { logout, LOGOUT_USER } from 'src/redux/actions/user/user'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'
import { history } from 'src/services/history'

function * watchLogout(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(LOGOUT_USER.REQUEST)

    try {
      yield http('admin/logout', {
        method: 'POST',
      })
      yield put(logout.success())
    } catch (e) {
      yield put(logout.error(e.message))
    }
    yield call(history.push, '/login')
  }
}

export default [fork(watchLogout)]
