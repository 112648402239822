// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_DECLINE_REASONS, getDeclineReasons } from 'src/redux/actions/insurances/insurances'
import { fetchDeclineReasons } from 'src/services/insurances'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetDeclineReasons(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_DECLINE_REASONS.REQUEST)

    try {
      const data = yield call(fetchDeclineReasons)

      yield put(getDeclineReasons.success(data))
    } catch (e) {
      yield put(getDeclineReasons.error(e.message))
    }
  }
}

export default [fork(watchGetDeclineReasons)]
