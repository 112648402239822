import styled from 'styled-components'

export const StyledTextField = styled.input`
    border-radius: 2px;
    margin: 6px 0 4px;
    border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
    background: ${props => props.theme.main.colors.inputBackgroundColor};
    color: ${props => props.theme.main.colors.inputColor};
    font-size: 14px;
    padding: 12px;
    width: 100%;
    
    :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus {
        border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
        font-size: 14px;
        transition: background-color 5000s ease-in-out 0s;
         -webkit-text-fill-color: ${props => props.theme.main.colors.inputColor};
    }
    
    :focus {
        border-color: ${props => props.theme.main.colors.primaryColor};
    }
    
    :disabled {
        border: 0;
        font-weight: 500;
        padding: 12px 0 0;
    }

`
