// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import config from 'src/config/formsConfig'
import { SET_USER, setUser } from 'src/redux/actions/user/user'
import http from 'src/services/http'
import { setToken } from 'src/services/user'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchLogin(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { email, password, keepLoggedIn = false, redirectCb },
    } = yield take(SET_USER.REQUEST)
    const formId = config.loginForm.id

    try {
      const { data: { token, user } } = yield http('admin/login', {
        body: JSON.stringify({
          password,
          email,
          keepLoggedIn,
        }),
        method: 'POST',
      })

      setToken(token)

      yield put(setUser.success(user))
      yield call(redirectCb, '/')
    } catch (e) {
      yield put(setUser.error(e.message))
      yield put(stopSubmit(formId))
      yield call(redirectCb, '/login')
    }
  }
}

export default [fork(watchLogin)]
