// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getAccounts,
  getUserRoles,
  getAccount,
  editAccount,
  editUserEmail,
  deleteAccount,
  getAccountChanges,
} from 'src/redux/actions/accounts/accounts'

const withAccounts = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithAccountsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ accounts }) => ({ accounts })

  const mapDispatchToProps = dispatch => ({
    getAccounts: values => dispatch(getAccounts.request(values)),
    getUserRoles: () => dispatch(getUserRoles.request()),
    getAccount: id => dispatch(getAccount.request(id)),
    editAccount: values => dispatch(editAccount.request(values)),
    editUserEmail: values => dispatch(editUserEmail.request(values)),
    deleteAccount: values => dispatch(deleteAccount.request(values)),
    getAccountChanges: id => dispatch(getAccountChanges.request(id)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithAccountsComponent))
}

export default withAccounts
