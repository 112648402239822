// @flow

import { GET_ORDER_REFUNDS } from 'src/redux/actions/refunds/refunds'
import type { RefundsActionTypes } from 'src/redux/actions/refunds/refundsTypes'

export type RefundItemType = {
    source_id: string,
    order_id: number,
    type: string,
    amount: number,
    currency: string,
    card_type: string,
    card_number: string,
    status: string,
    source: string,
    created_at: string,
    updated_at: string
};

export type RefundsData = {
    totalRefund: ?number,
    refunds: RefundItemType[]
};

export type RefundsState = {
  isLoadingRefunds: boolean,
  refundsData: ?RefundsData,
  refundsErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoadingRefunds: false,
  refundsErrors: null,
  refundsData: {
    totalRefund: 0,
    refunds: [],
  },
}

const refunds = (state: RefundsState = initialState, action: RefundsActionTypes): RefundsState => {
  switch (action.type) {
    case GET_ORDER_REFUNDS.REQUEST:
    {
      return {
        ...state,
        isLoadingRefunds: true,
        refundsErrors: null,
      }
    }

    case GET_ORDER_REFUNDS.SUCCESS:
    {
      return {
        ...state,
        isLoadingRefunds: false,
        refundsData: { ...action.payload },
        refundsErrors: null,
      }
    }

    case GET_ORDER_REFUNDS.FAILURE:
    {
      return {
        ...state,
        isLoadingRefunds: false,
        refundsErrors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default refunds
