// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_MEMBERS_BY_LEAGUE_ID, getMembersListByLeagueId } from 'src/redux/actions/members/members'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetMembersListByLeagueId(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { league_id, ...values },
    } = yield take(GET_MEMBERS_BY_LEAGUE_ID.REQUEST)

    const params = {
      limit: values.limit || 50,
      offset: values.offset || 0,
    }

    try {
      let { data } = yield call(http, `admin/league/${league_id}/members`, {
        method: 'GET',
        params,
      })

      data = { ...data, page: values.page || 1 }

      yield put(getMembersListByLeagueId.success(data))
    } catch (e) {
      yield put(getMembersListByLeagueId.error(e.message))
    }
  }
}

export default [fork(watchGetMembersListByLeagueId)]
