// @flow
import React, { lazy, Suspense } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import AppBrowserRouter, { history } from 'src/services/history'
import { Loader, PublicRoute, PrivateRoute, NotFound, NotFoundRoute } from 'src/common/components'
import SettingsRoutes from 'src/modules/userSettings/routes'
import UsersRoutes from 'src/modules/users/routes'
import AdminManagementRoutes from 'src/modules/adminManagement/routes'
import InsuranceRoutes from 'src/modules/insurance/routes'
import LeaguesRoutes from 'src/modules/leagues/routes'
import PaymentRoutes from 'src/modules/payment/routes'
import LeagueFinderRoutes from 'src/modules/leagueFinder/routes'
import OrdersRoutes from 'src/modules/orders/routes'
import GlobalSettings from 'src/modules/globalSettings/routes'
import OrderRoutes from 'src/modules/order/routes'
import DashboardManagementRoutes from 'src/modules/dashboardManagement/routes'
import ExemptionCertificatesRoutes from 'src/modules/exemptionCertificates/routes'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const Login = lazy(() => import('src/modules/login'))
const Dashboard = lazy(() => import('src/modules/dashboard'))
const EditProfile = lazy(() => import('src/modules/editProfile'))
const Payments = lazy(() => import('src/modules/payments'))
const BackgroundCheckPage = lazy(() => import('src/modules/backgroundChecks/backgroundCheckPage'))
const FeatureManagement = lazy(() => import('src/modules/featureManagement'))

const { REACT_APP_SENTRY_URL = '' } = process.env

Sentry.init({
  dsn: REACT_APP_SENTRY_URL,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

const AppRouter = () => (
  <AppBrowserRouter history={history}>
    <Suspense fallback={<Loader />}>
      <Switch>
        <PublicRoute exact={true} path="/login" component={Login} />
        <PrivateRoute exact={true} path="/" component={Dashboard} />
        <PrivateRoute exact={true} path="/edit-profile" component={EditProfile} />
        <PrivateRoute exact={true} path="/payments" component={Payments} />
        <PrivateRoute exact={true} path="/background-checks/:id" component={BackgroundCheckPage} />
        <PrivateRoute exact={true} path="/feature-management" component={FeatureManagement} />
        {AdminManagementRoutes}
        {SettingsRoutes}
        {UsersRoutes}
        {InsuranceRoutes}
        {LeaguesRoutes}
        {PaymentRoutes}
        {LeagueFinderRoutes}
        {OrdersRoutes}
        {DashboardManagementRoutes}
        {GlobalSettings}
        {OrderRoutes}
        {ExemptionCertificatesRoutes}
        <NotFoundRoute path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  </AppBrowserRouter>
)

export default AppRouter
