// @flow
import { call, fork, put, take } from 'redux-saga/effects'
import {
  MAKE_DISCOUNT_PAYMENT,
  makeDiscountPayment,
  UPDATE_DISCOUNT,
  updateDiscount,
} from 'src/redux/actions/makeOrder/makeOrder'

import http from 'src/services/http'
import { history } from 'src/services/history'
import { validateActualData } from 'src/helpers/sagaHelper'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchMakeDiscountPayment(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { order_id, discount_amount, order_amount } } = yield take(MAKE_DISCOUNT_PAYMENT.REQUEST)

    try {
      yield call(validateActualData, { order_amount, order_id })

      yield call(http, 'admin/order/discount-payment', {
        method: 'POST',
        body: JSON.stringify({
          order_id,
          discount_amount: +discount_amount,
        }),
      })

      yield put(makeDiscountPayment.success())

      yield call(history.push, '/orders-list')
    } catch (e) {
      yield put(makeDiscountPayment.error(e.message))
    }
  }
}

function * watchUpdateDiscount(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { order_id, discount_amount, redirect = null, order_amount } } = yield take(UPDATE_DISCOUNT.REQUEST)

    try {
      yield call(validateActualData, { order_amount, order_id })

      yield call(http, 'admin/order/discount', {
        method: 'POST',
        body: JSON.stringify({
          order_id,
          discount_amount: +discount_amount,
        }),
      }, { disableToastr: true })
      yield put(updateDiscount.success(+discount_amount))

      if (redirect) {
        yield call(history.push, redirect)
      }
    } catch (e) {
      yield put(updateDiscount.error(e.message))
    }
  }
}

export default [
  fork(watchMakeDiscountPayment),
  fork(watchUpdateDiscount),
]

