import styled, { css } from 'styled-components'

export const StyledOrdersFiltersList = styled.ul`
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
`

const baseIconStyles = css`
  width: 20px;
  height: 20px;
  min-width: 20px;
`

export const StyledFilter = styled.li`
    word-wrap: break-word;
    text-align: left;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: inline-flex;
  border-radius: 16px;
  align-items: center;
  padding: 5px;
  background-color: ${props =>
  props.selectedFilter
    ? props.theme.main.colors.primaryColor
    : props.theme.main.colors.backgroundLight};
  color: ${props =>
      props.selectedFilter
        ? props.theme.main.colors.backgroundLight
        : props.theme.main.colors.primaryColor};
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
  .filter-icon {
    margin-right: 5px;
   ${baseIconStyles}
  }
  .delete-icon {
    margin-left: 5px;
    cursor: pointer;
    ${baseIconStyles}
  }
  margin: 5px 3px;
`
