/* eslint-disable multiline-ternary */
// @flow
import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Options from './fragments/options'

// styles
import {
  StyledACHMethods,
  StyledPrimaryText,
  StyledSecondaryText,
  StyledCircleCheckedFilled,
  StyledCircleChecked,
} from 'src/common/components/ACHMethods/ACHMethodsStyles'

// types
import type { PaymentCardListPropsType } from './PaymentCardListTypes'

import { StyledImage } from './PaymentListStyles'

const PaymentCardList = ({
  paymentMethods,
  checked,
  setChecked,
  deletePaymentMethod,
  editable,
  onSubmit,
  buttonText,
  paymentToken,
  showSaveCard,
  canAddCard,
  addButtonText,
}: PaymentCardListPropsType) => {
  return (
    <StyledACHMethods>
      {Array.isArray(paymentMethods?.cards) && paymentMethods?.cards?.length
        ? (
          <List>
            <Options
              checked={checked}
              editable={editable}
              deletePaymentMethod={deletePaymentMethod}
              onSubmit={onSubmit}
              buttonText={buttonText}
              paymentToken={paymentToken}
              setChecked={setChecked}
              showSaveCard={showSaveCard}
              canAddCard={canAddCard}
              addButtonText={addButtonText}
            >
              <div className="list">
                {/* $FlowFixMe */}
                {paymentMethods?.cards.map(item => {
                  const { globalId, last4, expirationDate, cardType, imageUrl, expired } = item
                  const labelId = `checkbox-list-label-${globalId}`
                  const isChecked = checked?.globalId === globalId

                  return (
                    <div key={labelId} className="list-item-wrapper">
                      <ListItem
                        className={
                          isChecked ? 'left-wrapper braintree-method active' : ' left-wrapper braintree-method'
                        }
                        key={globalId}
                        role={undefined}
                        dense
                        button
                        onClick={() => setChecked(item)}
                        disableRipple
                      >
                        <StyledImage src={imageUrl} alt={`logo-${cardType}`}/>
                        <ListItemText
                          id={labelId}
                          primary={
                            <StyledPrimaryText>Ending in {last4}</StyledPrimaryText>
                          }
                          secondary={
                            <StyledSecondaryText>
                              {cardType}
                            </StyledSecondaryText>
                          }
                        />
                        <ListItemText
                          id={labelId}
                          primary={<StyledPrimaryText>{expired ? 'EXPIRED' : `expires at: ${expirationDate}`}</StyledPrimaryText>}
                        />
                        {isChecked && (
                          <ListItemIcon>
                            <Checkbox
                              style={{
                                backgroundColor: 'transparent',
                                transition:
                            'transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                              }}
                              checked={isChecked}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              icon={<StyledCircleChecked />}
                              checkedIcon={<StyledCircleCheckedFilled />}
                            />
                          </ListItemIcon>
                        )}
                      </ListItem>
                    </div>
                  )
                })}
              </div>
            </Options>
          </List>
        ) : (
          <Options
            checked={checked}
            editable={editable}
            deletePaymentMethod={deletePaymentMethod}
            onSubmit={onSubmit}
            buttonText={buttonText}
            paymentToken={paymentToken}
            setChecked={setChecked}
            showSaveCard={showSaveCard}
            canAddCard={canAddCard}
            addButtonText={addButtonText}
          />
        )}
    </StyledACHMethods>
  )
}

export default PaymentCardList
