// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_USER_ROLES, getUserRoles } from 'src/redux/actions/accounts/accounts'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetUserRoles(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_USER_ROLES.REQUEST)

    try {
      const data = yield call(http, 'admin/role', {
        method: 'GET',
      })

      yield put(getUserRoles.success(data.data))
    } catch (e) {
      yield put(getUserRoles.error(e.message))
    }
  }
}

export default [fork(watchGetUserRoles)]
