import styled from 'styled-components'
import CircleChecked from '@material-ui/icons/CheckCircleOutline'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'

export const StyledCircleCheckedFilled = styled(CircleCheckedFilled)`
  transform: scale(2);
  transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: ${props => props.theme.main.colors.greenCheckboxColor};
`
export const StyledCircleChecked = styled(CircleChecked)`
  transform: scale(2);
  transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: ${props => props.verified === 'true' ? props.theme.main.colors.greenCheckboxColor : props.theme.main.colors.borderColor};
`
export const StyledACHMethods = styled.div`
  .heading {

    .warning {
      margin-bottom: 12px;
    }
    
    .top-container {
      display: flex;
      margin-bottom: 10px;
      font-size: 16px;
    }

    .crud-container {
      display: flex;
      flex: 1;
    }

    .button-wrapper {
      background: #FAFAFA;;
      border-radius: 4px;
      color: ${props => props.theme.main.colors.inputColor};
      cursor: pointer;
      font-size: 14px;
      margin: 15px auto 0;
      padding: 18px;
      text-align: center;
      width: 100%;
      display: flex;
      text-decoration: none;
      justify-content: center;

      &:hover {
        background: #fcfcfc;
        font-weight: 800;
        filter: none;
        border-color: black;
      }

      &:hover span {
        border-color: black;
      }
      
      span {
        border-bottom: 1px solid #B5B5B5;
        padding-bottom: 1px;
       
      }
    }

    .list-item-wrapper {
      display: flex;
    }

    .left-wrapper {
      flex: 6;
    }

    .right-wrapper {
      flex: 1;
      display: flex;
      justify-content: center;
      margin-left: 20px;
    }

    .sub-crud {
      display: flex;
      flex: 1;
    }

  }

  .edit-link {
    padding-left: 10px;
    text-decoration: underline;
    cursor: pointer;
  }

  .crud {
    display: flex;
    flex: 6;
  }

  .list {
    background: #FAFAFA;

    .braintree-method {
      &.active {
        border-radius: 5px;
        opacity: 1;
        width: 100%;
        z-index: 1;
        margin-top: -1px;
        margin-bottom: -1px;
        border: 2px solid #219653;
      }

      align-items: center;
      background-color: white;
      border-color: #b5b5b5;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 10px 0;
      position: relative;
      transition: opacity 300ms,
        width 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275), border-color 300ms,
        border-radius 300ms;
      width: 98%;
      z-index: 0;
    }
  }
`

export const StyledPrimaryText = styled.span`
  font-size: 16px;
  margin: 0;
  font-weight: bold;
`
export const StyledSecondaryText = styled.span`
  font-size: 13px;
  margin: 0;
  color: ${props => props.theme.main.colors.inputColor};
`
export const StyledWarning = styled(StyledPrimaryText)`
  color: ${props => props.theme.main.colors.errorColor};
`
