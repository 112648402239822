// @flow

import { requestTypeCreator } from 'src/helpers'

import type {
  BasicRedux,
  GetLeaguesActionTypes,
  GetUserLeaguesActionTypes,
  GetLeaguesStatusesActionTypes,
  GetLeagueOrganizerActionTypes,
  GetLeagueActionTypes,
  GetLeaguesOrganizationsActionTypes,
  EditLeagueActionTypes,
  EditLeagueStatusActionTypes,
  DeleteLeagueActionType,
  GetLeagueSurveyActionTypes,
  GetLeagueInfoActionTypes,
  SetLeaguePermissionsActionTypes,
} from './leaguesTypes'

export const GET_LEAGUES = requestTypeCreator('GET_LEAGUES')
export const GET_USER_LEAGUES = requestTypeCreator('GET_USER_LEAGUES')
export const GET_USER_ALL_LEAGUES = requestTypeCreator('GET_USER_ALL_LEAGUES')
export const GET_LEAGUES_STATUSES = requestTypeCreator('GET_LEAGUES_STATUSES')
export const GET_LEAGUES_ORGANIZER = requestTypeCreator('GET_LEAGUES_ORGANIZER')
export const GET_LEAGUE = requestTypeCreator('GET_LEAGUE')
export const GET_LEAGUE_INFO = requestTypeCreator('GET_LEAGUE_INFO')
export const GET_LEAGUES_ORGANIZATIONS = requestTypeCreator('GET_LEAGUES_ORGANIZATIONS')
export const EDIT_LEAGUE = requestTypeCreator('EDIT_LEAGUE')
export const EDIT_LEAGUE_SURVEY = requestTypeCreator('EDIT_LEAGUE_SURVEY')
export const DELETE_LEAGUE = requestTypeCreator('DELETE_LEAGUE')
export const EDIT_LEAGUE_STATUS = requestTypeCreator('EDIT_LEAGUE_STATUS')
export const EDIT_LEAGUE_VENDOR = requestTypeCreator('EDIT_LEAGUE_VENDOR')
export const EDIT_LEAGUE_PRICES = requestTypeCreator('EDIT_LEAGUE_PRICES')
export const GET_LEAGUE_SURVEY = requestTypeCreator('GET_LEAGUE_SURVEY')
export const SET_LEAGUE_FAN_GEAR_CODE = requestTypeCreator('SET_LEAGUE_FAN_GEAR_CODE')
export const SET_LEAGUE_PERMISSIONS = requestTypeCreator('SET_LEAGUE_PERMISSIONS')
export const GET_LEAGUE_PROGRAMS = requestTypeCreator('GET_LEAGUE_PROGRAMS')
export const GET_LEAGUE_ITEM_TYPES = requestTypeCreator('GET_LEAGUE_ITEM_TYPES')

export const getLeagues = {
  error: (payload: any): GetLeaguesActionTypes => ({ type: GET_LEAGUES.FAILURE, payload }),
  request: (payload: any): GetLeaguesActionTypes => ({ type: GET_LEAGUES.REQUEST, payload }),
  success: (payload: any): GetLeaguesActionTypes => ({ type: GET_LEAGUES.SUCCESS, payload }),
}

export const getUserLeagues = {
  error: (payload: any): GetUserLeaguesActionTypes => ({ type: GET_USER_LEAGUES.FAILURE, payload }),
  request: (payload: any): GetUserLeaguesActionTypes => ({ type: GET_USER_LEAGUES.REQUEST, payload }),
  success: (payload: any): GetUserLeaguesActionTypes => ({ type: GET_USER_LEAGUES.SUCCESS, payload }),
}
export const getUserAllLeagues = {
  error: (payload: any): GetUserLeaguesActionTypes => ({ type: GET_USER_ALL_LEAGUES.FAILURE, payload }),
  request: (payload: any): GetUserLeaguesActionTypes => ({ type: GET_USER_ALL_LEAGUES.REQUEST, payload }),
  success: (payload: any): GetUserLeaguesActionTypes => ({ type: GET_USER_ALL_LEAGUES.SUCCESS, payload }),
}

export const getLeaguesStatuses = {
  error: (payload: any): GetLeaguesStatusesActionTypes => ({ type: GET_LEAGUES_STATUSES.FAILURE, payload }),
  request: (payload: any): GetLeaguesStatusesActionTypes => ({ type: GET_LEAGUES_STATUSES.REQUEST, payload }),
  success: (payload: any): GetLeaguesStatusesActionTypes => ({ type: GET_LEAGUES_STATUSES.SUCCESS, payload }),
}

export const getLeaguesOrganizer = {
  error: (payload: any): GetLeagueOrganizerActionTypes => ({ type: GET_LEAGUES_ORGANIZER.FAILURE, payload }),
  request: (payload: any): GetLeagueOrganizerActionTypes => ({ type: GET_LEAGUES_ORGANIZER.REQUEST, payload }),
  success: (payload: any): GetLeagueOrganizerActionTypes => ({ type: GET_LEAGUES_ORGANIZER.SUCCESS, payload }),
}

export const getLeague = {
  error: (payload: any): GetLeagueActionTypes => ({ type: GET_LEAGUE.FAILURE, payload }),
  request: (payload: any): GetLeagueActionTypes => ({ type: GET_LEAGUE.REQUEST, payload }),
  success: (payload: any): GetLeagueActionTypes => ({ type: GET_LEAGUE.SUCCESS, payload }),
}

export const getLeagueInfo = {
  error: (payload: any): GetLeagueInfoActionTypes => ({ type: GET_LEAGUE_INFO.FAILURE, payload }),
  request: (payload: any): GetLeagueInfoActionTypes => ({ type: GET_LEAGUE_INFO.REQUEST, payload }),
  success: (payload: any): GetLeagueInfoActionTypes => ({ type: GET_LEAGUE_INFO.SUCCESS, payload }),
}

export const getLeaguesOrganizations = {
  error: (payload: any): GetLeaguesOrganizationsActionTypes => ({ type: GET_LEAGUES_ORGANIZATIONS.FAILURE, payload }),
  request: (payload: any): GetLeaguesOrganizationsActionTypes => ({ type: GET_LEAGUES_ORGANIZATIONS.REQUEST, payload }),
  success: (payload: any): GetLeaguesOrganizationsActionTypes => ({ type: GET_LEAGUES_ORGANIZATIONS.SUCCESS, payload }),
}

export const editLeague = {
  error: (payload: any): EditLeagueActionTypes => ({ type: EDIT_LEAGUE.FAILURE, payload }),
  request: (payload: any): EditLeagueActionTypes => ({ type: EDIT_LEAGUE.REQUEST, payload }),
  success: (payload: any): EditLeagueActionTypes => ({ type: EDIT_LEAGUE.SUCCESS, payload }),
}

export const editLeagueSurvey = {
  success: (payload: any): EditLeagueActionTypes => ({ type: EDIT_LEAGUE_SURVEY.SUCCESS, payload }),
}

export const deleteLeague = {
  error: (payload: any): DeleteLeagueActionType => ({ type: DELETE_LEAGUE.FAILURE, payload }),
  request: (payload: any): DeleteLeagueActionType => ({ type: DELETE_LEAGUE.REQUEST, payload }),
  success: (payload: any): DeleteLeagueActionType => ({ type: DELETE_LEAGUE.SUCCESS, payload }),
}
export const editLeagueStatus = {
  error: (payload: any): EditLeagueStatusActionTypes => ({ type: EDIT_LEAGUE_STATUS.FAILURE, payload }),
  request: (payload: any): EditLeagueStatusActionTypes => ({ type: EDIT_LEAGUE_STATUS.REQUEST, payload }),
  success: (payload: any): EditLeagueStatusActionTypes => ({ type: EDIT_LEAGUE_STATUS.SUCCESS, payload }),
}
export const setLeaguePermissions = {
  error: (payload: any): SetLeaguePermissionsActionTypes => ({ type: SET_LEAGUE_PERMISSIONS.FAILURE, payload }),
  request: (payload: any): SetLeaguePermissionsActionTypes => ({ type: SET_LEAGUE_PERMISSIONS.REQUEST, payload }),
  success: (payload: any): SetLeaguePermissionsActionTypes => ({ type: SET_LEAGUE_PERMISSIONS.SUCCESS, payload }),
}

export const editLeagueVendor = {
  error: (payload: any): BasicRedux => ({ type: EDIT_LEAGUE_VENDOR.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: EDIT_LEAGUE_VENDOR.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: EDIT_LEAGUE_VENDOR.SUCCESS, payload }),
}

export const editLeaguePrices = {
  error: (payload: any): BasicRedux => ({ type: EDIT_LEAGUE_PRICES.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: EDIT_LEAGUE_PRICES.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: EDIT_LEAGUE_PRICES.SUCCESS, payload }),
}

export const getLeagueSurvey = {
  error: (payload: any): GetLeagueSurveyActionTypes => ({ type: GET_LEAGUE_SURVEY.FAILURE, payload }),
  request: (payload: any): GetLeagueSurveyActionTypes => ({ type: GET_LEAGUE_SURVEY.REQUEST, payload }),
  success: (payload: any): GetLeagueSurveyActionTypes => ({ type: GET_LEAGUE_SURVEY.SUCCESS, payload }),
}

export const setLeagueFanGearCode = {
  error: (payload: any): GetLeagueSurveyActionTypes => ({ type: SET_LEAGUE_FAN_GEAR_CODE.FAILURE, payload }),
  request: (payload: any): GetLeagueSurveyActionTypes => ({ type: SET_LEAGUE_FAN_GEAR_CODE.REQUEST, payload }),
  success: (payload: any): GetLeagueSurveyActionTypes => ({ type: SET_LEAGUE_FAN_GEAR_CODE.SUCCESS, payload }),
}

export const getLeaguePrograms = {
  error: (payload: any): BasicRedux => ({ type: GET_LEAGUE_PROGRAMS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_LEAGUE_PROGRAMS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_LEAGUE_PROGRAMS.SUCCESS, payload }),
}

export const getLeagueItemTypes = {
  error: (payload: any): BasicRedux => ({ type: GET_LEAGUE_ITEM_TYPES.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_LEAGUE_ITEM_TYPES.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_LEAGUE_ITEM_TYPES.SUCCESS, payload }),
}
