// @flow
import React from 'react'

// types
import type { TextFieldProps } from './TextFieldTypes'

// style
import { StyledTextField } from './TextFieldStyles'

function TextField(props: TextFieldProps) {
  const { onChange, placeholder, value } = props

  return <StyledTextField onChange={onChange} value={value} placeholder={placeholder} />
}

export default TextField
