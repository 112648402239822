import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledCard = styled.div`
    border: 1px solid rgb(235, 237, 239);
    border-left: 5px solid #4caf50;
    border-radius: 3px;
    margin-bottom: 20px;
    
    .card-layout {
        color: inherit;
        display: block;
        padding: 10px 15px 25px;
        
        :hover {
            background-color: rgba(31,46,81,0.03);
            text-decoration: none;
        }
    }
    
    .card-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        
        .title {
            text-transform: uppercase;
        }
        
        h5 {
            margin-bottom: 0;
        }
    }
    
    .card-body {
        line-height: 1.2;
        padding-top: 10px;
        
        > * {
            margin-bottom: 8px;
        }
        
        .value {
            padding-left: 4px;
        }
        
        .label {
            font-weight: 300;
            opacity: 0.8;
        
        
            svg {
                bottom: -4px;
                height: 20px;
                position: relative;
                margin-right: 4px;
                width: 20px;
            }
        }
    }
    
    .location {
        display: flex;
    }
    
    .location-item {
        align-items: flex-end;
        display: flex;
        padding-right: 25px;
    }
    
    @media ${device.mobileL} {
        .location {
            flex-direction: column;
        }
        
        .location-item:first-child {
            margin-bottom: 5px;
            padding-right: 0;
        }
        
        .card-layout {
            padding: 10px 15px 10px;
        }
    }
`

