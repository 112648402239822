// @flow
import type { AccountsActionTypes } from '../actions/accounts/accountsTypes'

import {
  GET_ACCOUNTS,
  GET_USER_ROLES,
  GET_ACCOUNT,
  EDIT_ACCOUNT,
  EDIT_USER_EMAIL,
  DELETE_ACCOUNT,
  GET_ACCOUNT_CHANGES,
} from 'src/redux/actions/accounts/accounts'

export type UserItem = {
    id: number,
    first_name: string,
    last_name: string,
    roles: string[],
    league: string[],
    state: string,
    country: string,
    email: string,
    zip: string,
    phone: string,
    agreement_version_id: ?number,
    is_activated: boolean,
    owner_id: ?number,
    logged_at: ?string
};

export type UsersData = {
    total: number,
    offset: number,
    limit: number,
    page: number,
    results: UserItem[]
};

export type UserRoles = string[];

export type UsersState = {
  isLoading: boolean,
  isLoadingRoles: boolean,
  usersData: ?UsersData,
  userRoles?: UserRoles,
  usersErrors: ?{ [key: string]: any } | ?string,
  currentAccount: ?UserItem,
  currentAccountChanges: [],
  editUserEmailSuccess: boolean
};

const initialState = {
  isLoading: false,
  isLoadingRoles: false,
  usersData: null,
  userRoles: [],
  limit: null,
  offset: null,
  usersErrors: null,
  currentAccount: null,
  currentAccountChanges: [],
  editUserEmailSuccess: false,
}

const accounts = (state: UsersState = initialState, action: AccountsActionTypes): UsersState => {
  switch (action.type) {
    case GET_ACCOUNTS.REQUEST:
    case GET_ACCOUNT.REQUEST:
    case GET_ACCOUNT_CHANGES.REQUEST:
    case EDIT_ACCOUNT.REQUEST:
    case DELETE_ACCOUNT.REQUEST:
    case EDIT_USER_EMAIL.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        limit: null,
        offset: null,
        usersErrors: null,
      }
    }
    case GET_USER_ROLES.REQUEST:
    {
      return {
        ...state,
        isLoadingRoles: true,
      }
    }

    case GET_ACCOUNTS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        usersData: { ...action.payload },
        usersErrors: null,
      }
    }

    case GET_ACCOUNT.SUCCESS:
    case EDIT_ACCOUNT.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        currentAccount: { ...action.payload },
        currentAccountChanges: [],
        usersErrors: null,
      }
    }
    case GET_ACCOUNT_CHANGES.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        currentAccountChanges: [...action.payload],
      }
    }

    case DELETE_ACCOUNT.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        usersErrors: null,
        currentAccountChanges: [],
      }
    }

    case GET_USER_ROLES.SUCCESS:
    {
      return {
        ...state,
        isLoadingRoles: false,
        userRoles: [...action.payload],
      }
    }

    case EDIT_USER_EMAIL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        editUserEmailSuccess: action.payload,
      }
    }

    case GET_ACCOUNTS.FAILURE:
    case EDIT_ACCOUNT.FAILURE:
    case GET_ACCOUNT.FAILURE:
    case DELETE_ACCOUNT.FAILURE:
    case GET_ACCOUNT_CHANGES.FAILURE:
    case EDIT_USER_EMAIL.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        usersErrors: action.payload,
      }
    }

    case GET_USER_ROLES.FAILURE:
    {
      return {
        ...state,
        isLoadingRoles: false,
        usersErrors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default accounts
