// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import config from 'src/config/formsConfig'
import { EDIT_SEASON_FREE_SHIPPING_COUNT } from 'src/redux/actions/seasons/seasons'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'
import { getLeague } from 'src/redux/actions/leagues/leagues'

function * watchSubmitFreeShippingCount(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(EDIT_SEASON_FREE_SHIPPING_COUNT.REQUEST)
    const { league_id, season_id, free_shipping_count } = payload

    try {
      yield call(http, `admin/season/${season_id}/free-shipping-count`, {
        body: JSON.stringify({ free_shipping_count: +free_shipping_count }),
        method: 'PATCH',
      })
      yield put(getLeague.request(league_id))
    } catch (e) {
      const formId = config.seasonFreeShippingCountForm.id
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchSubmitFreeShippingCount)]
