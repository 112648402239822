// @flow
import React from 'react'
import { formatFullDate } from 'src/helpers/dateHelpers'
import { StylesAdminInfo } from './AdminInfoStyles'

const AdminInfo = (
  {
    last_updated_admin: { first_name, last_name },
    status_updated_at,
  }: {last_updated_admin: {first_name: string, last_name: string}, status_updated_at: string}) => {
  return (
    <StylesAdminInfo>
      Updated by{' '}
      <span className="value">{`${first_name} ${last_name} `}</span>
      on{' '}
      <span className="value">
        {formatFullDate(status_updated_at)}{' '}
      </span>
    </StylesAdminInfo>
  )
}

export default AdminInfo
