// @flow

import type { BasicReduxActionTypes } from 'src/redux/actions/resources/resourcesTypes'
import { GET_RESOURCES, UPDATE_RESOURCES } from 'src/redux/actions/resources/resources'

export type ResourceDataItem = {
  id: number,
  title: string,
  link: string,
  order_resource: number
};

export type ResourcesState = {
  isLoading: boolean,
  data: ResourceDataItem[],
  resourcesError?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  data: [],
  resourcesError: null,
}

const resources = (state: ResourcesState = initialState, action: BasicReduxActionTypes) => {
  switch (action.type) {
    case UPDATE_RESOURCES.REQUEST:
    case GET_RESOURCES.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        resourcesError: null,
      }
    }
    case UPDATE_RESOURCES.FAILURE:
    case GET_RESOURCES.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        resourcesError: action.payload,
      }
    }
    case GET_RESOURCES.SUCCESS:
    case UPDATE_RESOURCES.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default resources
