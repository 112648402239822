// @flow
import {
  GET_ORDER_SETTINGS,
  EDIT_ORDER_SETTINGS,
  UPDATE_OWNER_LEAGUES_PRICES,
  UPDATE_OWNER_LEAGUES_VENDOR,
  FORCE_LEAGUE_VENDOR_UPDATE,
} from 'src/redux/actions/orderSettings/orderSettings'
import type { OrderSettingsActionTypes } from 'src/redux/actions/orderSettings/orderSettingsTypes.js'
import type { OrderPricesType, VendorType } from 'src/common/constants.types'
import type { failedLeagueVendorUpdateErrorType } from 'src/common/components/failedLeagueVendorUpdateBlock/failedLeagueVendorUpdateBlockTypes'

type OwnerPricesType = {
    [key: VendorType]: OrderPricesType
};

export type OwnerSettingsData = {
    id: number,
    default_prices: OwnerPricesType,
    default_vendor: VendorType,
    can_pay_be_check: boolean,
    allowed_outstanding_amount: string,
    outstanding_amount: string,
    credit_amount: string
};

export type OrderSettingsState = {
  isLoading: boolean,
  ownerSettingsData: ?OwnerSettingsData,
  orderSettingsErrors?: ?{ [key: string]: any } | ?string,
  orderSettingsVendorUpdateError: failedLeagueVendorUpdateErrorType[]
};

const initialState = {
  isLoading: false,
  ownerSettingsData: null,
  orderSettingsErrors: null,
  orderSettingsVendorUpdateError: [],
}

const insurances = (state: OrderSettingsState = initialState, action: OrderSettingsActionTypes): OrderSettingsState => {
  switch (action.type) {
    case GET_ORDER_SETTINGS.REQUEST:
    case EDIT_ORDER_SETTINGS.REQUEST:
    case UPDATE_OWNER_LEAGUES_PRICES.REQUEST:
    case UPDATE_OWNER_LEAGUES_VENDOR.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        orderSettingsErrors: null,
        orderSettingsVendorUpdateError: [],
      }
    }

    case GET_ORDER_SETTINGS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        ownerSettingsData: { ...action.payload },
        orderSettingsErrors: null,
      }
    }

    case EDIT_ORDER_SETTINGS.SUCCESS:
    case UPDATE_OWNER_LEAGUES_PRICES.SUCCESS:
    case UPDATE_OWNER_LEAGUES_VENDOR.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        orderSettingsErrors: null,
      }
    }

    case GET_ORDER_SETTINGS.FAILURE:
    case EDIT_ORDER_SETTINGS.FAILURE:
    case UPDATE_OWNER_LEAGUES_PRICES.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        orderSettingsErrors: action.payload,
      }
    }

    case UPDATE_OWNER_LEAGUES_VENDOR.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        orderSettingsVendorUpdateError: [...action.payload],
      }
    }

    case FORCE_LEAGUE_VENDOR_UPDATE.SUCCESS:
    {
      return {
        ...state,
        orderSettingsVendorUpdateError: state.orderSettingsVendorUpdateError.filter(err => err.league_id !== action.payload),
      }
    }

    default: {
      return state
    }
  }
}

export default insurances
