// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_LEAGUE_INSURANCES, getLeagueInsurances } from 'src/redux/actions/insurances/insurances'
import http from 'src/services/http'
import moment from 'moment-timezone'
import config from 'src/config/formsConfig'
import { stopAsyncValidation, stopSubmit } from 'redux-form'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetLeagueInsurance(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(GET_LEAGUE_INSURANCES.REQUEST)

    const formId = config.searchIncurancesForm.id
    const params = {
      limit: values.limit,
      page: values.page,
      approval_status: values.approval_status,
      expiration_status: values.expiration_status,
      start_date: values.start_date && moment(values.start_date, 'MM/DD/YYYY h:mm:ss a').utc(),
      end_date: values.end_date && moment(values.end_date, 'MM/DD/YYYY h:mm:ss a').utc(),
    }

    try {
      const { leagueId } = values
      const { data } = yield call(http, `admin/league/${leagueId}/insurances`, {
        method: 'GET',
        params,
      })

      yield put(getLeagueInsurances.success(data))
    } catch (e) {
      yield put(getLeagueInsurances.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(stopAsyncValidation(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchGetLeagueInsurance)]
