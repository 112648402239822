// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import config from 'src/config/formsConfig'
import { SET_USER_PASSWORD, setUserPassword } from 'src/redux/actions/user/user'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'
import { setToken } from '../../services/user'

function * watchPassword(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { password, token, redirectCb },
    } = yield take(SET_USER_PASSWORD.REQUEST)
    const formId = config.activatePasswordForm.id
    try {
      const { data: { user, token: userToken } } = yield http('activate-admin-account', {
        body: JSON.stringify({
          password,
          token,
        }),
        method: 'POST',
      })

      if (user && userToken) {
        setToken(userToken)
        yield put(setUserPassword.success(user))
        yield call(redirectCb, '/')
      } else {
        throw new Error('something went wrong')
      }
    } catch (e) {
      yield put(setUserPassword.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchPassword)]
