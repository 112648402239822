import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

const useStyles = makeStyles(theme => ({
  inputRoot: {
    color: '#000',
    borderRadius: 0,
    height: 42,
    fontSize: 14,
    padding: '0px 9px !important',
    margin: '6px 0 4px',
    borderColor: '#ccc',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ccc',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ccc',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ccc',
    },
  },
}))

const FormAutocomplete = ({
  options,
  input: { onChange, onBlur, value, ...rest },
  label,
  meta: { touched, error },
  children,
}) => {
  const renderFromHelper = ({ touched, error }) => {
    if (touched && error) {
      return <FormHelperText>{touched && error}</FormHelperText>
    }
  }
  const classes = useStyles()
  return (
    <FormControl fullWidth error={touched && !!error}>
      <Autocomplete
        classes={classes}
        options={options}
        getOptionLabel={option => option.name}
        onChange={(event, newValue) => onChange(newValue?.value)}
        onBlur={(event, newValue) => onBlur(value)}
        renderInput={params => {
          return (
            <TextField fullWidth {...rest} {...params} variant="outlined" />
          )
        }}
        fullWidth
      />
      {renderFromHelper({ touched, error })}
    </FormControl>
  )
}
export default FormAutocomplete
