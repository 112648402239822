// @flow

import { reduxForm, getFormValues } from 'redux-form'
import config from 'src/config/formsConfig'
import CheckReceived from './CheckReceived'
import { connect } from 'react-redux'
import { compose } from 'redux'

const Form = reduxForm({
  destroyOnUnmount: true,
  form: config.checkReceived.id,
})(CheckReceived)

const mapStateToProps = state => {
  const formVals = getFormValues(config.checkReceived.id)(state) || {}
  return {
    formVals,
  }
}

export default compose(
  connect(mapStateToProps),
)(Form)
