// @flow

import { requestTypeCreator } from 'src/helpers'

import type {
  BasicRedux,
  SetUserError,
  SetUserRequest,
  SetUserResponse,
  SetUserActionTypes,
  EditUserActionTypes,
  SetUserPasswordTypes,
  EditUserPasswordTypes,
} from './userTypes'

export const SET_USER = requestTypeCreator('SET_USER')
export const EDIT_USER = requestTypeCreator('EDIT_USER')
export const SET_USER_PASSWORD = requestTypeCreator('SET_USER_PASSWORD')
export const EDIT_USER_PASSWORD = requestTypeCreator('EDIT_USER_PASSWORD')

export const RESET_USER = 'RESET_USER'
export const LOGOUT_USER = requestTypeCreator('LOGOUT_USER')
export const CONNECT_TO_ACCOUNT = requestTypeCreator('CONNECT_TO_ACCOUNT')
export const EDIT_USER_GEOLOCATION_ZIP = 'EDIT_USER_GEOLOCATION_ZIP'

export const setUser = {
  error: (payload: SetUserError): SetUserActionTypes => ({ type: SET_USER.FAILURE, payload }),
  request: (payload: SetUserRequest): SetUserActionTypes => ({ type: SET_USER.REQUEST, payload }),
  success: (payload: SetUserResponse): SetUserActionTypes => ({ type: SET_USER.SUCCESS, payload }),
}

export const logout = {
  error: (payload: any): BasicRedux => ({ type: LOGOUT_USER.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: LOGOUT_USER.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: LOGOUT_USER.SUCCESS, payload }),
}

export const connectTo = {
  error: (payload: any): BasicRedux => ({ type: CONNECT_TO_ACCOUNT.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: CONNECT_TO_ACCOUNT.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: CONNECT_TO_ACCOUNT.SUCCESS, payload }),
}

export const resetUser = {
  success: () => ({ type: RESET_USER }),
}

export const editUser = {
  error: (payload: SetUserError): EditUserActionTypes => ({ type: EDIT_USER.FAILURE, payload }),
  request: (payload: any): EditUserActionTypes => ({ type: EDIT_USER.REQUEST, payload }),
  success: (payload: any): EditUserActionTypes => ({ type: EDIT_USER.SUCCESS, payload }),
}

export const setUserPassword = {
  error: (payload: any): SetUserPasswordTypes => ({ type: SET_USER_PASSWORD.FAILURE, payload }),
  request: (payload: any): SetUserPasswordTypes => ({ type: SET_USER_PASSWORD.REQUEST, payload }),
  success: (payload: any): SetUserPasswordTypes => ({ type: SET_USER_PASSWORD.SUCCESS, payload }),
}

export const editUserPassword = {
  error: (payload: any): EditUserPasswordTypes => ({ type: EDIT_USER_PASSWORD.FAILURE, payload }),
  request: (payload: any): EditUserPasswordTypes => ({ type: EDIT_USER_PASSWORD.REQUEST, payload }),
  success: (payload: any): EditUserPasswordTypes => ({ type: EDIT_USER_PASSWORD.SUCCESS, payload }),
}
export const editUserGeoLocationZip = {
  success: (payload: any): EditUserActionTypes => ({ type: EDIT_USER_GEOLOCATION_ZIP, payload }),
}
