// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'

const InsuranceDetails = lazy(() => import('src/modules/insurance/insuranceDetails'))
const InsurancesList = lazy(() => import('src/modules/insurance/insurancesList'))

export default [
  <PrivateRoute exact={true} path="/insurances" component={InsurancesList} key="insurances-list"/>,
  <PrivateRoute exact={true} path="/insurances/:insuranceId" component={InsuranceDetails} key="insurances-details"/>,
]
