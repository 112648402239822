// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getInsurances,
  getInsurancesStatuses,
  getInsurance,
  submitInsuranceStatus,
  getLeagueInsurances,
  bindInsurance,
  checkFileInsurance,
  getDeclineReasons,
} from 'src/redux/actions/insurances/insurances'

const withInsurances = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithInsurancesComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ insurances }) => ({ insurances })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getInsurances: values => dispatch(getInsurances.request(values)),
    getInsurancesStatuses: values => dispatch(getInsurancesStatuses.request(values)),
    getInsurance: () => dispatch(getInsurance.request({ insuranceId: +ownProps.match.params.insuranceId })),
    submitInsuranceStatus: values => dispatch(submitInsuranceStatus.request(values)),
    getLeagueInsurances: values => dispatch(getLeagueInsurances.request({ ...values, leagueId: ownProps.match.params.leagueId })),
    bindInsurance: values => dispatch(bindInsurance.request(values)),
    checkFileInsurance: values => dispatch(checkFileInsurance.request(values)),
    getDeclineReasons: values => dispatch(getDeclineReasons.request(values)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithInsurancesComponent))
}

export default withInsurances
