// @flow

import getFeatures from './getFeatures'
import updateFeature from './updateFeature'
import getFeature from './getFeature'

export default [
  ...getFeatures,
  ...updateFeature,
  ...getFeature,
]
