// @flow
import React, { useEffect, useRef, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { SimpleCheckIcon } from 'src/assets/icons'
// styles
import { StyledProgress } from './progressTrackerStyles'

const getStepName = (pathname: string): string => {
  const array = pathname.split('/')
  if (array[array.length - 1] === 'address-validation') {
    return 'address-info'
  }
  return array[array.length - 1]
}

const stepsConfig = [
  {
    stepName: 'choose-teams',
    displayName: '1.Choose Teams',
    value: 1,
  },
  {
    stepName: 'main-order',
    displayName: '2.Order Items',
    value: 2,
  },
  {
    stepName: 'address-info',
    displayName: '3.Addresses',
    value: 3,
  },
  {
    stepName: 'shipping',
    displayName: '4.Shipping',
    value: 4,
  },
  {
    stepName: 'order-summary',
    displayName: '5.Summary',
    value: 5,
  },
  {
    stepName: 'payment',
    displayName: '6.Payment',
    value: 6,
  },
]

const renderLinkComponent = ({ item, leagueId, seasonId, orderId, currentStep, isCurrentStep }) => {
  let LinkComponent = null
  if (item.value <= 3 && currentStep && item.value < currentStep.value) {
    LinkComponent = (
      <Link to={`/order/league/${leagueId}/season/${seasonId}/${item.stepName}`}>
        <span className="step-icon">
          <SimpleCheckIcon />
        </span>
        <span className="step-title">{item.displayName}</span>
      </Link>
    )
  } else if (item.value > 3 && currentStep && item.value < currentStep.value) {
    LinkComponent = (
      <Link to={`/order/league/${leagueId}/season/${seasonId}/${orderId}/${item.stepName}`}>
        <span className="step-icon">
          <SimpleCheckIcon />
        </span>
        <span className="step-title">{item.displayName}</span>
      </Link>)
  } else {
    LinkComponent = (
      <>
        <span className="step-icon"></span>
        <span className="step-title">{item.displayName}</span>
      </>
    )
  }

  return LinkComponent
}

const renderSteps = ({ stepName, leagueId, seasonId, orderId }) => {
  const currentStep = stepsConfig.find(step => step.stepName === stepName)

  return stepsConfig.map(item => {
    const isCurrentStep = item.stepName === stepName

    return (
      <div key={item.stepName} className={`step ${isCurrentStep ? 'current-step' : ''}`}>
        {renderLinkComponent({ item, leagueId, seasonId, orderId, currentStep, isCurrentStep })}
      </div>
    )
  })
}

// TODO: refactor

const ProgressTracker = props => {
  const stepName = getStepName(props.location.pathname)
  const { leagueId, seasonId } = props.match.params || {}
  const orderId = props.match.params.orderId || props.orderId

  const [columnSteps, setColumnSteps] = useState(false)
  const progressRef = useRef(null)

  const handleResize = () => {
    if (progressRef.current) setColumnSteps(progressRef.current.offsetHeight > 40)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <StyledProgress columnSteps={columnSteps} ref={progressRef}>
      {renderSteps({ stepName, leagueId, seasonId, orderId })}
    </StyledProgress>
  )
}

export default withRouter(ProgressTracker)
