// @flow
import React from 'react'
// $FlowFixMe
import { Droppable, Draggable } from 'react-beautiful-dnd'

// styles
import {
  TableExportDragDropColumnStyle,
  TableExportDragDropColumnHeaderStyle,
  TableExportDragDropInnerColumnStyle,
  TableExportDraggableItemStyle,
} from '../TableExportStyles'

// types
import type {
  TableExportDragDropColumnType,
} from '../TableExportTypes'

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? 'rgb(218,229,251)' : 'white',
  // styles we need to apply on draggables
  ...draggableStyle,
})

const TableExportDragDropColumn = (props: TableExportDragDropColumnType) => {
  const {
    fields,
    droppableId,
    title,
  } = props

  return (
    <TableExportDragDropColumnStyle>
      <TableExportDragDropColumnHeaderStyle>{title}</TableExportDragDropColumnHeaderStyle>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <TableExportDragDropInnerColumnStyle ref={provided.innerRef}>
            {fields.map((item, index) => (
              <Draggable
                key={item.name}
                draggableId={item.name}
                index={index}>
                {(provided, snapshot) => (
                  <TableExportDraggableItemStyle
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}>
                    {item.label}
                  </TableExportDraggableItemStyle>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </TableExportDragDropInnerColumnStyle>
        )}
      </Droppable>
    </TableExportDragDropColumnStyle>
  )
}

export default TableExportDragDropColumn
