// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { DELETE_ACCOUNT, deleteAccount } from 'src/redux/actions/accounts/accounts'
import http from 'src/services/http'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchDeleteAccount(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(DELETE_ACCOUNT.REQUEST)
    try {
      const { accountId } = payload
      const { data } = yield call(http, `admin/user/${accountId}`, {
        method: 'DELETE',
      })

      yield put(deleteAccount.success(data))
      yield call(history.push, '/users')
    } catch (e) {
      yield put(deleteAccount.error(e.message))
    }
  }
}

export default [fork(watchDeleteAccount)]
