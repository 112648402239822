// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getLatestAgreementData,
  releaseNewAgreementVersion,
} from 'src/redux/actions/globalSettings/globalSettings'

const withGlobalSettings = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithGlobalSettingsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ globalSettings }) => ({ globalSettings })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getLatestAgreementData: () => dispatch(getLatestAgreementData.request()),
    releaseNewAgreementVersion: () => dispatch(releaseNewAgreementVersion.request()),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithGlobalSettingsComponent))
}

export default withGlobalSettings
