// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'

const OrdersList = lazy(() => import('src/modules/orders/ordersList'))
const PaidOrderContainer = lazy(() => import('src/modules/orders/paidOrder'))

export default [
  <PrivateRoute exact={true} path="/orders-list" component={OrdersList} key="orders-list"/>,
  <PrivateRoute exact={true} path="/orders-list/:leagueId/:orderId" component={PaidOrderContainer} key="orders-list"/>,
]
