// @flow
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Field } from 'redux-form'
import { DeleteIcon } from 'src/assets/icons'
import { ButtonOutline, ErrorBlock } from 'src/common/components'
import SizeInput from './fragments/sizeInput'
import { normalizeSize } from 'src/helpers/formNormalizers'
import { maxOrderValue } from 'src/helpers/formValidation'
import { isEmpty } from 'lodash'
import { idToInputName } from 'src/helpers/shapeOrder'
import { humanize } from 'src/helpers/valuesHumanizer'

// styles
import { StyledSizesBlock, StyledAction } from './orderJerseysFormStyles'

// types
import type { OrderJerseysFormPropsType, OptimizedFieldPropsType } from './OrderJerseysFormTypes'

class OptimizedField extends React.Component<OptimizedFieldPropsType> {
  shouldComponentUpdate(nextProps: OptimizedFieldPropsType) {
    if (nextProps.lastTouchedTeamFormField) {
      return idToInputName(nextProps.item.id) === nextProps.lastTouchedTeamFormField
    } else {
      return true
    }
  }

  render() {
    const {
      item,
      onBlur,
      validators,
    } = this.props

    return (
      <Field
        availableNumber={item.max_quantity}
        size={item.size}
        component={SizeInput}
        name={idToInputName(item.id)}
        normalize={normalizeSize}
        data_meta={`${item.type}-${item.size}`}
        validate={validators}
        inventory_limited={item.inventory_limited}
        onBlur={onBlur}
      />
    )
  }
}

const FieldWrapper = props => {
  const {
    item,
    onBlur,
    lastTouchedTeamFormField,
  } = props

  const [validators, updateValidator] = useState([])

  useEffect(() => {
    updateValidator(item.inventory_limited ? [maxOrderValue(item.max_quantity)] : [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.max_quantity])

  return (
    <OptimizedField
      item={item}
      onBlur={onBlur}
      validators={validators}
      lastTouchedTeamFormField={lastTouchedTeamFormField}
    />
  )
}

const portalError = error => {
  const el = document.getElementById('portal-error')
  if (el) {
    return createPortal(error, el)
  }
}

const cutInputName = formVals => {
  const result = {}
  for (const prop in formVals) {
    result[parseInt(prop)] = +formVals[prop]
  }
  return result
}

const renderFieldsByType = (items, type, onBlur, lastTouchedTeamFormField) => {
  return items && items.map(item => {
    if (item.size_type === type) {
      return (
        <div className="list-item" key={item.id}>
          <FieldWrapper
            item={item}
            onBlur={onBlur}
            lastTouchedTeamFormField={lastTouchedTeamFormField}
          />

        </div>
      )
    } else {
      return null
    }
  })
}

const getItemsBySublimatedProperty = items => {
  if (!Array.isArray(items)) return null
  const data = [...items]
  const sublimated = []
  const notSublimated = []
  data.forEach(item => {
    if (item.is_sublimated) {
      sublimated.push(item)
    } else {
      notSublimated.push(item)
    }
  })
  return {
    sublimated,
    notSublimated,
  }
}

class OptimizedOrderJerseysForm extends React.Component<OrderJerseysFormPropsType> {
  shouldComponentUpdate(nextProps: OrderJerseysFormPropsType) {
    return nextProps.shouldReRender
  }

  getPriceString = ({ type, price }) => {
    return `${type} ($${price} each)`
  }

  render() {
    const {
      coreJrs,
      performanceJrs,
      shorts,
      socks,
      sleeves,
      gloves,
      headbands,
      removeTeamFromMakeOrder,
      toggleAccordion,
      onBlur,
      hasError,
      lastTouchedTeamFormField,
      reset,
      fullName,
    } = this.props
    // need to display sublimated and not sublimated shorts separately, see NFLFLAG-1361
    const { sublimated: sublimatedShorts, notSublimated: notSublimatedShorts } = getItemsBySublimatedProperty(shorts) || {}
    return (
      <div>
        <StyledSizesBlock>
          <div className="items-wrapper">
            <div className="left-items">
              {performanceJrs && (
                <div className="type-wrapper">
                  <h4 className="type-title">

                    <span>{this.getPriceString({ type: 'Performance Jerseys', price: performanceJrs[0].price })}</span>
                  </h4>
                  <div className="sizes-row">
                    <h6 className="title">Youth Sizes</h6>
                    <div className="sizes-list">
                      {renderFieldsByType(performanceJrs, 'youth', onBlur, lastTouchedTeamFormField)}
                    </div>
                  </div>
                  <div className="sizes-row">
                    <h6 className="title">Adult Sizes</h6>
                    <div className="sizes-list">
                      {renderFieldsByType(performanceJrs, 'adult', onBlur, lastTouchedTeamFormField)}
                    </div>
                  </div>
                </div>
              )}
              {coreJrs && (
                <div className="type-wrapper">
                  <h4 className="type-title">
                    <span>{this.getPriceString({ type: 'Core Jerseys', price: coreJrs[0].price })} – While supplies last</span>
                  </h4>
                  <div className="sizes-row">
                    <h6 className="title">Youth Sizes</h6>
                    <div className="sizes-list">
                      {renderFieldsByType(coreJrs, 'youth', onBlur, lastTouchedTeamFormField)}
                    </div>
                  </div>
                  <div className="sizes-row">
                    <h6 className="title">Adult Sizes</h6>
                    <div className="sizes-list">
                      {renderFieldsByType(coreJrs, 'adult', onBlur, lastTouchedTeamFormField)}
                    </div>
                  </div>
                </div>
              )}
              {notSublimatedShorts && notSublimatedShorts.length
                ? (
                  <div className="type-wrapper">
                    <h4 className="type-title">
                      Optional:{' '}
                      {this.getPriceString({
                        type: 'Core Shorts',
                        price: notSublimatedShorts[0].price,
                      })}
                    </h4>
                    <div className="sizes">
                      <div className="sizes-row">
                        <h6 className="title">Youth Sizes</h6>
                        <div className="sizes-list">
                          {renderFieldsByType(notSublimatedShorts, 'youth', onBlur, lastTouchedTeamFormField)}
                        </div>
                      </div>
                      <div className="sizes-row">
                        <h6 className="title">Adult Sizes</h6>
                        <div className="sizes-list">
                          {renderFieldsByType(notSublimatedShorts, 'adult', onBlur, lastTouchedTeamFormField)}
                        </div>
                      </div>
                    </div>
                  </div>
                )
                : null}
              {sublimatedShorts && sublimatedShorts.length
                ? (
                  <div className="type-wrapper">
                    <h4 className="type-title">
                      Optional:
                      {this.getPriceString({
                        type: 'Team Branded Performance Shorts',
                        price: sublimatedShorts[0].price,
                      })}
                    </h4>
                    <div className="sizes-row">
                      <h6 className="title">Youth Sizes</h6>
                      <div className="sizes-list">
                        {renderFieldsByType(sublimatedShorts, 'youth', onBlur, lastTouchedTeamFormField)}
                      </div>
                    </div>
                    <div className="sizes-row">
                      <h6 className="title">Adult Sizes</h6>
                      <div className="sizes-list">
                        {renderFieldsByType(sublimatedShorts, 'adult', onBlur, lastTouchedTeamFormField)}
                      </div>
                    </div>
                  </div>
                )
                : null}
            </div>
            <div className="right-items">
              {socks && (
                <div className="sizes-row">
                  <h4>
                    Optional: {fullName} {''}
                    {this.getPriceString({
                      type: 'Socks',
                      price: socks[0].price,
                    })}
                  </h4>
                  <div className="sizes-list">
                    {socks.map(item => (<div className="list-item" key={item.id}>
                      <FieldWrapper
                        item={item}
                        onBlur={onBlur}
                        lastTouchedTeamFormField={lastTouchedTeamFormField}
                      />
                    </div>),
                    )}
                  </div>
                </div>
              )}
              {sleeves && (
                <div className="sizes-row">
                  <h4>
                    Optional: {humanize(sleeves[0].color)}{' '}
                    {this.getPriceString({
                      type: 'Sleeves',
                      price: sleeves[0].price,
                    })}{' '}
                    - Sold in pairs)
                  </h4>
                  <div className="sizes-list">
                    {sleeves.map(item => (<div className="list-item" key={item.id}>
                      <FieldWrapper
                        item={item}
                        onBlur={onBlur}
                        lastTouchedTeamFormField={lastTouchedTeamFormField}
                      />
                    </div>),
                    )}
                  </div>
                </div>
              )}
              {gloves && (
                <div className="type-wrapper">
                  <h4 className="type-title">
                    Optional:{' '}
                    {this.getPriceString({
                      type: 'Gloves',
                      price: gloves[0].price,
                    })}
                  </h4>
                  <div className="sizes-row">
                    <div className="sizes-list">
                      {gloves.map(item => (<div className="list-item" key={item.id}>
                        <FieldWrapper
                          item={item}
                          onBlur={onBlur}
                          lastTouchedTeamFormField={lastTouchedTeamFormField}
                        />
                      </div>),
                      )}
                    </div>
                  </div>
                </div>
              )}
              {headbands && (
                <div className="type-wrapper">
                  <h4 className="type-title">
                    Optional: {fullName} {''}
                    {this.getPriceString({
                      type: 'Headbands',
                      price: headbands[0].price,
                    })}
                  </h4>
                  <div className="sizes-row">
                    <div className="sizes-list">
                      {headbands.map(item => (<div className="list-item" key={item.id}>
                        <FieldWrapper
                          item={item}
                          onBlur={onBlur}
                          lastTouchedTeamFormField={lastTouchedTeamFormField}
                        />
                      </div>),
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </StyledSizesBlock>
        <StyledAction>
          <button type="reset" className="btn-delete" onClick={e => {
            e.preventDefault()
            removeTeamFromMakeOrder()
            reset()
          }}>
            <DeleteIcon />
            <span>Remove Team from the Order</span>
          </button>
          <ButtonOutline size="small" buttonText="Done" action={!hasError ? toggleAccordion : () => {}}/>
        </StyledAction>
      </div>
    )
  }
}

const OrderJerseysForm = (props: OrderJerseysFormPropsType) => {
  const {
    toggleAccordion,
    onUpdate,
    meta: { error },
    toggleStatus,
    formVals,
    valid,
    anyTouched,
    hasOrderSubmitFailed,
  } = props
  const emitUpdatedForm = isValid => {
    onUpdate && onUpdate({
      ...cutInputName(formVals),
      isValid,
    })
  }
  const shouldShowError = anyTouched || hasOrderSubmitFailed
  const isFormEmpty = isEmpty(formVals)

  useEffect(() => {
    toggleStatus(!valid)
    if (!shouldShowError) {
      toggleAccordion(!valid || isFormEmpty)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid, shouldShowError, isFormEmpty])

  useEffect(() => {
    if (valid && isFormEmpty) { // init vals
      return
    }
    emitUpdatedForm(valid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid])

  const onBlur = () => {
    emitUpdatedForm(valid)
  }

  return (
    <>
      <OptimizedOrderJerseysForm
        {...props}
        onBlur={onBlur}
      />
      <StyledAction className="justify-content-center text-center"> {error && shouldShowError && <ErrorBlock error={error}/>}</StyledAction>
      {error && shouldShowError && portalError(
        <ErrorBlock error={error}/>,
      )}
    </>
  )
}

export default OrderJerseysForm
