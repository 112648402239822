// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_LEAGUE, getLeague, getLeagueSurvey } from 'src/redux/actions/leagues/leagues'
import { getAddresses } from 'src/redux/actions/addresses/addresses'
import { getLeagueSeasons } from 'src/redux/actions/seasons/seasons'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetLeague(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(GET_LEAGUE.REQUEST)
    try {
      const { data } = yield call(http, `admin/league/initial-data/${payload}`, {
        method: 'GET',
      })

      yield put(getLeague.success(data.league))
      yield put(getLeagueSurvey.success(data.surveyData || []))
      yield put(getAddresses.success(data.addresses))
      yield put(getLeagueSeasons.success(data.seasons || []))
    } catch (e) {
      yield put(getLeague.error(e.message))
    }
  }
}

export default [fork(watchGetLeague)]
