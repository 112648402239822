// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import { getOrderRefunds } from 'src/redux/actions/refunds/refunds'

const withRefunds = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithRefundsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ refunds }) => ({ refunds })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getRefundsByOrderId: orderId => dispatch(getOrderRefunds.request({ orderId })),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithRefundsComponent)
}

export default withRefunds
