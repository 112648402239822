// @flow
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Layout } from 'src/common/components'
import { withUser } from 'src/decorators'
import { getToken } from 'src/services/user'

// types
import type { PrivateRouteProps } from './PrivateRouteTypes'

const PrivateRoute = ({
  component: Component,
  user,
  ...rest
}: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={props =>
        user.userData && getToken()
          ? (
            <>
              <Layout>
                <Component {...props} />
              </Layout>
            </>
          )
          : (
            <Redirect to="/login" />
          )
      }
    />
  )
}

export default withUser(PrivateRoute)
