// @flow

import React from 'react'
import { StyledRadioInput, StyledError, StyledFormInput } from './radioFieldStyles'
import type { RadioFieldPropsType } from './RadioFieldTypes'

const FormInput = ({
  input,
  meta: { touched, error },
  validationErrors,
  title,
  date,
  cost,
  defaultChecked,
  id, // form field id equals to validationErrors.errors[key]
  ...rest
}: RadioFieldPropsType) => {
  let errorBlock = null

  if (touched && (error || (validationErrors && validationErrors.errors[id]))) {
    errorBlock = (
      <div>
        <StyledError className="error-message">
          {error || validationErrors.message}
        </StyledError>
      </div>
    )
  }

  return (
    <>
      <StyledRadioInput className="radio-label">
        <StyledFormInput {...rest} {...input} />
        <div className="text-container">
          <span>{title}</span>
          <span>{date}</span>
          <span>{cost}</span>
        </div>
      </StyledRadioInput>
      {errorBlock}
    </>
  )
}

export default FormInput
