// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getLeagues,
  getUserLeagues,
  getLeaguesStatuses,
  getLeaguesOrganizer,
  getLeague,
  getLeaguesOrganizations,
  editLeague,
  editLeagueSurvey,
  deleteLeague,
  editLeagueStatus,
  editLeagueVendor,
  editLeaguePrices,
  getLeagueInfo,
  setLeaguePermissions,
  getUserAllLeagues,
  setLeagueFanGearCode,
  getLeaguePrograms,
  getLeagueItemTypes,
} from 'src/redux/actions/leagues/leagues'

const withLeagues = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithLeaguesComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapStateToProps = ({ leagues }) => ({ leagues })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getLeagues: values => dispatch(getLeagues.request(values)),
    getUserLeagues: values => dispatch(getUserLeagues.request({ userId: +ownProps.match.params.userId, limit: 100, ...values })),
    getUserAllLeagues: values => dispatch(getUserAllLeagues.request({ userId: +ownProps.match.params.userId, limit: 100, ...values })),
    getLeaguesStatuses: () => dispatch(getLeaguesStatuses.request()),
    getLeaguesOrganizer: values => dispatch(getLeaguesOrganizer.request({ ...values, leagueId: +ownProps.match.params.leagueId })),
    getLeague: leagueId => dispatch(getLeague.request(leagueId)),
    getLeaguesOrganizations: () => dispatch(getLeaguesOrganizations.request()),
    editLeague: values => dispatch(editLeague.request({ ...values, league_id: ownProps.match.params.leagueId })),
    editLeagueSurvey: values => dispatch(editLeagueSurvey.success(values)),
    deleteLeague: () => dispatch(deleteLeague.request({ league_id: ownProps.match.params.leagueId })),
    editLeagueStatus: values => dispatch(editLeagueStatus.request(values)),
    editLeagueVendor: values => dispatch(editLeagueVendor.request(values)),
    editLeaguePrices: values => dispatch(editLeaguePrices.request(values)),
    getLeagueInfo: () => dispatch(getLeagueInfo.request({ leagueId: ownProps.match.params.leagueId })),
    setLeaguePermissions: values => dispatch(setLeaguePermissions.request(values)),
    setLeagueFanGearCode: values => dispatch(setLeagueFanGearCode.request(values)),
    getLeaguePrograms: () => dispatch(getLeaguePrograms.request()),
    getLeagueItemTypes: () => dispatch(getLeagueItemTypes.request()),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithLeaguesComponent))
}

export default withLeagues
