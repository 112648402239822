// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { stopSubmit, stopAsyncValidation } from 'redux-form'
import { GET_ACCOUNT, getAccount } from 'src/redux/actions/accounts/accounts'
import http from 'src/services/http'
import config from 'src/config/formsConfig'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetAccount(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(GET_ACCOUNT.REQUEST)
    const formId = config.searchUserForm.id
    try {
      const { data } = yield call(http, `admin/user/${payload}`, {
        method: 'GET',
      })

      yield put(getAccount.success(data))
    } catch (e) {
      yield put(getAccount.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(stopAsyncValidation(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchGetAccount)]
