// @flow

import type { MessageCenterTypes } from 'src/redux/actions/messageCenter/messageCenterType'
import { GET_MESSAGE_CENTER, UPDATE_MESSAGE_CENTER } from 'src/redux/actions/messageCenter/messageCenter'

export type MessageCenterState = {
  isLoading: boolean,
  body: string,
  isActive: boolean,
  messageCenterError?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  body: '',
  isActive: false,
  messageCenterError: null,
}

const messageCenter = (state: MessageCenterState = initialState, action: MessageCenterTypes) => {
  switch (action.type) {
    case UPDATE_MESSAGE_CENTER.REQUEST:
    case GET_MESSAGE_CENTER.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        messageCenterError: null,
      }
    }
    case UPDATE_MESSAGE_CENTER.FAILURE:
    case GET_MESSAGE_CENTER.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        messageCenterError: action.payload,
      }
    }
    case GET_MESSAGE_CENTER.SUCCESS:
    case UPDATE_MESSAGE_CENTER.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        body: action.payload.body,
        isActive: action.payload.isActive,
      }
    }
    default: {
      return state
    }
  }
}

export default messageCenter
