// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { DELETE_PAYMENT_METHOD, deletePaymentMethod, getPaymentMethods } from 'src/redux/actions/payments/payments'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchDeletePaymentMethod(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { token, orderId, userId },
    } = yield take(DELETE_PAYMENT_METHOD.REQUEST)

    try {
      yield call(http, `admin/payment/payment-method/${token}`, {
        method: 'DELETE',
      })

      yield put(deletePaymentMethod.success())
      yield put(getPaymentMethods.request({ orderId, userId }))
    } catch (e) {
      yield put(deletePaymentMethod.error(e.message))
    }
  }
}

export default [fork(watchDeletePaymentMethod)]
