// @flow
import * as React from 'react'
import { Confirm, DashboardBlock } from 'src/common/components'

import type { ACHMethod } from 'src/redux/reducers/payments'

import { StyledPrimaryText } from '../../ACHMethodsStyles'

type OptionsPropsType = {
    checked: ?ACHMethod,
    editable: ?boolean,
    deletePaymentMethod: (data: {token: string}) => void,
    children?: React.Node
};

const Options = ({ checked, editable, deletePaymentMethod, children = null }: OptionsPropsType) => {
  return (
    <div className="heading">
      <DashboardBlock title="ACH Account">
        <div className="warning">
          <StyledPrimaryText>Declined or failed ACH payments are subject to a $5 fee</StyledPrimaryText>
        </div>
        <div className="top-container">
          {editable && (
            <div className="crud-container">
              <div className="crud">
                {checked && (
                  <>
                    {deletePaymentMethod && checked && (
                      <Confirm
                        title="Confirm"
                        description={'Are you sure you wish to delete this Payment Method?'}
                        confirmText="yes"
                        cancelText="no"
                      >
                        {confirm => (
                          <div className="text-center">
                            <span
                              className="edit-link"
                              onClick={confirm({
                                handleSubmit: deletePaymentMethod,
                                valid: true,
                              })}
                            >
                              Delete
                            </span>
                          </div>
                        )}
                      </Confirm>
                    )}

                  </>
                )}
              </div>
            </div>
          )}
        </div>
        {children}
      </DashboardBlock>
    </div>
  )
}

export default Options
