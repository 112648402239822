// @flow
import { COUNTRY_CODES } from 'src/common/constants'
import { normalizeZipCA } from 'src/helpers/formNormalizers'

export type ItemLocationType = any;

export default (item: ItemLocationType) => {
  if (!item) return null

  const address = []

  if (item.league_address_1 || item.league_address_2) {
    const street = []
    if (item.league_address_1) street.push(item.league_address_1)
    if (item.league_address_2) street.push(item.league_address_2)
    address.push(street.join(' '))
  }
  if (item.league_city) address.push(item.league_city)
  if (item.league_state) address.push(item.league_state)
  if (item.league_zip) address.push(item.league_country === COUNTRY_CODES.CA ? normalizeZipCA(item.league_zip) : item.league_zip)
  if (item.league_country) address.push(item.league_country)

  return address.join(', ')
}
