// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_ORDERS_EXPORT_FILTERS, getOrdersExportFilters } from 'src/redux/actions/orders/orders'
import { fetchOrdersExportFilters } from 'src/services/orders'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetOrdersExportFilters(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_ORDERS_EXPORT_FILTERS.REQUEST)

    try {
      const data = yield call(fetchOrdersExportFilters)

      yield put(getOrdersExportFilters.success(data))
    } catch (e) {
      yield put(getOrdersExportFilters.error(e.message))
    }
  }
}

export default [fork(watchGetOrdersExportFilters)]
