// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getLeagueSeasons,
  editSeasonFreeShippingCount,
  editSeason,
  deleteSeason,
} from 'src/redux/actions/seasons/seasons'

const withSeasons = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithSeasonsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ seasons }) => ({ seasons })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getLeagueSeasons: values => dispatch(getLeagueSeasons.request(values)),
    editSeasonFreeShippingCount: values => dispatch(editSeasonFreeShippingCount.request(values)),
    editSeason: values => dispatch(editSeason.request({ ...values, league_id: ownProps.match.params.leagueId, season_id: ownProps.match.params.seasonId })),
    deleteSeason: values => dispatch(deleteSeason.request({ ...values })),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithSeasonsComponent))
}

export default withSeasons
