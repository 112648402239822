// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_USER_LEAGUES, getUserLeagues } from 'src/redux/actions/leagues/leagues'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetMyLeagues(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_USER_LEAGUES.REQUEST)

    try {
      const { userId, limit, offset } = action.payload
      const { data } = yield call(http, `admin/user/${userId}/leagues/organizer`, {
        method: 'GET',
        params: {
          limit,
          offset,
        },
      })

      yield put(getUserLeagues.success(data))
    } catch (e) {
      yield put(getUserLeagues.error(e.message))
    }
  }
}

export default [fork(watchGetMyLeagues)]
