import React, { Component } from 'react'
import { Field } from 'redux-form'
import { ButtonPrimary, FormSelectField, FormTextField } from 'src/common/components'

// styles
import { StyledComponent, StyledWrapper } from './SeasonFreeShippingCountStyles'
import { required } from 'src/helpers/formValidation'
import { onlyPositiveInteger } from 'src/helpers/formNormalizers'

const btnSize = 'medium'

export default class SeasonFreeShippingCountWrapper extends Component {
  render() {
    const { btnActionText, btnActionCallback, btnDisable, seasons = [] } = this.props
    return (
      <StyledWrapper>
        <StyledComponent>
          <div className="row">
            {seasons.length > 1 &&
              <>
                <span className="label-without-width">Season:</span>
                <div className="value">
                  <Field
                    name="seasonId"
                    id="seasonId"
                    placeholder="Select Season"
                    component={FormSelectField}
                    validate={[required]}
                  >
                    {seasons.map((season, index) => (
                      <option
                        key={season.id}
                        value={season.id}
                      >
                        {season.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </>
            }
            <span className="label-without-width">Free shipping count:</span>
            <div className="value">
              <Field
                name="freeCount"
                id="freeCount"
                placeholder="0"
                component={FormTextField}
                variant="outlined"
                normalize={onlyPositiveInteger}
              />
            </div>
            <div className="btn-wrapper">
              {btnActionCallback && (
                <ButtonPrimary
                  disabled={btnDisable}
                  size={btnSize}
                  action={btnActionCallback}
                  buttonText={btnActionText}
                />
              )}
            </div>
          </div>
        </StyledComponent>
      </StyledWrapper>
    )
  }
}
