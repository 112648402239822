// @flow
import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

const handleBorderColor = (status: string) => {
  switch (status) {
    case 'pending':
      return '#ffb74d'
    case 'inactive':
    case 'declined':
      return '#f44336'
    case 'approved':
      return '#4caf50'
    default:
      return '#4caf50'
  }
}

export const StyledCard = styled.div`
    .card-layout {
        border: 1px solid ${props => props.theme.main.colors.borderColor};
        border-left: 5px solid ${({ status }) => handleBorderColor(status)};
        border-radius: 3px;
        color: inherit;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        cursor: pointer;
        
        :hover {
            background-color: rgba(31,46,81,0.03);
            text-decoration: none;
        }
    }
    
    .card-heading {
        display: flex;
    }
    
    .status {
        margin-left: 20px;
        margin-top: 2px;
        color: ${({ status }) => handleBorderColor(status)};
        min-width: 70px;
    }
    .card-left,
    .card-center,
    .card-right {
        padding: 10px 15px;
    }

    .card-left {
        flex: 3;
        display: flex;
    }

    .card-center {
        flex: 1.5;
    }

    .card-right {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        min-width: 200px;
        flex: 1;
    }
    
    .contact {
        align-items: center;
        display: flex;
        line-height: 1.2;
    }
    
    .phone {
        a {
            :hover {
                text-decoration: none;
            }
        }
    }
    
    .dates {
        display: flex;
    }
    
    .date {
        align-items: center;
        display: flex;
        padding-right: 8px; 
        margin-bottom: 10px;
        
        .value {
            padding-left: 4px;
        }
        
        .label {
            font-weight: 300;
            opacity: 0.8;
        }
    }
    
    .card-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    
    .distance {
        margin-top: 10px;
        display: block;
    }
    
    .card-status {
        display: flex;
        align-items: center;
        align-content: center;
        font-size: 14px;
        font-family: ${props => props.theme.main.fonts};
        text-transform: none;
        color: #d43540;
        margin-top: 5px;
        
        svg {
          margin-right: 5px;
          path {
            fill: red;
            }
          }
       }
    
    .card-subtitle {
        display: block;
        margin-bottom: 10px;
    }
    
    .list-contacts {
        flex-wrap: wrap;
        margin-left: -6px;
        
        .list-item {
            border-right: 1px solid ${props => props.theme.main.colors.borderColor};
            padding-left: 8px;
            padding-right: 20px;
            
            :first-child {
                padding-left: 0;
            }
            
            :last-child {
                border-right: 0;
            }
        }
    }
    
    .contact {
        margin-bottom: 6px;
        line-height: 1.1;
        
        .icon-wrapper {
            min-width: 20px;
            margin-right: 4px;
        }
    }
    
    .list-divisions {
        margin-top: 8px;
    }
    
    .value {
        padding-left: 4px;
    }
    
    .label {
        font-weight: 300;
        opacity: 0.8;
    }
    
    .row {
        margin-bottom: 8px;
        margin-top: 0;
    }
  
    .link-item {
        cursor: pointer;
      
        :hover {
          text-decoration: underline;
        }
    }

    .logo {
        min-width: 150px;
        margin: auto auto;
        flex: 1;
        text-align: right;
        img {
            width: 133px;
        }
    }

    .card-contacts {
        flex: 2;
    }
    
    @media ${device.tablet} {
        .card-layout {
            flex-direction: column;
        }
        .card-heading {
            flex-direction: column;
            margin-bottom: 15px;
        }

        .dates {
            flex-wrap: wrap;
        }
        
        .card-title {
            margin-bottom: 0;
        }
        .card-right {
            justify-content: center;
            padding-bottom: 20px;
            margin: 0 auto;
            max-width: 240px;
            width: 100%;
        }
        
        .status {
            margin-left: 0;
        }

        .content-wrapper {
            flex-direction: column;
        }
    }
`
