// @flow
import type { UserActionTypes } from 'src/redux/actions/user/userTypes'
import {
  SET_USER,
  RESET_USER,
  EDIT_USER,
  EDIT_USER_PASSWORD,
  SET_USER_PASSWORD,
  EDIT_USER_GEOLOCATION_ZIP,
  LOGOUT_USER,
} from 'src/redux/actions/user/user'

export type UserData = {
    id: number,
    first_name: string,
    last_name: string,
    zip: string,
    country: string,
    state: string,
    email: string,
    geoLocationZip: ?string,
    roles: string[]
};

export type UserState = {
    isLoading: boolean,
    userData?: ?UserData,
    userErrors?: ?{ [key: string]: any } | ?string,
    emailUnconfirmed?: string,
    loginErrors: ?{ [key: string]: any } | ?string,
    passwordErrors: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  userData: null,
  userErrors: null,
  emailUnconfirmed: undefined,
  loginErrors: null,
  passwordErrors: null,
}

const user = (state: UserState = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case LOGOUT_USER.REQUEST:
    case SET_USER.REQUEST:
    case EDIT_USER.REQUEST:
    case EDIT_USER_PASSWORD.REQUEST:
    case SET_USER_PASSWORD.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        userErrors: null,
        loginErrors: null,
      }
    }

    case LOGOUT_USER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        loginErrors: null,
      }
    }

    case SET_USER.SUCCESS:
    case EDIT_USER.SUCCESS:
    case SET_USER_PASSWORD.SUCCESS:
    case EDIT_USER_PASSWORD.SUCCESS:
    case EDIT_USER_GEOLOCATION_ZIP:
    {
      return {
        ...state,
        isLoading: false,
        userData: { ...action.payload },
        loginErrors: null,
        userErrors: null,
        passwordErrors: null,
      }
    }

    case LOGOUT_USER.FAILURE:
    case SET_USER.FAILURE:
    case EDIT_USER_PASSWORD.FAILURE:
    case SET_USER_PASSWORD.FAILURE:
    {
      return { ...state, isLoading: false, loginErrors: action.payload }
    }

    case EDIT_USER.FAILURE:
    {
      return { ...state, isLoading: false, userErrors: action.payload }
    }

    case RESET_USER: {
      return { ...initialState }
    }

    default: {
      return state
    }
  }
}

export default user
