// @flow
import React from 'react'
import MomentUtils from '@date-io/moment'
import ClearIcon from '@material-ui/icons/Clear'
import moment from 'moment'

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import type { DatePickerPropsType } from './MuiDatePickerTypes'

import { ClearButton } from './MuiDatePickerStyles'

const MuiDatePicker = ({
  input,
  id,
  label,
  variant,
  size,
  meta: { touched, error },
  format = 'MM/DD/YYYY',
  // $FlowFixMe
  ...custom
}: DatePickerPropsType) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        <DatePicker
          inputVariant={variant}
          format={format}
          size={size}
          id={id}
          label={label}
          autoOk
          {...custom}
          value={input.value || null}
          onChange={val => input.onChange(val ? moment(val).format(format) : null)}
          variant="inline"
        />
        {
          input.value && (
            <ClearButton onClick={e => {
              input.onChange(null)
            }}>
              <ClearIcon fontSize={'small'}/>
            </ClearButton>
          )
        }
      </>
    </MuiPickersUtilsProvider>
  )
}

export const MuiDatePickerSimple = ({
  id,
  label,
  variant,
  size,
  value,
  onChange,
  format = 'MM/DD/YYYY',
  // $FlowFixMe
  ...custom
}: DatePickerPropsType) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        <DatePicker
          inputVariant={variant}
          format={format}
          size={size}
          id={id}
          label={label}
          autoOk
          {...custom}
          value={value || null}
          onChange={onChange}
          variant="inline"
        />
      </>
    </MuiPickersUtilsProvider>
  )
}

export default MuiDatePicker
