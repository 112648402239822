// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { destroy } from 'redux-form'
import config from 'src/config/formsConfig'

import {
  getOrder,
  resetBulkOrder,
  cancelPendingOrder,
  submitOrderToVendor,
  manualOrderSubmit,
  downloadOrderPDF,
  downloadOrderInvoicePDF,
} from 'src/redux/actions/bulkOrder/bulkOrder'
import { downloadMatrixTeamOrder } from 'src/redux/actions/teamOrder'

const withBulkOrder = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithBulkOrderComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ bulkOrder }) => ({ bulkOrder })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getOrder: () => dispatch(getOrder.request({ order_id: +ownProps.match.params.orderId, leagueId: +ownProps.match.params.leagueId })),
    resetBulkOrder: values => {
      dispatch(resetBulkOrder.success())
      dispatch(destroy(config.mainOrderForm.id))
    },
    manualOrderSubmit: values => dispatch(manualOrderSubmit.request(values)),
    cancelPendingOrder: ({ orderId }) => dispatch(cancelPendingOrder.request({ orderId })),
    submitOrderToVendor: ({ order_id, sendInvoiceEmail }) => dispatch(submitOrderToVendor.request({ order_id, sendInvoiceEmail })),
    submitOrderToVendorErrorReset: order_id => dispatch(submitOrderToVendor.errorReset()),
    downloadOrderPDF: orderId => dispatch(downloadOrderPDF.request(orderId)),
    downloadMatrixTeamOrder: orderId => dispatch(downloadMatrixTeamOrder.request(orderId)),
    downloadOrderInvoicePDF: id => dispatch(downloadOrderInvoicePDF.request(id)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithBulkOrderComponent))
}

export default withBulkOrder

