// @flow

import React from 'react'
import { Field } from 'redux-form'
import { FormFileInput } from 'src/common/components'

// styles
import { StyledForm, StyledBlock } from './importSizesFormStyles'

// types
import type { ImportSizeFormPropsType } from './ImportSizesFormTypes'

const ImportSizesForm = ({ makeOrder: { order }, sendMakeOrderFile, handleSubmit }: ImportSizeFormPropsType) => {
  return (
    <StyledBlock>
      <StyledForm>
        <div className="form-body">
          <div className="form-column">
            <h4 className="title">Choose the NFL Teams you want to add to the order <br /> Or click Proceed to order flag belts and footballs only</h4>
          </div>
          <div className="form-column input-file-wrapper link-wrapper">
            <Field
              name="file"
              id="file"
              component={FormFileInput}
              handleSubmit={handleSubmit}
              action={({ file }) => sendMakeOrderFile({ file, order })}
              params={{ leagueId: order.league_id }}
              size="meidum"
              buttonText="Import Sizes from XLS"
            />
          </div>
        </div>
      </StyledForm>
    </StyledBlock>
  )
}

export default ImportSizesForm
