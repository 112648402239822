// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_LEAGUES, getLeagues } from 'src/redux/actions/leagues/leagues'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'
import config from '../../config/formsConfig'
import { stopAsyncValidation, stopSubmit } from 'redux-form'

function * watchGetMyLeagues(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(GET_LEAGUES.REQUEST)

    const formId = config.searchLeaguesForm.id
    const params = {
      page: values.page,
      limit: values.limit,
      search: values.search,
      status: values.status,
    }
    try {
      const { data } = yield call(http, 'admin/leagues', {
        method: 'GET',
        params,
      })

      yield put(getLeagues.success(data))
    } catch (e) {
      yield put(getLeagues.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(stopAsyncValidation(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchGetMyLeagues)]
