import styled from 'styled-components'

export const DataDifferenceTimelineItemStyle = styled.div`
  display: block;

  .MuiSvgIcon-root {
    vertical-align: middle;
    font-size: 22px;
  }

  .timeline-time {
    color: #bdbdbd;
    margin: 0 0 5px;
    font-size: 12px;
  }

  .prop-diff {
    font-size: 14px;
  }

  .diff-arrow-icon {
    font-size: 16px;
    vertical-align: text-top;
  }

  .prop-diff-row {
    display: flex;
    align-items: center;
  }
`
