// @flow
// $FlowFixMe
import dropin from 'braintree-web-drop-in'

class Payment {
  instance: any = null
  createInstance = async(paymentToken: ?string, button: ?HTMLElement) => {
    const instance = paymentToken && await dropin.create({
      authorization: paymentToken,
      container: button,
    })
    this.instance = instance
    return instance
  }

  refreshInstance = async(paymentToken: ?string, button: ?HTMLElement) => {
    this.instance && await this.instance.teardown()
    await this.createInstance(paymentToken, button)
    return this.instance
  }

  getInstance = () => this.instance

  onPaymentSubmit = async() => {
    const payload = this.instance && await this.instance.requestPaymentMethod()
    return payload
  }
}

export default Payment
