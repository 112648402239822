// @flow

import { fork, put, take } from 'redux-saga/effects'
import { connectTo, CONNECT_TO_ACCOUNT } from 'src/redux/actions/user/user'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchConnectTo(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { accountId } } = yield take(CONNECT_TO_ACCOUNT.REQUEST)
    const { data: { redirectTo } } = yield http('admin/connect-to-user', {
      method: 'POST',
      body: JSON.stringify({ accountId }),
    })
    try {
      yield put(connectTo.success())
      window.open(redirectTo, '_blank').focus()
    } catch (e) {
      yield put(connectTo.error(e.message))
    }
  }
}

export default [fork(watchConnectTo)]
