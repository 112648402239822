// @flow
import {
  GET_LEAGUES,
  GET_USER_LEAGUES,
  GET_LEAGUES_STATUSES,
  GET_LEAGUES_ORGANIZER,
  GET_LEAGUE,
  GET_LEAGUES_ORGANIZATIONS,
  EDIT_LEAGUE,
  DELETE_LEAGUE,
  EDIT_LEAGUE_STATUS,
  SET_LEAGUE_PERMISSIONS,
  EDIT_LEAGUE_VENDOR,
  GET_LEAGUE_SURVEY,
  GET_LEAGUE_INFO,
  GET_USER_ALL_LEAGUES,
  EDIT_LEAGUE_PRICES,
  EDIT_LEAGUE_SURVEY,
  SET_LEAGUE_FAN_GEAR_CODE,
  GET_LEAGUE_PROGRAMS,
  GET_LEAGUE_ITEM_TYPES,
} from 'src/redux/actions/leagues/leagues'
import type { LeaguesActionTypes } from '../actions/leagues/leaguesTypes'
import type { LeagueActiveSeasonType } from './publicLeagues'
import type { failedLeagueVendorUpdateErrorType } from 'src/common/components/failedLeagueVendorUpdateBlock/failedLeagueVendorUpdateBlockTypes'
import type { VendorType } from 'src/common/constants.types'

export type LeaguesItem = {
  league_id: number,
  id: number,
  name: string,
  roles: string[],
  has_boys: boolean,
  has_girls: boolean,
  has_coed: boolean,
  status: string,
  location: string,
  owner: string,
  updated_at: ?string,
  has_insurance: string,
  latitude: string,
  longitude: string,
  max_age: number,
  min_age: number,
  min_grade: number,
  max_grade: number,
  website: ?string,
  phone: ?string,
  distance: string,
  league_zip: string,
  league_city: string,
  league_state: string,
  league_address_1: string,
  league_county: string,
  league_country: string,
  survey_answers: {
    id: string,
    value: string | string[]
  },
  billing_address_1: string,
  billing_city: string,
  billing_state: string,
  billing_zip: string,
  billing_county: string,
  billing_country: string,
  shipping_address_1: string,
  shipping_city: string,
  shipping_state: string,
  shipping_zip: string,
  shipping_county: string,
  shipping_country: string,
  practice_address_id: number,
  org_type_id: number,
  approved: string,
  created_at: string,
  status_change_date: string,
  active: ?boolean,
  allow_finder: boolean,
  fan_gear_code: string | null,
  active_seasons: LeagueActiveSeasonType[],
  default_vendor: VendorType,
  twitter?: string,
  facebook?: string,
  instagram?: string,
  email?: string,
  program_id: string | number
};

export type LeaguesData = {
  total: number,
  offset: number,
  limit: number,
  page: number,
  results: LeaguesItem[]
};

export type LeaguesStatuses = string[];

export type OwnerData = {
  account_id: number,
  first_name: string,
  last_name: string,
  email: string,
  phone: string
};

export type SurveyDataItem = {
  question: string,
  answer: string
};

export type SurveyData = SurveyDataItem[];

export type LeagueOrganizerData = {
  owner: OwnerData,
  leaguesData: ?LeaguesData
};

export type LeagueSeason = {
  id: number,
  league_id: number,
  name: string,
  start_date: string,
  end_date: string,
  created_at: string,
  updated_at: string,
  deleted_at: string,
  min_age: number,
  max_age: number,
  max_grade: number,
  min_grade: number,
  age_grade_range: boolean,
  has_boys: boolean,
  has_coed: boolean,
  has_girls: boolean
};

export type LeagueSeasons = LeagueSeason[];

export type OrganizationsDataItem = {
  id: number,
  name: string,
  type: string
};

export type OrganizationsData = OrganizationsDataItem[];

export type LeagueProgram = {
  id: number,
  name: string
};

export type ItemType = {
  default_price: number,
  id: number,
  key: string,
  name: string,
  premium: boolean
};

export type LeaguesState = {
  isLoading: boolean,
  isLoadingLeague: boolean,
  isLoadingStatuses: boolean,
  isLoadingOrganizer: boolean,
  isLoadingPrograms: boolean,
  isLoadingItemTypes: boolean,
  leaguesData: ?LeaguesData,
  leagueData: LeaguesItem,
  leaguesStatuses?: LeaguesStatuses,
  leaguesOrganizer: ?LeagueOrganizerData,
  leagueSeasons: ?LeagueSeasons,
  organizationsData: OrganizationsData,
  leaguesErrors?: ?{ [key: string]: any } | ?string,
  surveyData: ?SurveyData,
  leagueVendorUpdateError: ?failedLeagueVendorUpdateErrorType,
  programs: LeagueProgram[],
  itemTypes: ItemType[]
};

const initialState = {
  isLoading: false,
  isLoadingStatuses: false,
  isLoadingLeague: false,
  isLoadingOrganizer: false,
  isLoadingPrograms: false,
  isLoadingItemTypes: false,
  leaguesData: null,
  leagueData: {},
  leaguesStatuses: [],
  leaguesOrganizer: null,
  leagueSeasons: [],
  limit: null,
  offset: null,
  organizationsData: [],
  leaguesErrors: null,
  surveyData: [],
  leagueVendorUpdateError: null,
  programs: [],
  itemTypes: [],
}

const leagues = (state: LeaguesState = initialState, action: LeaguesActionTypes): LeaguesState => {
  switch (action.type) {
    case GET_LEAGUES.REQUEST:
    case GET_USER_LEAGUES.REQUEST:
    case GET_USER_ALL_LEAGUES.REQUEST:
    case GET_LEAGUES_ORGANIZATIONS.REQUEST:
    case GET_LEAGUE_SURVEY.REQUEST:
    case GET_LEAGUE_INFO.REQUEST: {
      return {
        ...state,
        isLoading: true,
        leaguesErrors: null,
      }
    }

    case GET_LEAGUES_STATUSES.REQUEST: {
      return {
        ...state,
        isLoadingStatuses: true,
      }
    }

    case GET_LEAGUES_ORGANIZER.REQUEST: {
      return {
        ...state,
        isLoadingOrganizer: true,
      }
    }

    case GET_LEAGUE_PROGRAMS.REQUEST: {
      return {
        ...state,
        isLoadingPrograms: true,
      }
    }

    case GET_LEAGUE_PROGRAMS.SUCCESS: {
      return {
        ...state,
        programs: action.payload,
        isLoadingPrograms: false,
      }
    }

    case GET_LEAGUE_PROGRAMS.FAILURE: {
      return {
        ...state,
        isLoadingPrograms: false,
      }
    }

    case GET_LEAGUE_ITEM_TYPES.REQUEST: {
      return {
        ...state,
        isLoadingItemTypes: true,
      }
    }

    case GET_LEAGUE_ITEM_TYPES.SUCCESS: {
      return {
        ...state,
        itemTypes: action.payload,
        isLoadingItemTypes: false,
      }
    }

    case GET_LEAGUE_ITEM_TYPES.FAILURE: {
      return {
        ...state,
        isLoadingItemTypes: false,
      }
    }

    case GET_LEAGUE.REQUEST:
    case EDIT_LEAGUE.REQUEST:
    case DELETE_LEAGUE.REQUEST:
    case EDIT_LEAGUE_STATUS.REQUEST:
    case SET_LEAGUE_PERMISSIONS.REQUEST:
    case EDIT_LEAGUE_VENDOR.REQUEST:
    case EDIT_LEAGUE_PRICES.REQUEST:
    case SET_LEAGUE_FAN_GEAR_CODE.REQUEST: {
      return {
        ...state,
        isLoadingLeague: true,
      }
    }

    case GET_LEAGUES.SUCCESS:
    case GET_USER_LEAGUES.SUCCESS:
    case GET_USER_ALL_LEAGUES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        leaguesData: { ...action.payload },
        leaguesErrors: null,
      }
    }
    case SET_LEAGUE_FAN_GEAR_CODE.SUCCESS: {
      return {
        ...state,
        leagueData: {
          ...state.leagueData,
          fan_gear_code: action.payload,
        },
        isLoadingLeague: false,
        leaguesErrors: null,
      }
    }
    case DELETE_LEAGUE.SUCCESS: {
      return {
        ...state,
        isLoadingLeague: false,
        leaguesErrors: null,
      }
    }
    case GET_LEAGUE_SURVEY.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        surveyData: action.payload,
        leaguesErrors: null,
      }
    }

    case GET_LEAGUES_STATUSES.SUCCESS: {
      return {
        ...state,
        isLoadingStatuses: false,
        leaguesStatuses: [...action.payload],
      }
    }

    case GET_LEAGUE_INFO.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        leagueSeasons: [...action.payload],
      }
    }

    case GET_LEAGUES_ORGANIZER.SUCCESS: {
      return {
        ...state,
        isLoadingOrganizer: false,
        leaguesOrganizer: { ...action.payload },
      }
    }

    case EDIT_LEAGUE_SURVEY.SUCCESS:
      return {
        ...state,
        leagueData: {
          ...state.leagueData,
          survey_answers: {
            ...state.leagueData.survey_answers,
            [action.payload.id]: action.payload.value,
          },
        },
      }

    case GET_LEAGUE.SUCCESS:
    case EDIT_LEAGUE.SUCCESS: {
      return {
        ...state,
        isLoadingLeague: false,
        leagueData: { ...action.payload },
        leagueVendorUpdateError: null,
      }
    }
    case EDIT_LEAGUE_STATUS.SUCCESS:
    case EDIT_LEAGUE_VENDOR.SUCCESS:
    case EDIT_LEAGUE_PRICES.SUCCESS:
    case SET_LEAGUE_PERMISSIONS.SUCCESS: {
      return {
        ...state,
        isLoadingLeague: false,
        leagueVendorUpdateError: null,
      }
    }

    case GET_LEAGUES_ORGANIZATIONS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        organizationsData: [...action.payload],

      }
    }

    case GET_LEAGUES.FAILURE:
    case GET_USER_LEAGUES.FAILURE:
    case GET_USER_ALL_LEAGUES.FAILURE:
    case GET_LEAGUE_SURVEY.FAILURE:
    case GET_LEAGUES_ORGANIZATIONS.FAILURE:
    case GET_LEAGUE_INFO.FAILURE: {
      return {
        ...state,
        isLoading: false,
        leaguesErrors: action.payload,
      }
    }

    case GET_LEAGUES_STATUSES.FAILURE: {
      return {
        ...state,
        isLoadingStatuses: false,
        leaguesErrors: action.payload,
      }
    }

    case GET_LEAGUES_ORGANIZER.FAILURE: {
      return {
        ...state,
        isLoadingOrganizer: false,
        leaguesErrors: action.payload,
      }
    }
    case EDIT_LEAGUE_VENDOR.FAILURE: {
      return {
        ...state,
        isLoadingLeague: false,
        leagueVendorUpdateError: action.payload,
      }
    }

    case GET_LEAGUE.FAILURE:
    case EDIT_LEAGUE.FAILURE:
    case DELETE_LEAGUE.FAILURE:
    case EDIT_LEAGUE_STATUS.FAILURE:
    case SET_LEAGUE_PERMISSIONS.FAILURE:
    case EDIT_LEAGUE_PRICES.FAILURE:
    case SET_LEAGUE_FAN_GEAR_CODE.FAILURE: {
      return {
        ...state,
        isLoadingLeague: false,
        leaguesErrors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default leagues
