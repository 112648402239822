// @flow
import React, { useEffect } from 'react'
import { ListItem, Divider, List } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import GroupIcon from '@material-ui/icons/Group'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import AttachmentIcon from '@material-ui/icons/Attachment'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import SettingsIcon from '@material-ui/icons/Settings'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import SearchIcon from '@material-ui/icons/Search'
import TuneIcon from '@material-ui/icons/Tune'
import PaymentsIcon from '@material-ui/icons/Payment'
import Assessment from '@material-ui/icons/Assessment'
import { SidebarLink, RenderIf } from 'src/common/components'
import { FEATURES_LIST } from 'src/config/featureManagement'
import { withFeatureManagement } from 'src/decorators'

// styles
import { StyledNav } from './sidebarNavStyles'

const SidebarNav = ({ getFeature, featureManagement }) => {
  useEffect(() => {
    getFeature(FEATURES_LIST.AVALARA_TAXES)
  }, [])

  const taxExemptionCondition = featureManagement?.features?.[FEATURES_LIST.AVALARA_TAXES]?.status
  return (
    <StyledNav>
      <List className="nav-list">
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/" icon={<DashboardIcon/>} title="Dashboard" />
          </ListItem>
        </div>
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/users" icon={<GroupIcon/>} title="Users" />
          </ListItem>
        </div>
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/leagues-list" icon={<HowToRegIcon/>} title="Leagues" />
          </ListItem>
        </div>
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/insurances" icon={<AttachmentIcon/>} title="Insurances" />
          </ListItem>
        </div>
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/orders-list" icon={<ShoppingBasketIcon/>} title="Orders" />
          </ListItem>
        </div>
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/payments" icon={<PaymentsIcon/>} title="Payments" />
          </ListItem>
          <Divider/>
        </div>
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/league-finder" icon={<SearchIcon/>} title="League Finder" />
          </ListItem>
        </div>
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/dashboard-management" icon={<TuneIcon/>} title="LO Dashboard Management" />
          </ListItem>
        </div>
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/global-settings" icon={<SettingsIcon/>} title="Settings / Admin Actions" />
          </ListItem>
        </div>
        <div className="css-hide">
          <div className="list-item">
            <Divider/>
            <ListItem>
              <SidebarLink path="#" icon={<CreditCardIcon/>} title="Payments" />
            </ListItem>
          </div>
          <div className="list-item">
            <Divider/>
            <ListItem>
              <SidebarLink path="#" icon={<MailOutlineIcon/>} title="Emails" />
            </ListItem>
          </div>
          <div className="list-item">
            <Divider/>
            <ListItem>
              <SidebarLink path="#" icon={<SettingsIcon/>} title="Settings" />
            </ListItem>
          </div>
        </div>
        <div className="list-item">
          <Divider/>
          <ListItem>
            <SidebarLink path="/edit-profile" icon={<AssignmentIndIcon/>} title="Edit Profile" />
          </ListItem>
          <Divider/>
        </div>
        <RenderIf condition={taxExemptionCondition}>
          <div className="list-item">
            <Divider/>
            <ListItem>
              <SidebarLink path="/exemption-certificates" icon={<Assessment/>} title="Exemption Certificates" />
            </ListItem>
            <Divider/>
          </div>
        </RenderIf>
        {/* <div className="list-item">
                    <Divider/>
                    <ListItem>
                        <SidebarLink path="/find-league-iframe" icon={<SearchIcon/>} title="Find League Iframe" />
                    </ListItem>
                </div> */}
      </List>
    </StyledNav>
  )
}

export default withFeatureManagement(SidebarNav)
