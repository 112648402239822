// @flow
import { GET_LATEST_AGREEMENT_VERSION, RELEASE_NEW_AGREEMENT } from 'src/redux/actions/globalSettings/globalSettings'
import type { BasicRedux } from 'src/redux/actions/globalSettings/globalSettingsTypes.js'

export type LatestAgreementDataType = {
  id: number,
  created_at: string
};

type LatestAgreementType = {
  isLoading: boolean,
  data: ?LatestAgreementDataType
};

export type GlobalSettingsState = {
  latestAgreement: LatestAgreementType,
  releaseNewAgreementLoading: boolean
};

const initialState = {
  latestAgreement: {
    isLoading: false,
    data: null,
  },
  releaseNewAgreementLoading: false,
}

const insurances = (
  state: GlobalSettingsState = initialState,
  action: BasicRedux,
): GlobalSettingsState => {
  switch (action.type) {
    case GET_LATEST_AGREEMENT_VERSION.REQUEST: {
      return {
        ...state,
        latestAgreement: {
          isLoading: true,
          data: null,
        },
      }
    }

    case GET_LATEST_AGREEMENT_VERSION.SUCCESS: {
      return {
        ...state,
        latestAgreement: {
          isLoading: false,
          data: { ...action.payload },
        },
      }
    }

    case GET_LATEST_AGREEMENT_VERSION.FAILURE: {
      return {
        ...state,
        latestAgreement: {
          isLoading: false,
          data: null,
        },
      }
    }

    case RELEASE_NEW_AGREEMENT.REQUEST: {
      return {
        ...state,
        releaseNewAgreementLoading: true,
      }
    }

    case RELEASE_NEW_AGREEMENT.SUCCESS: {
      return {
        ...state,
        releaseNewAgreementLoading: false,
      }
    }

    case RELEASE_NEW_AGREEMENT.FAILURE: {
      return {
        ...state,
        releaseNewAgreementLoading: false,
      }
    }

    default: {
      return state
    }
  }
}

export default insurances
