import styled from 'styled-components'

export const StyledWrapper = styled.div`

  .MuiTimelineSeparator-root {
    min-width: 11px;
  }

  .MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
  }

  .data-diff-filter {
    padding: 0 0 0 45px;
  }
`
