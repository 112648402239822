// @flow

import moment from 'moment'
moment.suppressDeprecationWarnings = true

export const formatAbsoluteDate = (date: string) => {
  if (!date) return
  return moment(date).utc().format('MMM DD, YYYY')
}

export const formatAbsoluteShortDate = (date: string) => {
  if (!date) return
  return moment(date).utc().format('MMM DD')
}

export const formatAbsoluteDateForDatePicker = (date: ?string) => {
  if (!date) return
  return moment(date).utc().format('MM/DD/YYYY')
}

export const isActiveDate = (start: string, end: string): boolean => moment().isBetween(start, end)

export const formatFullDate = (date: string) => {
  if (!date) return
  return moment(date).format('MM/DD/YYYY h:mm:ss A')
}

export const isDayInPast = (date: string) => {
  return moment.utc(date).endOf('day').format() < moment().utc().format()
}
