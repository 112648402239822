// @flow

import { stopSubmit, change } from 'redux-form'
import { call, fork, put, take, select } from 'redux-saga/effects'
import { cloneDeep, each } from 'lodash'
import config from 'src/config/formsConfig'
import {
  EDIT_LEAGUE,
  editLeague,
  EDIT_LEAGUE_STATUS,
  editLeagueStatus,
  EDIT_LEAGUE_VENDOR,
  editLeagueVendor,
  EDIT_LEAGUE_PRICES,
  editLeaguePrices,
  SET_LEAGUE_PERMISSIONS,
  setLeaguePermissions,
  SET_LEAGUE_FAN_GEAR_CODE,
  setLeagueFanGearCode,
} from 'src/redux/actions/leagues/leagues'

import { history } from 'src/services/history'
import { trimObjectStringValues } from 'src/helpers/formatHelpers'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

const shapeToNumber = (val: string) => {
  return val ? +val : null
}

const toNumberValues = ['org_type_id', 'min', 'max', 'league_id', 'shipping_address_id', 'billing_address_id']

function * watchEditLeague(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(EDIT_LEAGUE.REQUEST)
    const formId = config.editLeagueForm.id

    const formValues = cloneDeep(values)

    trimObjectStringValues(formValues)

    toNumberValues.forEach(item => {
      formValues[item] = shapeToNumber(formValues[item])
    })
    const { league_id } = formValues

    const { withRedirect = true } = values

    try {
      const { data } = yield call(http, `admin/league/${league_id}`, {
        body: JSON.stringify({
          ...formValues,
        }),
        method: 'PUT',
      })

      yield put(editLeague.success(data))
      if (withRedirect) {
        yield call(history.goBack)
      }
    } catch (e) {
      yield put(editLeague.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

function * watchEditLeagueStatus(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...formValues },
    } = yield take(EDIT_LEAGUE_STATUS.REQUEST)
    const formId = config.approveLeagueForm.id

    const { league_id, approved } = formValues

    try {
      yield call(http, `admin/league/${league_id}/status`, {
        body: JSON.stringify({
          approved,
        }),
        method: 'PUT',
      })

      yield put(editLeagueStatus.success({ approved }))
    } catch (e) {
      yield put(editLeagueStatus.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

function * watchSetLeaguePermissions(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { league_id, isAllowedFinder },
    } = yield take(SET_LEAGUE_PERMISSIONS.REQUEST)

    try {
      yield call(http, `admin/league/${league_id}/permissions`, {
        body: JSON.stringify({
          isAllowedFinder,
        }),
        method: 'PUT',
      })

      yield put(setLeaguePermissions.success({ isAllowedFinder }))
    } catch (e) {
      yield put(setLeaguePermissions.error(e.message))
    }
  }
}

function * watchEditLeagueVendor(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(EDIT_LEAGUE_VENDOR.REQUEST)
    const {
      league_id,
      default_vendor,
      force = false,
    } = payload
    const formId = config.editLeagueOrderSettingsForm.id

    try {
      yield call(http, `admin/league/${league_id}/vendor`, {
        body: JSON.stringify({
          default_vendor,
          force,
        }),
        method: 'PUT',
      })

      yield put(change(formId, 'default_vendor', default_vendor))
      yield put(editLeagueVendor.success({ default_vendor }))
    } catch (e) {
      const { leagues: { leagueData: { default_vendor } } } = yield select()
      if (!(e.errors && e.errors.type === 'UNPAID_ORDERS')) {
        yield put(change(formId, 'default_vendor', default_vendor))
      }
      yield put(editLeagueVendor.error(e.errors))
    }
  }
}

function * watchEditLeaguePrices(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(EDIT_LEAGUE_PRICES.REQUEST)
    const { league_id, prices } = payload

    each(prices, (val, key) => {
      prices[key] = +val
    })

    try {
      yield call(http, `admin/league/${league_id}/prices`, {
        body: JSON.stringify(prices),
        method: 'PUT',
      })

      yield put(editLeaguePrices.success({ prices }))
    } catch (e) {
      yield put(editLeaguePrices.error(e.message))
    }
  }
}

function * watchSetLeagueFanGearCode(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { leagueId, fanGearCode },
    } = yield take(SET_LEAGUE_FAN_GEAR_CODE.REQUEST)

    try {
      yield call(http, `admin/league/${leagueId}/fan-gear-code`, {
        body: JSON.stringify({
          fanGearCode,
        }),
        method: 'PUT',
      })

      yield put(setLeagueFanGearCode.success(fanGearCode))
    } catch (e) {
      yield put(setLeagueFanGearCode.error(e.message))
    }
  }
}

export default [
  fork(watchEditLeague),
  fork(watchEditLeagueStatus),
  fork(watchSetLeaguePermissions),
  fork(watchEditLeagueVendor),
  fork(watchEditLeaguePrices),
  fork(watchSetLeagueFanGearCode),
]
