// @flow
import { store } from 'src/redux/store'
import { logout, connectTo } from 'src/redux/actions/user/user'
const { localStorage } = window

export const setToken = (token: string) => localStorage.setItem('access_token', token)

export const getToken = () => localStorage.getItem('access_token')

export const resetToken = () => localStorage.removeItem('access_token')

export const resetUser = () => localStorage.clear()

export const signOutUser = async(resetUserState: (() => void)) => {
  resetToken()
  resetUser()
  if (typeof resetUserState === 'function') {
    resetUserState()
  }
}

export const logoutUser = async(resetUserState: any) => {
  await signOutUser(resetUserState)
  store.dispatch(logout.request())
}

export const connectToUser = async(accountId: number) => {
  store.dispatch(connectTo.request({ accountId }))
}
