// @flow
import styled from 'styled-components'

const handleButtonSize = (size: string) => {
  switch (size) {
    case 'small':
      return '160px'
    case 'medium':
      return '200px'
    case 'large':
      return '250px'
    case 'big':
      return '300px'
    default:
      return '100%'
  }
}

export const StyledPrimaryButton = styled.button`
    appearance: none;
    align-items: center;
    background-color: ${props => props.theme.main.colors.buttonPrimaryColor};
    border: 1px solid ${props => props.theme.main.colors.buttonPrimaryColor};
    border-radius: 3px;
    color: ${props => props.theme.main.colors.primaryColor};
    cursor: pointer;
    display: inline-flex;
    font-family: ${props => props.theme.main.fonts.secondaryFontFamily};
    font-size: 16px;
    height: 40px;
    justify-content: center;
    letter-spacing: 0.6px;
    max-width: ${({ size }) => handleButtonSize(size)};
    padding: 12px 24px;
    text-decoration: none;
    width: auto;
    ${props => props.center && 'margin: 0 auto;'}

    &:hover {
        opacity: 0.8;
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }

    &[disabled] {
        background-color: ${props => props.theme.main.colors.disabledInputBgColor};
        border: 1px solid ${props => props.theme.main.colors.disabledInputBgColor};
        &:hover {
            opacity: 1;
        }
    }
`
