// @flow

import { requestTypeCreator } from 'src/helpers'

import type {
  BasicRedux,
  GetOrderSettingsActionTypes,
  EditOrderSettingsActionTypes,
} from './orderSettingsTypes.js'

export const GET_ORDER_SETTINGS = requestTypeCreator('GET_ORDER_SETTINGS')
export const EDIT_ORDER_SETTINGS = requestTypeCreator('EDIT_ORDER_SETTINGS')
export const UPDATE_OWNER_LEAGUES_PRICES = requestTypeCreator('UPDATE_OWNER_LEAGUES_PRICES')
export const UPDATE_OWNER_LEAGUES_VENDOR = requestTypeCreator('UPDATE_OWNER_LEAGUES_VENDOR')
export const FORCE_LEAGUE_VENDOR_UPDATE = requestTypeCreator('FORCE_LEAGUE_VENDOR_UPDATE')

export const getOrderSettings = {
  error: (payload: any): GetOrderSettingsActionTypes => ({ type: GET_ORDER_SETTINGS.FAILURE, payload }),
  request: (payload: any): GetOrderSettingsActionTypes => ({ type: GET_ORDER_SETTINGS.REQUEST, payload }),
  success: (payload: any): GetOrderSettingsActionTypes => ({ type: GET_ORDER_SETTINGS.SUCCESS, payload }),
}
export const editOrderSettings = {
  error: (payload: any): EditOrderSettingsActionTypes => ({ type: EDIT_ORDER_SETTINGS.FAILURE, payload }),
  request: (payload: any): EditOrderSettingsActionTypes => ({ type: EDIT_ORDER_SETTINGS.REQUEST, payload }),
  success: (payload: any): EditOrderSettingsActionTypes => ({ type: EDIT_ORDER_SETTINGS.SUCCESS, payload }),
}

export const updateOwnerLeaguesPrices = {
  error: (payload: any): BasicRedux => ({ type: UPDATE_OWNER_LEAGUES_PRICES.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: UPDATE_OWNER_LEAGUES_PRICES.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: UPDATE_OWNER_LEAGUES_PRICES.SUCCESS, payload }),
}

export const updateOwnerLeaguesVendor = {
  error: (payload: any): BasicRedux => ({ type: UPDATE_OWNER_LEAGUES_VENDOR.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: UPDATE_OWNER_LEAGUES_VENDOR.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: UPDATE_OWNER_LEAGUES_VENDOR.SUCCESS, payload }),
}

export const forceLeagueVendorUpdate = {
  request: (payload: any): BasicRedux => ({ type: FORCE_LEAGUE_VENDOR_UPDATE.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: FORCE_LEAGUE_VENDOR_UPDATE.SUCCESS, payload }),
}
