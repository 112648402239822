// @flow

import type { MessageCenterTypes } from './messageCenterType'
import { requestTypeCreator } from 'src/helpers'

export const GET_MESSAGE_CENTER = requestTypeCreator('GET_MESSAGE_CENTER')
export const UPDATE_MESSAGE_CENTER = requestTypeCreator('UPDATE_MESSAGE_CENTER')

export const getMessageCenter = {
  error: (payload: any): MessageCenterTypes => ({ type: GET_MESSAGE_CENTER.FAILURE, payload }),
  request: () => ({ type: GET_MESSAGE_CENTER.REQUEST }),
  success: (payload: any): MessageCenterTypes => ({ type: GET_MESSAGE_CENTER.SUCCESS, payload }),
}

export const updateMessageCenter = {
  error: (payload: any): MessageCenterTypes => ({ type: UPDATE_MESSAGE_CENTER.FAILURE, payload }),
  request: (payload: any): MessageCenterTypes => ({ type: UPDATE_MESSAGE_CENTER.REQUEST, payload }),
  success: (payload: any): MessageCenterTypes => ({ type: UPDATE_MESSAGE_CENTER.SUCCESS, payload }),
}
