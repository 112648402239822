// @flow
import React from 'react'
import { FormSelectField, FormTextField } from 'src/common/components'
import { Field } from 'redux-form'

// styles
import {
  TableExportFormStyle,
} from '../TableExportStyles'

const FORMATS = [{
  val: 'xls',
  name: '.xls File',
}, {
  val: 'csv',
  name: '.csv File',
}, {
  val: 'html',
  name: 'HTML Table for printing',
}, {
  val: 'xlsx',
  name: '.xlsx File',
}]

const TableExportForm = () => {
  return (
    <TableExportFormStyle>
      <form>
        <div className="form-row">
          <div className="form-field">
            <Field
              name="fileformat"
              id="fileformat"
              variant="outlined"
              animatedLabel="Export format"
              size="small"
              component={FormSelectField}
            >
              {FORMATS.map(({ val, name }) => (
                <option
                  key={val}
                  value={val}
                >
                  {name}
                </option>
              ))}
            </Field>
          </div>
          <div className="form-field">
            <Field
              name="filename"
              id="filename"
              variant="outlined"
              label="File name"
              size="small"
              component={FormTextField}
            />
          </div>
        </div>
      </form>
    </TableExportFormStyle>
  )
}

export default TableExportForm

