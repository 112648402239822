// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'
const LeaguesList = lazy(() => import('src/modules/leagues/leaguesList'))
const LeagueOrganizer = lazy(() => import('src/modules/leagues/leagueDetails/leagueOrganizer'))
const EditLeague = lazy(() => import('src/modules/leagues/leagueDetails/editLeague'))
const EditLeagueOrderSettings = lazy(() => import('src/modules/leagues/leagueDetails/editLeagueOrderSettings'))
const PreviewLeague = lazy(() => import('src/modules/leagues/leagueDetails/previewLeague'))
const OrdersList = lazy(() => import('src/modules/leagues/leagueDetails/ordersList'))
const LeagueInsurance = lazy(() => import('src/modules/leagues/leagueDetails/leagueInsurance'))
const LeagueMembers = lazy(() => import('src/modules/leagues/leagueDetails/leagueMembers'))
const VirtualTerminal = lazy(() => import('src/modules/leagues/leagueDetails/virtualTerminal'))
const OrderLogsContainer = lazy(() => import('src/modules/logs/orderLogsList'))
const PaidOrderContainer = lazy(() => import('src/modules/orders/paidOrder'))
const Seasons = lazy(() => import('src/modules/leagues/leagueDetails/seasons'))
const EditSeason = lazy(() => import('src/modules/leagues/leagueDetails/editSeason'))

export default [
  <PrivateRoute exact={true} path="/leagues-list" component={LeaguesList} key="leagues-list"/>,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/organizer" component={LeagueOrganizer} key="league-details-organizer" />,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/orders" component={OrdersList} key="league-details-orders" />,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/preview-league" component={PreviewLeague} key="league-details-preview" />,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/edit-league" component={EditLeague} key="league-details-edit" />,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/edit-league-order-settings" component={EditLeagueOrderSettings} key="league-order-settings" />,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/members" component={LeagueMembers} key="league-details-members" />,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/insurance" component={LeagueInsurance} key="league-details-insurance" />,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/virtual-terminal" component={VirtualTerminal} key="league-details-virtual-terminal" />,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/orders/:orderId/logs" component={OrderLogsContainer} key="order-log-details"/>,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/orders/:orderId" component={PaidOrderContainer} key="/my-leagues/leagueId/orders"/>,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/seasons" component={Seasons} key="league-details-seasons"/>,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/seasons/:seasonId/edit" component={EditSeason} key="league-details-edit-season"/>,
]
