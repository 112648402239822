// @flow

import { reduxForm } from 'redux-form'
import config from 'src/config/formsConfig'
import PayWithCheck from './PayWithCheck'

const Form = reduxForm({
  destroyOnUnmount: true,
  form: config.payWithCheckForm.id,
})(PayWithCheck)

export default Form
