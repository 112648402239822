// @flow
import { call, put, takeEvery, fork } from 'redux-saga/effects'
import { getLeaguePrograms, GET_LEAGUE_PROGRAMS } from 'src/redux/actions/leagues/leagues'
import { fetchLeaguePrograms } from 'src/services/leagues'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetLeagueProgramsStart(): Generator<IOEffect, void, any> {
  yield takeEvery(
    GET_LEAGUE_PROGRAMS.REQUEST,
    getLeagueProgramsRequest,
  )
}

function * getLeagueProgramsRequest(): Generator<IOEffect, void, any> {
  try {
    const data = yield call(
      fetchLeaguePrograms,
    )
    yield put(getLeaguePrograms.success(data))
  } catch (e) {
    yield put(getLeaguePrograms.error(e.message))
  }
}

export default [fork(watchGetLeagueProgramsStart)]
