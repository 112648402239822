// @flow

import React from 'react'

// types

import type { FormFileInputPropsType } from './FormFileInputTypes'

import { StyledFileButton } from './formInputStyles'

const FormFileInput = (props: FormFileInputPropsType) => {
  // $FlowFixMe
  const { buttonText = 'submit', handleSubmit, action, params, size, input: { name }, ...rest } = props

  const handleChange = e => {
    const { input: { onChange } } = props
    onChange(e.target.files[0])
    handleSubmit && action && handleSubmit(action({ [name]: e.target.files[0], ...params }))
  }

  return (
    <StyledFileButton
      buttonText={buttonText}
      size={size}
      {...rest}
    >
      <input type="file" onChange={handleChange}/>
    </StyledFileButton>

  )
}

export default FormFileInput
