// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { UPDATE_FEATURE, updateFeature, getFeatures } from 'src/redux/actions/featureManagement/featureManagement'
import { updateFeature as fetchUpdateFeature } from 'src/services/featureManagement'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchUpdateFeature(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(UPDATE_FEATURE.REQUEST)

    try {
      yield call(fetchUpdateFeature, payload)

      yield put(updateFeature.success())
      yield put(getFeatures.request())
    } catch (e) {
      yield put(updateFeature.error(e.message))
    }
  }
}

export default [fork(watchUpdateFeature)]
