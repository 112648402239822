import http from 'src/services/http'
import { shapePaymentMethods } from 'src/redux/sagas/payments/getPaymentMethods'

export const getBackgroundCheck = async backgroundCheckId => {
  const response = await http(`admin/backgroundChecks/${backgroundCheckId}`, {
    method: 'GET',
  })
  return response?.data
}

export const getPaymentMethods = async accountId => {
  const response = await http(`admin/payment/list?accountId=${accountId}`, {
    method: 'GET',
  })
  return shapePaymentMethods(response?.data)
}

export const getPaymentToken = async accountId => {
  const response = await http(`admin/payment/client-token?accountId=${accountId}`, {
    method: 'GET',
  })
  return response?.data
}

export const payForBackgroundCheck = async(backgroundCheckId, paymentOptions) => {
  const response = await http(`admin/backgroundChecks/${backgroundCheckId}/pay`, {
    method: 'POST',
    body: JSON.stringify(paymentOptions),
  })
  return response?.data
}

export const payByCheckBC = async(backgroundCheckId, { amount, checkNumber }) => {
  const response = await http(`admin/backgroundChecks/${backgroundCheckId}/payByCheck`, {
    method: 'POST',
    body: JSON.stringify({
      amount: +amount,
      checkNumber,
    }),
  })
  return response?.data
}

export const getOrderPaymentMethods = async orderId => {
  const response = await http(`admin/order/payment-methods/${orderId}`, {
    method: 'GET',
  })
  return shapePaymentMethods(response?.data)
}
