// @flow

import { reduxForm } from 'redux-form'
import config from 'src/config/formsConfig'
import TableExportForm from './TableExportForm'
import { connect } from 'react-redux'
import { compose } from 'redux'

const Form = reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  form: config.tableExportForm.id,
})(TableExportForm)

const mapStateToProps = state => {
  return {
    initialValues: {
      fileformat: 'xlsx',
      filename: 'excel-list',
    },
  }
}

export default compose(
  connect(mapStateToProps),
)(Form)

