// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_LEAGUE_ORDERS, getLeagueOrders } from 'src/redux/actions/orders/orders'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetOrders(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { leagueId, ...values },
    } = yield take(GET_LEAGUE_ORDERS.REQUEST)

    const params = {
      limit: values.limit,
      offset: values.offset,
    }

    try {
      let { data } = yield call(http, `admin/league/${leagueId}/orders`, {
        method: 'GET',
        params,
      })

      data = { ...data, page: values.page || 1 }

      yield put(getLeagueOrders.success(data))
    } catch (e) {
      yield put(getLeagueOrders.error(e.message))
    }
  }
}

export default [fork(watchGetOrders)]
