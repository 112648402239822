// @flow
import React from 'react'
// types
import type { PaginationPropsType, PaginationStateType } from './PaginationTypes'

// styles
import { StyledPagination } from './paginationStyles'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const AVAILABLE_PAGE_SIZES = [10, 20, 50, 100]

class Pagination extends React.Component<PaginationPropsType, PaginationStateType> {
  state = {
    page: 1,
    size: 50,
    pages: [],
    total: 0,
    totalPages: 0,
  }

  componentDidMount() {
    // set page if items array isn't empty
    if (this.props.total) {
      this.setContent()
    }
  }

  componentDidUpdate(prevProps: PaginationPropsType, prevState: PaginationStateType) {
    if (
      (this.props.total !== prevProps.total) ||
                (this.props.page !== prevProps.page) ||
                (this.props.limit !== prevProps.limit)
    ) {
      this.setContent()
    }
  }

  setPage(page: number) {
    this.props.onChangePage(page)
  }

  setSize(size: number) {
    this.props.onChangeSize(size)
  }

  setContent() {
    this.setState({ ...this.calculateContent() })
  }

  calculateContent() {
    let {
      total,
      page,
      limit,
    } = this.props

    // default to first page
    page = +page || 1

    // default page size is 10
    limit = limit || 10

    // calculate total pages
    const totalPages = Math.ceil(total / limit)

    let startPage, endPage
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1
      endPage = totalPages
      // more than 10 total pages so calculate start and end pages
    } else if (page <= 6) {
      startPage = 1
      endPage = 10
    } else if (page + 4 >= totalPages) {
      startPage = totalPages - 9
      endPage = totalPages
    } else {
      startPage = page - 5
      endPage = page + 4
    }

    // create an array of pages to ng-repeat in the pager control
    let pages: number[] = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i)

    const pageLimitDelta: number = page === 1 || page === totalPages ? 2 : 1

    if (window.innerWidth <= 450) {
      pages = pages.filter(num => Math.abs(page - num) <= pageLimitDelta)
    } else if (window.innerWidth < 650) {
      pages = pages.filter(num => Math.abs(page - num) <= pageLimitDelta + 2)
    }

    return {
      page,
      size: limit,
      total,
      pages,
      totalPages,
    }
  }

  render() {
    const { page: PAGE, pages, size, totalPages } = this.state

    const isPrevDisabled = PAGE === 1
    const isFirstDisabled = PAGE === 1
    const isNextDisabled = PAGE >= totalPages
    const isLastDisabled = PAGE >= totalPages
    return (
      <StyledPagination>
        <div className="pagination">

          <div className="page-item edge-jump">
            <button disabled={isFirstDisabled} onClick={() => this.setPage(1)} className={isFirstDisabled ? 'page-link disabled' : 'page-link'}>First</button>
          </div>

          <div className="page-item">
            <button disabled={isPrevDisabled} onClick={() => this.setPage(PAGE - 1)} className={isPrevDisabled ? 'page-link disabled' : 'page-link'}>Previous</button>
          </div>
          {pages.map(page =>
            <div className="page-item page-num" key={`page-${page}`}>
              <button onClick={() => this.setPage(page)} className={+PAGE === +page ? 'page-link is-current' : 'page-link'}>{page}</button>
            </div>,
          )}
          <div className="page-item">
            <button disabled={isNextDisabled} onClick={() => this.setPage(PAGE + 1)} className={isNextDisabled ? 'page-link disabled' : 'page-link'}>Next</button>
          </div>

          <div className="page-item edge-jump">
            <button disabled={isLastDisabled} onClick={() => this.setPage(totalPages)} className={isLastDisabled ? 'page-link disabled' : 'page-link'}>Last</button>
          </div>
          <div className="page-item">
            <div className="children">

              <Select
                labelId="select-label-page-size"
                id="select-page-size"
                value={size}
                onChange={e => this.setSize(e.target.value)}
              >
                {AVAILABLE_PAGE_SIZES.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="page-item flex hide-mobile">
            <span className="per-page">
              Per Page
            </span>
          </div>
        </div>
      </StyledPagination>
    )
  }
}

export default Pagination
