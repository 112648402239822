import http from 'src/services/http'

export const getFeatures = async() => {
  const response = await http('admin/features', {
    method: 'GET',
  })
  return response?.data
}
export const updateFeature = async feature => {
  const { name } = feature
  const response = await http(`admin/features/${name}`, {
    method: 'PUT',
    body: JSON.stringify(feature),
  })
  return response?.data
}

export const getFeature = async name => {
  const response = await http(`features/${name}`, {
    method: 'GET',
  })
  return response?.data
}
