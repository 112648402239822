// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import {
  GET_RESOURCES,
  getResources,
  UPDATE_RESOURCES,
  updateResources,
} from 'src/redux/actions/resources/resources'
import http from 'src/services/http'
import { showToast } from 'src/redux/actions/ui/ui'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchUpdateResources(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: data,
    } = yield take(UPDATE_RESOURCES.REQUEST)
    try {
      yield http('admin/resource', {
        body: JSON.stringify({ data }),
        method: 'POST',
      })

      yield put(updateResources.success(data))
      yield put(showToast.success({
        title: 'Resources was update',
        messageType: 'success',
      }))
    } catch (e) {
      yield put(updateResources.error(e.message))
      yield put(
        showToast.success({
          title: e.message || 'Something went Wrong',
          messageType: 'error',
        }),
      )
    }
  }
}

function * watchGetResources(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_RESOURCES.REQUEST)

    try {
      const { data } = yield call(http, 'admin/resource', {
        method: 'GET',
      })
      yield put(getResources.success(data))
    } catch (e) {
      yield put(getResources.error(e.message))
      yield put(
        showToast.success({
          title: e.message || 'Something went Wrong',
          messageType: 'error',
        }),
      )
    }
  }
}

export default [
  fork(watchUpdateResources),
  fork(watchGetResources),
]
