// @flow
import React, { useEffect } from 'react'
import { Field } from 'redux-form'
import http from 'src/services/http'
import { Link } from 'react-router-dom'

import Popper from '@material-ui/core/Popper'
import Button from '@material-ui/core/Button'
import {
  usePopupState,
  bindToggle,
  bindPopper,
} from 'material-ui-popup-state/hooks'

import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { useRequestCallback } from 'src/hooks'
import {
  Loader,
  ButtonPrimary,
  FormTextField,
  UserConfirm,
} from 'src/common/components'
import StyledBanner, { BANNER_TYPES } from 'src/common/components/bannerBlockStyle'

import config from 'src/config/formsConfig'
import { STEPS } from 'src/modules/order/utils'
import { formatMoney, getPriceString, toDollar } from 'src/helpers/moneyHelpers'

import { onlyDecimal } from 'src/helpers/formNormalizers'
import { required, minNumber, trimCheck } from 'src/helpers/formValidation'
import { CURRENCIES, COUNTRY_CODES } from 'src/common/constants'

import { FormStyle } from './CheckReceivedStyles'

import type { CheckReceivedPropsType } from './CheckReceivedTypes'

const aboveZero = minNumber(1)

const getOrderSummary = order_id => http(`admin/order/${order_id}/summary`, {
  method: 'GET',
})

const getLatestActiveInvoice = order_id => http(`admin/invoice/order/${order_id}`, {
  method: 'GET',
})

// const validateOrder = order_id => http(`admin/order/${order_id}/valid-for-submit`, {
//   method: 'GET',
// }, { disableToastr: true })

const checkReceived = (order_id, data) => http(`admin/order/${order_id}/check-received`, {
  method: 'POST',
  body: JSON.stringify({
    check_number: data.check_number,
    amount: +data.amount,
  }),
})

const CheckReceived = ({
  order_id,
  formVals,
  reset,
  handleSubmit,
  onSuccess = () => undefined,
}: CheckReceivedPropsType) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'checkSubmit' })
  const [{
    data: orderSummaryData,
    isLoading: isLoading1,
  }, orderSummaryHandler] = useRequestCallback()
  const [{
    data: latestActiveInvoiceData,
    errorData: latestActiveInvoiceError,
    isLoading: isLoading2,
  }, latestActiveInvoiceHandler] = useRequestCallback()
  const [{
    errorData: orderValidationError,
    isLoading: isLoading3,
  }] = useRequestCallback()
  const [{
    errorData: checkReceivedError,
    isSuccessful: checkReceivedIsSuccessful,
    isLoading: isLoading5,
  }, checkReceivedHandler] = useRequestCallback()

  const isLoading = isLoading1 || isLoading2 || isLoading3 || isLoading5

  const order = orderSummaryData ? orderSummaryData.order : {}
  const cantBeProceeded = orderValidationError && !order.submitted_at
  const grandTotalDifference = (orderSummaryData && latestActiveInvoiceData)
    ? orderSummaryData.order.total_amount - latestActiveInvoiceData.snapshot.order.total_amount
    : 0

  useEffect(() => {
    if (!popupState.isOpen) return

    orderSummaryHandler(() => getOrderSummary(order_id))
    latestActiveInvoiceHandler(() => getLatestActiveInvoice(order_id))
    // orderValidationHandler(() => validateOrder(order_id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupState.isOpen])

  useEffect(() => {
    if (!checkReceivedIsSuccessful) return

    reset(config.checkReceived.id)
    popupState.close()
    onSuccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkReceivedIsSuccessful])

  const checkCurrency = order?.league_owner_country === COUNTRY_CODES.CA ? CURRENCIES.CAD : CURRENCIES.USD

  return (
    <div>
      <ButtonPrimary size="medium" buttonText={'Check Received'} {...bindToggle(popupState)} />
      <Popper {...bindPopper(popupState)} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <ClickAwayListener onClickAway={popupState.close}>
                <Paper>
                  <Loader show={isLoading}/>
                  <FormStyle>
                    <DialogContent>
                      {cantBeProceeded
                        ? (
                          <StyledBanner type={BANNER_TYPES.ERROR} mb={10}>
                            {orderValidationError.message}
                          </StyledBanner>
                        )
                        : (
                          <>
                            {latestActiveInvoiceError && latestActiveInvoiceError.message && (
                              <StyledBanner type={BANNER_TYPES.WARNING} mb={10}>
                                {latestActiveInvoiceError.message}
                              </StyledBanner>
                            )}
                            {grandTotalDifference !== 0 && (
                              <StyledBanner type={BANNER_TYPES.WARNING} mb={10}>
                                {grandTotalDifference > 0 && 'Order Total increased. Please work with the accounting team to issue an additional invoice.'}
                                {grandTotalDifference < 0 && 'Order Total decreased. Please work with the accounting team to process a refund.'}
                              </StyledBanner>
                            )}
                            {order && (
                              <DialogContentText>
                                Remaining {getPriceString({
                                  price: order?.amount_to_pay,
                                  currency: checkCurrency,
                                  currencyRate: order?.currency_cad_rate || 1,
                                })}
                              </DialogContentText>
                            )}
                            <div className="row">
                              <Field
                                name="check_number"
                                id="check_number"
                                label={'Check #'}
                                fullWidth
                                margin="dense"
                                component={FormTextField}
                                validate={[required, trimCheck]}
                              />
                            </div>
                            <div className="row">
                              <Field
                                name="amount"
                                id="amount"
                                label={'Amount'}
                                fullWidth
                                margin="dense"
                                component={FormTextField}
                                validate={[required, aboveZero]}
                                normalize={onlyDecimal}
                              />
                            </div>
                            {checkReceivedError && checkReceivedError.message && (
                              <StyledBanner type={BANNER_TYPES.ERROR} mb={10}>
                                {checkReceivedError.message}
                              </StyledBanner>
                            )}
                          </>
                        )}
                    </DialogContent>
                    {cantBeProceeded
                      ? (
                        <DialogActions>
                          <Link to={`/order/league/${order.league_id}/season/${order.season_id}/order/${order.id}/step/${STEPS.ORDER_SUMMARY}?check_received=true`}>
                            <ButtonPrimary size="small" buttonText={'Edit Order'}/>
                          </Link>
                        </DialogActions>
                      )
                      : (
                        <DialogActions>
                          <Button onClick={popupState.close}>Cancel</Button>

                          <UserConfirm
                            title={`Confirm Order ${order_id}`}
                            description={
                              <>
                                <div>Check# {formVals.check_number}</div>
                                <div>Amount: ${formatMoney(formVals.amount)}</div>
                              </>
                            }
                            closedQuestion={true}
                          >
                            {confirm => (
                              <Button
                                color="primary"
                                onClick={() => {
                                  return handleSubmit(values => {
                                    const clonedValues = { ...values }
                                    const { amount } = clonedValues
                                    const shapedAmount = toDollar({
                                      price: amount,
                                      currency: checkCurrency,
                                      currencyRate: order?.currency_cad_rate,
                                      round: false,
                                    })
                                    clonedValues.amount = shapedAmount
                                    confirm({
                                      handleSubmit: () => {
                                        checkReceivedHandler(() => checkReceived(order_id, clonedValues))
                                      },
                                      handleCancel: () => popupState.close(),
                                      valid: true,
                                    })()
                                  })()
                                }}
                              >Submit</Button>
                            )}
                          </UserConfirm>
                        </DialogActions>
                      )}
                  </FormStyle>
                </Paper>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default CheckReceived
