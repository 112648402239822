// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_LEAGUE_INFO, getLeagueInfo } from 'src/redux/actions/leagues/leagues'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetLeagueInfo(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_LEAGUE_INFO.REQUEST)

    try {
      const { leagueId } = action.payload
      const { data } = yield call(http, `admin/league/${leagueId}/info`, {
        method: 'GET',
      })

      yield put(getLeagueInfo.success(data.seasons))
    } catch (e) {
      yield put(getLeagueInfo.error(e.message))
    }
  }
}

export default [fork(watchGetLeagueInfo)]
