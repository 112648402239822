// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_FEATURE, getFeature } from 'src/redux/actions/featureManagement/featureManagement'
import { getFeature as fetchGetFeature } from 'src/services/featureManagement'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetFeature(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload,
    } = yield take(GET_FEATURE.REQUEST)

    try {
      const data = yield call(fetchGetFeature, payload)

      yield put(getFeature.success(data))
    } catch (e) {
      yield put(getFeature.error(e.message))
    }
  }
}

export default [fork(watchGetFeature)]
