// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_EXEMPTION_CERTIFICATES, getExemptionCertificates } from 'src/redux/actions/exemptionCertificates/exemptionCertificates'
import { fetchExemptionCertificates } from 'src/services/exemptionCertificates'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetExemptionCertificates(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(GET_EXEMPTION_CERTIFICATES.REQUEST)
    try {
      const params = {
        limit: values.limit,
        page: values.page,
        leagueName: values.leagueName,
        exposureZone: values.exposureZone,
        createdAtFrom: values.createdAtFrom,
        createdAtTo: values.createdAtTo,
        exemptionType: values.exemptionType,
        status: values.status,
      }

      const data = yield call(fetchExemptionCertificates, params)

      yield put(getExemptionCertificates.success(data))
    } catch (e) {
      yield put(getExemptionCertificates.error(e.message))
    }
  }
}

export default [fork(watchGetExemptionCertificates)]
