// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { getMembersListByLeagueId, CHANGE_OWNER, changeOwner } from 'src/redux/actions/members/members'
import http from 'src/services/http'

import type { IOEffect } from 'redux-saga/effects'

function * watchChangeLeagueOrganizer(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { league_id, account_id },
    } = yield take(CHANGE_OWNER.REQUEST)

    try {
      yield call(http, `admin/owner/league/${league_id}/account/${account_id}`, {
        method: 'PUT',
      })

      yield put(changeOwner.success())
      yield put(getMembersListByLeagueId.request({ league_id }))
    } catch (e) {
      yield put(changeOwner.error(e.message))
    }
  }
}

export default [fork(watchChangeLeagueOrganizer)]
