import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledOrderItemPrices = styled.div`
  .label {
    align-items: center;
    display: flex;
    padding-right: 10px;
    min-width: 240px;

    svg {
      margin-right: 3px;
    }
  }

  .row {
    display: flex;
    margin-bottom: 10px;
    flex: 1;
  }

  .MuiFormControlLabel-root {
    margin-left: -6px;
  }

  .MuiOutlinedInput-input {
    padding: 8px 10px;
    width: 100px;
  }

  .value {
    align-items: center;
    display: flex;
    span {
      padding-right: 6px;
    }
  }

  .row-wrapper {
      display: flex;
  }
  @media ${device.tabletL} {
    .row-wrapper {
      flex-direction: column;
    }
  }
`

export const StyledOrderItemPricesWrapper = styled.div`
  border: 1px solid ${props => props.theme.main.colors.borderColor};
  padding: 20px; 
  .btn-wrapper {
    margin-top: 20px;

    button + button {
      margin-left: 20px;
    }
  }
`
