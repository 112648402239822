// @flow

import { stopSubmit } from 'redux-form'
import { fork, put, take } from 'redux-saga/effects'
import { BIND_INSURANCE, bindInsurance, getInsurance } from 'src/redux/actions/insurances/insurances'
import http from 'src/services/http'
import config from 'src/config/formsConfig'
import { showToast } from 'src/redux/actions/ui/ui'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchBindInsurance(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { qNum, qSuffix, insuranceId } } = yield take(BIND_INSURANCE.REQUEST)
    const formId = config.insuranceForm.id
    try {
      const data = yield http('admin/insurance/purchase/bind-insurance', {
        body: JSON.stringify({ qNum, qSuffix }),
        method: 'POST',
      })

      yield put(bindInsurance.success(data))

      showToast.success({
        title: `Certificate was bind with policy number: ${data.policyNumber}`,
        messageType: 'success',
      })

      yield put(getInsurance.request({ insuranceId }))
    } catch
    (e) {
      yield put(bindInsurance.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(
        showToast.success({
          title: (e.errors && e.errors.toString()) || e.message || 'Something went Wrong',
          messageType: 'error',
        }),
      )
    }
  }
}

export default [fork(watchBindInsurance)]
