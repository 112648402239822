// @flow

import { fork, put, take, call } from 'redux-saga/effects'
import { SUBMIT_VIRTUAL_PAYMENT, submitVirtualPayment, getPaymentToken } from 'src/redux/actions/payments/payments'
import http from 'src/services/http'
import { history } from 'src/services/history'
import { resetBulkOrder } from 'src/redux/actions/bulkOrder/bulkOrder'
import { stopSubmit } from 'redux-form'
import config from 'src/config/formsConfig'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSubmitVirtualPayment(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { paymentInfo, maxValue, ...values },
    } = yield take(SUBMIT_VIRTUAL_PAYMENT.REQUEST)
    const formId = config.virtualTerminalForm.id

    try {
      const { nonce, token, storeInVaultOnSuccess, paymentType } = paymentInfo
      if (!maxValue) {
        throw new Error('One of fields \'Order Amount\', \'Shipping Amount\' or \'Sales Tax Amount\' should be more than 0')
      } else {
        const { data: order } = yield http('admin/payment/virtual-terminal', {
          body: JSON.stringify({
            nonceFromTheClient: nonce,
            paymentMethodToken: token,
            storeInVaultOnSuccess,
            paymentType,
            ...values,
            terminal_id: +values.terminal_id,
            league_id: +values.league_id,
          }),
          method: 'POST',
        })

        yield put(submitVirtualPayment.success())
        yield call(history.push, `/leagues-list/${values.league_id}/league-details/order/${order.id}/thank-you`)
        yield put(resetBulkOrder.success())
      }
    } catch (e) {
      yield put(getPaymentToken.request())
      yield put(submitVirtualPayment.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchSubmitVirtualPayment)]
