// @flow
import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { LogoInverse } from 'src/assets/images'
import { SidebarNav } from 'src/common/components'
import { withUser } from 'src/decorators'

// styles
import { StyledSidebar } from './sidebarStyles'

// types
interface SidebarProps {
    container?: Element,
    user: {
      userData: {
        first_name: string
      }
    }
}

const Sidebar = ({ container, user }: SidebarProps) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const { userData } = user

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <StyledSidebar>
      <div className="sidebar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className="menu-button"
        >
          <MenuIcon/>
        </IconButton>
        <nav className="nav-wrapper">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              className="drawer-paper"
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div className="logo">
                <img src={LogoInverse} alt="logo"/>
                <span>Hello, {userData.first_name}</span>
              </div>
              <SidebarNav />
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              className="drawer-paper"
              variant="permanent"
              open
            >
              <div className="logo">
                <img src={LogoInverse} alt="logo"/>
                <span>Hello, {userData.first_name}</span>
              </div>
              <SidebarNav />
            </Drawer>
          </Hidden>
        </nav>
      </div>
    </StyledSidebar>
  )
}

export default withUser(Sidebar)
