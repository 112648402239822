// @flow
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// types
import type { TabPanelProps } from './TabPanelTypes'

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    // $FlowFixMe
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

export default TabPanel
