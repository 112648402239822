// @flow
import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import LanguageIcon from '@material-ui/icons/Language'
import { Link } from 'react-router-dom'
import getRole from 'src/helpers/getRolesTitle'
import { ButtonPrimary, LeagueActiveSeason, RenderIf } from 'src/common/components'
import leagueStatuses from 'src/config/leagueStatuses'

// styles
import { StyledCard } from './cardLeagueStyles'

// types
import type { CardLeagueTypes } from './CardLeagueTypes'

const Wrapper = ({ showButton, onClickHandler, id, children }) => {
  const wrapper = showButton
    ? (
      <div className="card-layout" onClick={showButton ? onClickHandler : () => {}}>
        {children}
      </div>
    )
    : (
      <Link
        to={{
          pathname: `/leagues-list/${id}/league-details/preview-league`,
          state: { activeTab: 0 },
        }}

        className="card-layout"
      >
        {children}
      </Link>
    )
  return wrapper
}

const CardLeague = (props: CardLeagueTypes) => {
  const openWebSite = event => {
    event.preventDefault()
    window.open(props.websiteUrl, '_blank')
  }

  const openPhone = event => {
    event.preventDefault()
    window.open(`tel: ${props.phone}`, '_self')
  }

  return (
    <StyledCard status={props.status && props.status.toLowerCase()}>
      <Wrapper {...props}>
        <div className="card-left">
          <div>
            <div className="card-heading">
              <h5 className="card-title">
                <div>
                  <div>{props.leagueName}</div>
                </div>
              </h5>
              <span className="status">{props.status && props.status.toLowerCase()}</span>
            </div>
            <div className="card-contacts d-flex align-items-center row">
              <ul className="list-contacts d-flex list">
                <li className="list-item">
                  <div className="contact location">
                    <span className="icon-wrapper">
                      <LocationOnIcon/>
                    </span>
                    {props.location}
                  </div>
                </li>
                <li className="list-item">
                  <div className="contact phone">
                    <span className="icon-wrapper">
                      <PhoneIcon/>
                    </span>
                    <div className="link-item" onClick={openPhone}>{props.phone}</div>
                  </div>
                </li>
                {props.websiteUrl && (
                  <li className="list-item">
                    <div className="contact website" onClick={ev => ev.stopPropagation()}>
                      <span className="icon-wrapper">
                        <LanguageIcon/>
                      </span>
                      <div className="link-item" onClick={openWebSite}>Website</div>
                    </div>
                  </li>
                )}
              </ul>
            </div>

            {props.leagueOwner &&
            <div className="card-subtitle card-username row">
              <span className="label">Organizer:</span>
              <span className="value">{props.leagueOwner}</span>
            </div>
            }
            {props.roles && Array.isArray(props.roles) && (
              <div className="card-subtitle row">
                <span className="label">Role: </span>
                {props.roles.map((role, index, array: string[]) => (
                  <span
                    key={index}
                    className="value"
                  >
                    {getRole(role)}{index < array.length - 1 ? ',' : ''}
                  </span>
                ))}
              </div>
            )}
            <div className="list-divisions row">
              <div className="d-flex">
                <span className="label">Team Genders:</span>
                <span className="value">
                  {props.hasBoy && 'boys'}{props.hasBoy && (props.hasGirl || props.hasCoed) ? ', ' : ''}
                  {props.hasGirl && 'girls'}{props.hasGirl && props.hasCoed ? ', ' : ''}
                  {props.hasCoed && 'co-ed'}
                </span>
              </div>
            </div>
            <div className="dates">
              {props.submittedDate && (
                <div className="date">
                  <span className="label">Submitted date:</span>
                  <span className="value">{props.submittedDate}</span>
                </div>
              )}
              {props.approvedDate && props.status !== leagueStatuses.PENDING && (
                <div className="date">
                  <span
                    className="label">{props.status === leagueStatuses.APPROVED ? 'Approved date:' : 'Declined date:'}</span>
                  <span className="value">{props.approvedDate}</span>
                </div>
              )}
            </div>
            {props.distance && (
              <div className="dates">
                <div className="date">
                  <span className="label">Distance:</span>
                  <span className="value">{props.distance}</span>
                </div>
              </div>
            )}
          </div>
          <RenderIf condition={props.program_logo}>
            <div className="logo">
              <img src={props.program_logo} />
            </div>
          </RenderIf>
        </div>
        <div className="card-center">
          <LeagueActiveSeason
            seasons={props.activeSeasons}
          />

        </div>

        {props.showButton && (
          <div className="card-right">
            <Link to={{
              pathname: `/leagues-list/${props.id}/league-details/preview-league`,
              state: { activeTab: 0 },
            }}>
              <ButtonPrimary buttonText={'Manage League'}/>
            </Link>
          </div>
        )}
      </Wrapper>
    </StyledCard>
  )
}

export default CardLeague
