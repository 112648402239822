// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { DELETE_LEAGUE, deleteLeague } from 'src/redux/actions/leagues/leagues'
import http from 'src/services/http'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchDeleteSeason(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { league_id },
    } = yield take(DELETE_LEAGUE.REQUEST)

    try {
      yield call(http, `admin/league/${league_id}`, {
        method: 'DELETE',
      })

      yield put(deleteLeague.success())
      yield call(history.push, '/leagues-list')
    } catch (e) {
      yield put(deleteLeague.error(e.message))
    }
  }
}

export default [fork(watchDeleteSeason)]
