// @flow
import type { BasicRedux } from 'src/redux/actions/NFLTeams/NFLTeamsTypes'
import { GET_NFL_TEAMS } from 'src/redux/actions/NFLTeams/NFLTeams'

export type NFLTeamsDataItem = {
    color: ?string,
    conference: string,
    full_name: string,
    id: string,
    logo: ?string,
    short_name: string,
    show_popup: boolean
};
export type NFLTeamsData = NFLTeamsDataItem[];

export type NFLTeamsState = {
    isLoading: boolean,
    NFLTeamsData: NFLTeamsData,
    NFLTeamsMapped: {
        [key: string]: NFLTeamsDataItem
    },
    NFLTeamsErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  NFLTeamsData: [],
  NFLTeamsMapped: {},
  NFLTeamsErrors: null,
}

const NFLTeams = (state: NFLTeamsState = initialState, action: BasicRedux): NFLTeamsState => {
  switch (action.type) {
    case GET_NFL_TEAMS.REQUEST:
    {
      return {
        ...state,
        isLoadistateng: true,
      }
    }

    case GET_NFL_TEAMS.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        NFLTeamsData: [...action.payload],
        NFLTeamsMapped: action.payload.reduce((acc, cur) => {
          acc[cur.id] = cur
          return acc
        }, {}),
        NFLTeamsErrors: null,
      })
    }

    case GET_NFL_TEAMS.FAILURE:
    {
      return { ...state, isLoading: false, NFLTeamsErrors: action.payload }
    }

    default: {
      return state
    }
  }
}

export default NFLTeams
