// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_INSURANCES, getInsurances } from 'src/redux/actions/insurances/insurances'
import http from 'src/services/http'
import moment from 'moment-timezone'

// types
import type { IOEffect } from 'redux-saga/effects'
import config from '../../config/formsConfig'
import { stopAsyncValidation, stopSubmit } from 'redux-form'

function * watchGetInSurances(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(GET_INSURANCES.REQUEST)

    const formId = config.searchIncurancesForm.id
    try {
      const params = {
        limit: values.limit,
        page: values.page,
        approval_status: values.approval_status,
        expiration_status: values.expiration_status,
        start_date: values.start_date && moment(values.start_date, 'MM/DD/YYYY h:mm:ss a').utc(),
        end_date: values.end_date && moment(values.end_date, 'MM/DD/YYYY h:mm:ss a').utc(),
        league_name: values.league_name,
      }

      const { data } = yield call(http, 'admin/insurance/list', {
        method: 'GET',
        params,
      })

      yield put(getInsurances.success(data))
    } catch (e) {
      yield put(getInsurances.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(stopAsyncValidation(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchGetInSurances)]
