// @flow

import { requestTypeCreator } from 'src/helpers'

import type {
  GetInsurancesActionTypes,
  GetInsurancesStatusesActionTypes,
  GetInsuranceActionTypes,
  SubmitInsuranceStatusActionTypes,
  GetLeagueInsurancesActionTypes,
  BindInsuranceActionTypes,
  CheckFileInsuranceActionTypes,
  BasicRedux,
} from './insurancesTypes.js'

export const GET_INSURANCES = requestTypeCreator('GET_INSURANCES')
export const GET_INSURANCES_STATUSES = requestTypeCreator('GET_INSURANCES_STATUSES')
export const GET_INSURANCE = requestTypeCreator('GET_INSURANCE')
export const SUBMIT_INSURANCE_STATUS = requestTypeCreator('SUBMIT_INSURANCE_STATUS')
export const GET_LEAGUE_INSURANCES = requestTypeCreator('GET_LEAGUE_INSURANCES')
export const BIND_INSURANCE = requestTypeCreator('BIND_INSURANCE')
export const CHECK_FILE_INSURANCE = requestTypeCreator('CHECK_FILE_INSURANCE')
export const GET_DECLINE_REASONS = requestTypeCreator('GET_DECLINE_REASONS')
// getDeclineReasons

export const getInsurances = {
  error: (payload: any): GetInsurancesActionTypes => ({ type: GET_INSURANCES.FAILURE, payload }),
  request: (payload: any): GetInsurancesActionTypes => ({ type: GET_INSURANCES.REQUEST, payload }),
  success: (payload: any): GetInsurancesActionTypes => ({ type: GET_INSURANCES.SUCCESS, payload }),
}
export const getInsurancesStatuses = {
  error: (payload: any): GetInsurancesStatusesActionTypes => ({ type: GET_INSURANCES_STATUSES.FAILURE, payload }),
  request: (payload: any): GetInsurancesStatusesActionTypes => ({ type: GET_INSURANCES_STATUSES.REQUEST, payload }),
  success: (payload: any): GetInsurancesStatusesActionTypes => ({ type: GET_INSURANCES_STATUSES.SUCCESS, payload }),
}
export const getInsurance = {
  error: (payload: any): GetInsuranceActionTypes => ({ type: GET_INSURANCE.FAILURE, payload }),
  request: (payload: any): GetInsuranceActionTypes => ({ type: GET_INSURANCE.REQUEST, payload }),
  success: (payload: any): GetInsuranceActionTypes => ({ type: GET_INSURANCE.SUCCESS, payload }),
}
export const submitInsuranceStatus = {
  error: (payload: any): SubmitInsuranceStatusActionTypes => ({ type: SUBMIT_INSURANCE_STATUS.FAILURE, payload }),
  request: (payload: any): SubmitInsuranceStatusActionTypes => ({ type: SUBMIT_INSURANCE_STATUS.REQUEST, payload }),
  success: (payload: any): SubmitInsuranceStatusActionTypes => ({ type: SUBMIT_INSURANCE_STATUS.SUCCESS, payload }),
}

export const getLeagueInsurances = {
  error: (payload: any): GetLeagueInsurancesActionTypes => ({ type: GET_LEAGUE_INSURANCES.FAILURE, payload }),
  request: (payload: any): GetLeagueInsurancesActionTypes => ({ type: GET_LEAGUE_INSURANCES.REQUEST, payload }),
  success: (payload: any): GetLeagueInsurancesActionTypes => ({ type: GET_LEAGUE_INSURANCES.SUCCESS, payload }),
}

export const bindInsurance = {
  error: (payload: any): BindInsuranceActionTypes => ({ type: BIND_INSURANCE.FAILURE, payload }),
  request: (payload: any): BindInsuranceActionTypes => ({ type: BIND_INSURANCE.REQUEST, payload }),
  success: (payload: any): BindInsuranceActionTypes => ({ type: BIND_INSURANCE.SUCCESS, payload }),
}

export const checkFileInsurance = {
  error: (payload: any): CheckFileInsuranceActionTypes => ({ type: CHECK_FILE_INSURANCE.FAILURE, payload }),
  request: (payload: any): CheckFileInsuranceActionTypes => ({ type: CHECK_FILE_INSURANCE.REQUEST, payload }),
  success: (payload: any): CheckFileInsuranceActionTypes => ({ type: CHECK_FILE_INSURANCE.SUCCESS, payload }),
}

export const getDeclineReasons = {
  error: (payload: any): BasicRedux => ({ type: GET_DECLINE_REASONS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_DECLINE_REASONS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_DECLINE_REASONS.SUCCESS, payload }),
}
