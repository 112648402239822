
// @flow
import React from 'react'
import { ArrowRightAlt, Check, Clear } from '@material-ui/icons'
// import Icon from "@material-ui/core/Icon"

import { formatFullDate } from 'src/helpers/dateHelpers'

// styles
import { DataDifferenceTimelineItemStyle } from './DataDifferenceTimelineItemStyles'

// types
import type { DataDifferenceTimelineItemTypes } from './DataDifferenceTimelineItemTypes'

const beautifyValue = val => {
  if (typeof val === 'boolean') {
    return val ? <Check/> : <Clear/>
  }
  return val || 'N/A'
}

const DataDifferenceTimelineItem = (props: DataDifferenceTimelineItemTypes) => {
  const { date, changesList } = props
  return (
    <DataDifferenceTimelineItemStyle>
      <p className="timeline-time">{formatFullDate(date)}</p>
      {changesList.map(({ prop, name, from, to }) => (
        <div className="prop-diff" key={prop}>
          <span className="prop-diff-row">
            {name}: {beautifyValue(from)} <ArrowRightAlt className="diff-arrow-icon"/> <b>{beautifyValue(to)}</b>
          </span>
        </div>
      ))}
    </DataDifferenceTimelineItemStyle>
  )
}

export default DataDifferenceTimelineItem

