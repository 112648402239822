import styled from 'styled-components'

export const BANNER_TYPES = {
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
}

const borderColor = (type, props) => {
  switch (type) {
    case BANNER_TYPES.ERROR:
      return 'rgba(212, 53, 64, 0.7)'
    case BANNER_TYPES.SUCCESS:
      return 'rgba(0,128,0)'
    case BANNER_TYPES.WARNING:
    default:
      return props.theme.main.colors.accentColor
  }
}

const backgroundColor = type => {
  switch (type) {
    case BANNER_TYPES.ERROR:
      return 'rgba(212,53,64,0.1)'
    case BANNER_TYPES.SUCCESS:
      return 'rgba(144,238,144,0.2)'
    case BANNER_TYPES.WARNING:
    default:
      return 'rgba(255,255,102,0.4)'
  }
}

export default styled.div`
    background-color: ${({ type }) => backgroundColor(type)};
    border: 1px solid ${props => borderColor(props.type, props)};
    padding: 15px;
    margin-bottom: ${({ mb }) => mb || 0}px;
`
