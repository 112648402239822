// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_INSURANCES_STATUSES, getInsurancesStatuses } from 'src/redux/actions/insurances/insurances'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetInsurancesStatuses(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_INSURANCES_STATUSES.REQUEST)
    try {
      const { data } = yield call(http, 'admin/insurance/statuses', {
        method: 'GET',
      })

      yield put(getInsurancesStatuses.success(data))
    } catch (e) {
      yield put(getInsurancesStatuses.error(e.message))
    }
  }
}

export default [fork(watchGetInsurancesStatuses)]
