import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledWrapper = styled.div`
    align-items: center;
    background-color: ${props => props.theme.main.colors.backgroundLight};
    display: flex;
    min-height: 100vh;
    justify-content: center;
`

export const StyledContent = styled.div`
    background-color: ${props => props.theme.main.colors.inverseColor};
    margin: 30px;
    max-width: 980px;
    min-width: 450px;
    padding: 50px;
    width: ${props => props.width ? props.width : '82vw'};
    
    @media ${device.tabletL} {
        padding: 35px;
    }
    
    @media ${device.tablet} {
        width: 100vw;
    }
    
    @media ${device.mobileL} {
        margin: 15px;
        min-width: unset;
    }
`
