// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_NFL_TEAMS, getNFLTeams } from 'src/redux/actions/NFLTeams/NFLTeams'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetNFLTeams(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_NFL_TEAMS.REQUEST)

    try {
      const { data } = yield call(http, 'admin/nfl-teams', {
        method: 'GET',
      })

      yield put(getNFLTeams.success(data))
    } catch (e) {
      yield put(getNFLTeams.error(e.message))
    }
  }
}

export default [fork(watchGetNFLTeams)]
