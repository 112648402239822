// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import {
  GET_MESSAGE_CENTER,
  getMessageCenter,
  UPDATE_MESSAGE_CENTER,
  updateMessageCenter,
} from 'src/redux/actions/messageCenter/messageCenter'
import http from 'src/services/http'
import { showToast } from 'src/redux/actions/ui/ui'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchUpdateMessageCenter(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload,
    } = yield take(UPDATE_MESSAGE_CENTER.REQUEST)
    const { body, isActive } = payload
    try {
      yield http('admin/message-center', {
        body: JSON.stringify({ body, isActive }),
        method: 'POST',
      })

      yield put(updateMessageCenter.success({ body, isActive }))
      yield put(showToast.success({
        title: 'Message was update',
        messageType: 'success',
      }))
    } catch (e) {
      yield put(updateMessageCenter.error(e.message))
      yield put(
        showToast.success({
          title: e.message || 'Something went Wrong',
          messageType: 'error',
        }),
      )
    }
  }
}

function * watchGetMessageCenter(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_MESSAGE_CENTER.REQUEST)

    try {
      const { data: { body, active_at } } = yield call(http, 'admin/message-center', {
        method: 'GET',
      })
      yield put(getMessageCenter.success({ body, isActive: Boolean(active_at) }))
    } catch (e) {
      yield put(getMessageCenter.error(e.message))
      yield put(
        showToast.success({
          title: e.message || 'Something went Wrong',
          messageType: 'error',
        }),
      )
    }
  }
}

export default [
  fork(watchUpdateMessageCenter),
  fork(watchGetMessageCenter),
]
