// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'
const UsersList = lazy(() => import('src/modules/users/usersList'))
const EditProfile = lazy(() => import('src/modules/users/userSettings/editProfile'))
const ChangeUserEmail = lazy(() => import('src/modules/users/userSettings/changeUserEmail'))
const LeaguesList = lazy(() => import('src/modules/users/userSettings/leaguesList'))
const OrderSettings = lazy(() => import('src/modules/users/userSettings/orderSettings'))
const AccountChanges = lazy(() => import('src/modules/users/userSettings/accountChanges'))

export default [
  <PrivateRoute exact={true} path="/users" component={UsersList} key="users"/>,
  <PrivateRoute exact={true} path="/users/:userId/user-settings" component={EditProfile} key="user-settings"/>,
  <PrivateRoute exact={true} path="/users/:userId/user-settings/profile" component={EditProfile} key="user-settings-profile"/>,
  <PrivateRoute exact={true} path="/users/:userId/user-settings/change-email" component={ChangeUserEmail} key="user-settings-change-email"/>,
  <PrivateRoute exact={true} path="/users/:userId/user-settings/order-settings" component={OrderSettings} key="user-settings-order-settings"/>,
  <PrivateRoute exact={true} path="/users/:userId/user-settings/leagues-list" component={LeaguesList} key="user-settings-leagues-list"/>,
  <PrivateRoute exact={true} path="/users/:userId/user-settings/account-changes" component={AccountChanges} key="user-settings-account-changes"/>,
]
