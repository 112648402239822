// @flow

import getExemptionCertificates from './getExemptionCertificates'
import getExemptionCertificateAttachment from './getExemptionCertificateAttachment'
import getExposureZones from './getExposureZones'

export default [
  ...getExemptionCertificates,
  ...getExemptionCertificateAttachment,
  ...getExposureZones,
]
