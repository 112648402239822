// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import {
  getMembersListByLeagueId,
  inviteMember,
  removeMember,
  changeOwner,
} from 'src/redux/actions/members/members'

const withMembers = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithMembersComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ members }) => ({ members })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getMembersListByLeagueId: values => dispatch(getMembersListByLeagueId.request({ league_id: ownProps.match.params.leagueId, ...values })),
    inviteMember: values => dispatch(inviteMember.request({ ...values, league_id: ownProps.match.params.leagueId })),
    removeMember: values => dispatch(removeMember.request({
      email: values.email,
      league_id: ownProps.match.params.leagueId,
    })),
    changeLeagueOrganizer: values => dispatch(changeOwner.request(values)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithMembersComponent)
}

export default withMembers

