import styled from 'styled-components'

export const StyledComponent = styled.div`
.label {
    align-items: center;
    display: flex;
    padding-right: 10px;
    width: 220px;
    
    svg {
        margin-right: 3px;
    }
}

.label-without-width {
    align-items: center;
    display: flex;
    padding-right: 10px;
    
    svg {
        margin-right: 3px;
    }
}

.row {
    display: flex;
    margin-bottom: 10px;
}

.MuiFormControlLabel-root {
    margin-left: -6px;
}

.MuiOutlinedInput-input {
    padding: 8px 10px;
    width: 100px;
}

.value {
    align-items: center;
    display: flex;
    padding-right: 12px;
    span {
        padding-right: 6px;
    }
}

`

export const StyledWrapper = styled.div`
background-color: #fff;

padding: 0 20px 20px 20px;

.btn-wrapper {
    margin-left: 20px;
}

`
