// @flow
import React from 'react'
// $FlowFixMe
import { DragDropContext } from 'react-beautiful-dnd'
import TableExportDragDropColumn from './TableExportDragDropColumn'
import TableExportDragDropMiddleArrow from './TableExportDragDropMiddleArrow'

import {
  TableExportDragDropStyle,
} from '../TableExportStyles'

// types
import type {
  TableExportDragDropType,
} from '../TableExportTypes'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)
  destClone.splice(droppableDestination.index, 0, removed)

  return [sourceClone, destClone]
}

const OUTPUT_COLUMN = 'output'
const REMOVED_COLUMN = 'removed'

const TableExportDragDrop = (props: TableExportDragDropType) => {
  const {
    outputArray,
    updateOutputArray,
    removedArray,
    updateRemovedArray,
  } = props

  const getColumnState = type => {
    switch (type) {
      case OUTPUT_COLUMN:
        return [outputArray, updateOutputArray]
      case REMOVED_COLUMN:
        return [removedArray, updateRemovedArray]
      default:
        throw new Error('Wrong type')
    }
  }

  const onDragEnd = result => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const [array, toUpd] = getColumnState(source.droppableId)

      toUpd(reorder(
        array,
        source.index,
        destination.index,
      ))
    } else {
      const [sourceArray, sourceToUpd] = getColumnState(source.droppableId)
      const [destArray, destToUpd] = getColumnState(destination.droppableId)

      const [sourceClone, destClone] = move(
        sourceArray,
        destArray,
        source,
        destination,
      )

      sourceToUpd(sourceClone)
      destToUpd(destClone)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TableExportDragDropStyle>
        <TableExportDragDropColumn
          fields={outputArray}
          droppableId={OUTPUT_COLUMN}
          title="Columns in export:"
        />

        <TableExportDragDropMiddleArrow
          outputArray={outputArray}
          updateOutputArray={updateOutputArray}
          removedArray={removedArray}
          updateRemovedArray={updateRemovedArray}
        />

        <TableExportDragDropColumn
          fields={removedArray}
          droppableId={REMOVED_COLUMN}
          title="Removed from export:"
        />
      </TableExportDragDropStyle>
    </DragDropContext>
  )
}

export default TableExportDragDrop
