// @flow
import styled from 'styled-components'

const handleButtonSize = (size: string) => {
  switch (size) {
    case 'small':
      return '80px'
    case 'medium':
      return '120px'
    case 'large':
      return '150px'
    default:
      return '100%'
  }
}

export const StyledOutlineButton = styled.button`
    appearance: none;
    align-items: center;
    background-color: transparent;
    border: 1px solid ${props => props.theme.main.colors.primaryColor};
    border-radius: 3px;
    color: ${props => props.theme.main.colors.primaryColor};
    cursor: pointer;
    display: inline-flex;
    font-family: ${props => props.theme.main.fonts.secondaryFontFamily};
    font-size: 16px;
    height: 40px;
    justify-content: center;
    letter-spacing: 0.6px;
    max-width: ${({ size }) => handleButtonSize(size)};
    padding: 12px 24px;
    text-decoration: none;
    width: 100%;

    &:hover {
        opacity: 0.8;
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }
`
