// @flow
import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import DoubleArrow from '@material-ui/icons/DoubleArrow'

import {
  TableExportDragDropMiddleArrowsStyle,
} from '../TableExportStyles'

// types
import type {
  TableExportDragDropMiddleArrowsType,
} from '../TableExportTypes'

const TableExportDragDropMiddleArrows = (props: TableExportDragDropMiddleArrowsType) => {
  const {
    outputArray,
    updateOutputArray,
    removedArray,
    updateRemovedArray,
  } = props

  return (
    <TableExportDragDropMiddleArrowsStyle>
      <IconButton
        color="primary"
        disabled={!removedArray.length}
        onClick={() => {
          updateOutputArray(outputArray.concat(removedArray))
          updateRemovedArray([])
        }}
      >
        <DoubleArrow fontSize="inherit" />
      </IconButton>

      <IconButton
        color="primary"
        disabled={!outputArray.length}
        onClick={() => {
          updateRemovedArray(removedArray.concat(outputArray))
          updateOutputArray([])
        }}
      >
        <DoubleArrow fontSize="inherit" />
      </IconButton>
    </TableExportDragDropMiddleArrowsStyle>
  )
}

export default TableExportDragDropMiddleArrows
