// @flow

import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'

import config from 'src/config/formsConfig'
import OrdersFilters from './OrdersFiltersForm'
import withOrders from 'src/decorators/withOrders'

const Form = reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  form: config.ordersFiltersForm.id,
})(OrdersFilters)

const mapStateToProps = state => {
  const formVals = getFormValues(config.ordersFiltersForm.id)(state) || {}
  return {
    formVals,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const {
    saveOrdersExportFilter,
    outputArray,
  } = props
  return {
    onSubmit: values => {
      saveOrdersExportFilter({ values, outputArray })
    },
  }
}

export default compose(
  withOrders,
  connect(mapStateToProps, mapDispatchToProps),
)(Form)

