
// @flow

export default {
  loginForm: {
    id: 'loginForm',
  },
  editUserForm: {
    id: 'editUserForm',
  },
  changeUserEmail: {
    id: 'editUserForm',
  },
  activatePasswordForm: {
    id: 'activatePasswordForm',
  },
  forgotPasswordForm: {
    id: 'forgotPasswordForm',
  },
  orderSettingsForm: {
    id: 'orderSettingsForm',
  },
  searchUserForm: {
    id: 'searchUserForm',
  },
  searchLeaguesForm: {
    id: 'searchLeaguesForm',
  },
  orderAccountForm: {
    id: 'orderAccountForm',
  },
  searchIncurancesForm: {
    id: 'searchIncurancesForm',
  },
  insuranceForm: {
    id: 'insuranceForm',
  },
  editLeagueForm: {
    id: 'editLeagueForm',
  },
  editLeagueOrderSettingsForm: {
    id: 'editLeagueOrderSettingsForm',
  },
  inviteUserForm: {
    id: 'inviteUserForm',
  },
  virtualTerminalForm: {
    id: 'virtualTerminalForm',
  },
  leaguesFinderForm: {
    id: 'leaguesFinderForm',
  },
  approveLeagueForm: {
    id: 'approveLeagueForm',
  },
  importSizesForm: {
    id: 'importSizesForm',
  },
  mainOrderForm: {
    id: 'mainOrderForm',
  },
  ordersSearchForm: {
    id: 'ordersSearchForm',
  },
  addressValidationForm: {
    id: 'addressValidationForm',
  },
  chooseTeamsForm: {
    id: 'chooseTeamsForm',
  },
  teamOrderItems: {
    dynamic: (id: string) => `teamOrderItems-${id}`,
    isTeamForm: (name: string) => name.indexOf('teamOrderItems-') === 0,
  },
  freeBallsItems: {
    id: 'freeBallsItems',
  },
  freeBeltsItems: {
    id: 'freeBeltsItems',
  },
  paidBallsItems: {
    id: 'paidBallsItems',
  },
  paidBeltsItems: {
    id: 'paidBeltsItems',
  },
  paidItems: {
    dynamic: (id: string) => `paidItems-${id}`,
  },
  addressesInfoForm: {
    id: 'addressesInfoForm',
  },
  shippingForm: {
    id: 'shippingForm',
  },
  manualOrderSubmit: {
    id: 'manualOrderSubmit',
  },
  checkReceived: {
    id: 'checkReceived',
  },
  seasonFreeShippingCountForm: {
    id: 'seasonFreeShippingCountForm',
  },
  tableExportForm: {
    id: 'tableExportForm',
  },
  searchCertificateForm: {
    id: 'searchCertificateForm',
  },
  editSeasonForm: {
    id: 'editSeasonForm',
  },
  ordersFiltersForm: {
    id: 'ordersFiltersForm',
  },
  payWithCheckForm: {
    id: 'payWithCheckForm',
  },
  ballsForm: {
    id: 'ballsForm',
  },
  additionalItemsForm: {
    id: 'additionalItemsForm',
  },
}
