import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledFormWrapper = styled.div`
  .absolute {
    position: absolute;
  }

  .form-error{
    font-size: 14px;
    color: #cc3b3b;
  }

  .form-blank {
    padding-top: 12px;
    margin-top: 6px;
    margin-bottom: 4px;
  }

  .form-row {
    display: flex;
    margin: 0 -15px 20px;

    &.without-margin {
      margin-bottom: 0;
    }

    &.zero-margin {
      margin: 0;
    }
  }

  .form-group {
    padding: 0 15px;
    width: 50%;

    .note {
      padding-left: 90px;
      font-size: 12px;
    }
    
    &.quarter-width {
      width: 25%;
    }

    &.css-row {
      .field-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        aligns-content: center;
        width: auto;
        flex-wrap: wrap;

        div {
          width: 100%;
        }

        .error-message {
          padding-left: 90px;
        }

        .title {
          display: inline-flex;
          margin-top: 5px;
        }

        input {
          width: auto;
          flex: 1;
          margin-left: 10px;
          margin-bottom: 0;
        }
      }
    }

    .form-link {
      text-decoration: underline;

      :hover {
        text-decoration: none;
      }
    }

    &.full-width {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
    }

    .btn-delete {
      align-items: center;
      appearance: none;
      background-color: transparent;
      border: 0;
      color: ${props => props.theme.main.colors.secondaryColor};
      display: flex;
      margin-left: auto;

      svg {
        height: 12px;
        margin-right: 4px;
        min-width: 12px;
        width: 12px;

        path {
          fill: ${props => props.theme.main.colors.secondaryColor};
        }
      }

      :hover {
        text-decoration: underline;
      }
    }
  }

  .checkbox-group {
    margin-bottom: 30px;

    &.methods-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      label.radio-label {
        display: flex;
        margin-bottom: 15px;
        width: 315px;
        padding: 15px;
        border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
      }
    }

    > label {
      display: block;
      margin-bottom: 14px;
    }
  }

  input,
  select {
    width: 100%;
  }

  .form-action {
    display: flex;
    justify-content: center;
  }

  .agreement-link {
    a {
      color: #3751a2;
      text-decoration: underline;
    }
  }

  .warn-message {
    color: ${props => props.theme.main.colors.errorColor};
  }

  @media ${device.tablet} {
    .absolute {
      position: relative;
    }
    .form-row {
      flex-direction: column;
      margin-bottom: 0;

      .form-row {
        flex-direction: row;
      }
    }

    .form-group {
      margin-bottom: 15px;
      width: 100%;

      .form-group {
        margin-bottom: 0;
      }
    }
  }

  @media ${device.mobileL} {
    .form-row {
      .form-row {
        flex-direction: column;
      }
    }

    .form-group {
      .form-group {
        margin-bottom: 15px;
      }
    }
  }
`
