// @flow
import React, { lazy } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { PrivateRoute } from 'src/common/components'

const DashboardManagement = lazy(() => import('src/modules/dashboardManagement'))

export default [
  <Route
    exact={true}
    path="/dashboard-management"
    render={() => <Redirect to="/dashboard-management/message-center"/>}
    key="dashboard-management"
  />,
  <PrivateRoute
    exact={true}
    path="/dashboard-management/message-center"
    component={DashboardManagement}
    key="dashboard-management-message-center"
  />,
  <PrivateRoute
    exact={true}
    path="/dashboard-management/resources"
    component={DashboardManagement}
    key="dashboard-management-resources"
  />,
]
