// @flow

import React, { useState } from 'react'
import { StyledRadioInput, StyledFormInput } from './orderShippingAddressRadioStyles'
import type { OrderShippingAddressRadioPropsType } from './OrderShippingAddressRadioTypes'
import classNames from 'classnames'

import TextField from '@material-ui/core/TextField'

import {
  Edit,
  Check,
  Close,
} from '@material-ui/icons'

const groupText = (...list) => list.filter(Boolean).join(', ')

const LabelComponent = address => {
  return (
    <>
      {address.county
        ? (
          <>
            <span>
              {groupText(address.city, address.state, address.zip)}
            </span>
            <span>
              {groupText(address.county, address.country_code)}
            </span>
          </>
        )
        : (
          <span>
            {groupText(address.city, address.state, address.zip, address.country_code)}
          </span>
        )}
    </>
  )
}

const FormInput = ({
  input,
  meta: { touched, error },
  validationErrors,
  defaultChecked,
  id,
  address,
  editable = false,
  changeShippingAddressFields,
  ...rest
}: OrderShippingAddressRadioPropsType) => {
  const [isEditing, updateEditFlag] = useState(false)

  const [formVals, updateFormVals] = useState({
    address_1: address.address_1,
    address_2: address.address_2 || '',
  })
  const handleChange = (event, prop: string) => {
    const newVal = event.target.value
    updateFormVals({
      ...formVals,
      [prop]: newVal,
    })
  }

  const handleCancel = () => {
    updateEditFlag(false)
    updateFormVals({
      address_1: address.address_1,
      address_2: address.address_2 || '',
    })
  }

  const handleSubmit = () => {
    if (!formVals.address_1) return
    changeShippingAddressFields(formVals, address)

    updateEditFlag(false)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit()
    } else if (event.key === 'Escape') {
      handleCancel()
    }
  }

  return (
    <>
      <StyledRadioInput className="radio-label">
        <div className="edit-btns">
          {editable && (isEditing
            ? (
              <>
                <Check
                  className={classNames({
                    success: true,
                    disabled: !formVals.address_1,
                  })}
                  onClick={handleSubmit}
                />
                <Close className="cancel" onClick={handleCancel}/>
              </>
            )
            : <Edit onClick={() => updateEditFlag(true)}/>
          )}
        </div>
        <StyledFormInput {...rest} {...input} />
        <div className="text-container">
          {editable && isEditing
            ? (
              <div className="address-fields">
                <TextField
                  label="Street address"
                  value={formVals.address_1}
                  onChange={ev => handleChange(ev, 'address_1')}
                  onKeyDown={handleKeyDown}
                  className="w100"
                />
                <TextField
                  label="Apt, suite, etc. (optional)"
                  value={formVals.address_2}
                  onChange={ev => handleChange(ev, 'address_2')}
                  onKeyDown={handleKeyDown}
                  className="w100"
                />
              </div>
            )
            : (
              <span>
                {groupText(address.address_1, address.address_2)}
              </span>
            )
          }
          {LabelComponent(address)}
        </div>
      </StyledRadioInput>
    </>
  )
}

export default FormInput
