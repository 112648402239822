import styled from 'styled-components'

export const StyledWrapper = styled.div`
    position: relative;
    min-height: ${props => props.fullHeight ? '100vh' : 'auto'};
    ${props => props.contentFit
  ? `
    min-height: inherit;
    height: inherit;
    `
  : ''}
    
    .btn-delete {
          align-items: center;
          appearance: none;
          background-color: transparent;
          border: 0;
          color: ${props => props.theme.main.colors.secondaryColor};
          display: flex;
          margin-left: 20px;
          
          svg {
            height: 12px;
            margin-right: 4px;
            min-width: 12px;
            width: 12px;
            
            path {
                fill: ${props => props.theme.main.colors.secondaryColor};
            }
        }
    }
`
