import styled from 'styled-components'

export const StyledBlock = styled.div`
    box-shadow: 3px 6px 40px 0 rgba(14,28,69,0.1);
    margin-bottom: 30px;

    .block-header {
        background-color: rgb(218, 229, 251);
        padding: 15px;
        
        .title {
            margin-bottom: 0;
        }
    }
    
    .block-body {
        padding: 15px;
    }
    
    .link-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      
        a {
            align-items: center;
            background-color: ${props => props.theme.main.colors.buttonPrimaryColor};
            border: 1px solid ${props => props.theme.main.colors.buttonPrimaryColor};
            color: ${props => props.theme.main.colors.primaryColor};
            border-radius: 3px;
            cursor: pointer;
            display: inline-flex;
            font-family: ${props => props.theme.main.fonts.secondaryFontFamily};
            font-size: 16px;
            height: 40px;
            justify-content: center;
            letter-spacing: 0.6px;
            max-width: 200px;
            padding: 12px 24px;
            text-decoration: none;
            width: 200px;
        
            &:hover {
                opacity: 0.8;
                text-decoration: none;
            }
            
            &:focus {
                outline: none;
            }
        }
    }

    .summary-action {
      display: flex;
      justify-content: center;
    }
`
