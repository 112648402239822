// @flow

import type { GetLeagueOrdersActionTypes } from 'src/redux/actions/orders/ordersTypes'
import {
  GET_LEAGUE_ORDERS,
  GET_ORDERS,
  GET_ORDERS_STATUSES,
  GET_SHIPPING_METHODS,
  GET_ORDERS_EXPORT_AVAILABLE_COLUMNS,
  GET_ORDERS_EXPORT_FILTERS,
  SET_ORDERS_EXPORT_FILTER,
  DELETE_ORDERS_EXPORT_FILTER,
} from 'src/redux/actions/orders/orders'

import type { OrderType } from 'src/common/constants.types'

export type OrderItem = OrderType;

export type OrdersData = {
    total: number,
    offset: number,
    limit: number,
    page: number,
    results: OrderItem[]
};

export type OrderColumType = {
  name: string,
  label: string,
  valueFormat?: (value: any, row: any) => any
};

export type OrderFilter = {
  id: number,
  name: string,
  fields: OrderColumType[]
};

export type OrdersState = {
    isLoading: boolean,
    isLoadingStatuses: boolean,
    isLoadingShippingMethods: boolean,
    ordersData: ?OrdersData,
    ordersStatuses: ?string[],
    shippingMethods: ?string[],
    ordersErrors?: ?{ [key: string]: any } | ?string,
    availableOrderColumns: OrderColumType[],
    ordersFilters: OrderFilter[],
    selectedOrdersFilter: ?OrderFilter
};

const initialState = {
  isLoading: false,
  isLoadingStatuses: false,
  isLoadingShippingMethods: false,
  ordersData: null,
  ordersStatuses: null,
  shippingMethods: null,
  ordersErrors: null,
  availableOrderColumns: [],
  ordersFilters: [],
  selectedOrdersFilter: null,
}

const orders = (state: OrdersState = initialState, action: GetLeagueOrdersActionTypes): OrdersState => {
  switch (action.type) {
    case GET_LEAGUE_ORDERS.REQUEST:
    case GET_ORDERS.REQUEST:
    case GET_ORDERS_EXPORT_AVAILABLE_COLUMNS.REQUEST:
    case GET_ORDERS_EXPORT_FILTERS.REQUEST:
    case DELETE_ORDERS_EXPORT_FILTER.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case GET_ORDERS_STATUSES.REQUEST:
    {
      return {
        ...state,
        isLoadingStatuses: true,
      }
    }

    case GET_SHIPPING_METHODS.REQUEST:
    {
      return {
        ...state,
        isLoadingShippingMethods: true,
      }
    }

    case GET_LEAGUE_ORDERS.SUCCESS:
    case GET_ORDERS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        ordersData: { ...action.payload },
      }
    }

    case GET_ORDERS_STATUSES.SUCCESS:
    {
      return {
        ...state,
        isLoadingStatuses: false,
        ordersStatuses: [...action.payload],
      }
    }

    case GET_SHIPPING_METHODS.SUCCESS:
    {
      return {
        ...state,
        isLoadingShippingMethods: false,
        shippingMethods: [...action.payload],
      }
    }

    case GET_ORDERS_EXPORT_AVAILABLE_COLUMNS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        availableOrderColumns: action.payload,
      }
    }

    case GET_ORDERS_EXPORT_FILTERS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        ordersFilters: action.payload,
      }
    }

    case DELETE_ORDERS_EXPORT_FILTER.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
      }
    }

    case SET_ORDERS_EXPORT_FILTER:
    {
      return {
        ...state,
        selectedOrdersFilter: state.ordersFilters.find(({ id }) => id === action.payload.id),
      }
    }

    case GET_LEAGUE_ORDERS.FAILURE:
    case GET_ORDERS.FAILURE:
    case GET_ORDERS_EXPORT_AVAILABLE_COLUMNS.FAILURE:
    case GET_ORDERS_EXPORT_FILTERS.FAILURE:
    case DELETE_ORDERS_EXPORT_FILTER.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        ordersErrors: action.payload,
      }
    }

    case GET_ORDERS_STATUSES.FAILURE:
    {
      return {
        ...state,
        isLoadingStatuses: false,
        ordersErrors: action.payload,
      }
    }

    case GET_SHIPPING_METHODS.FAILURE:
    {
      return {
        ...state,
        isLoadingShippingMethods: false,
        ordersErrors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default orders
