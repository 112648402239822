import http from 'src/services/http'
import { stringify } from 'query-string'

export const PAYMENT_TYPES = {
  INSURANCE: 'INSURANCE',
  BACKGROUND_CHECKS: 'BACKGROUND_CHECKS',
  ORDER: 'ORDER',
  VIRTUAL_TERMINAL: 'VIRTUAL_TERMINAL',
}
export const getPayments = async({
  q,
  paymentTypes,
  createdAtFrom,
  createdAtTo,
  limit = 50,
  offset = 0,
} = {}) => {
  const response = await http(`admin/payments?${stringify({
    q,
    limit,
    offset,
    paymentTypes,
    createdAtFrom,
    createdAtTo,
  }, {
    arrayFormat: 'bracket',
  })}`, {
    method: 'GET',
  })
  return response?.data
}

export const fetchCurrencyRate = async() => {
  const response = await http('globalSettings/currencyRate', {
    method: 'GET',
  })
  return response?.data
}
