// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_INSURANCE, getInsurance } from 'src/redux/actions/insurances/insurances'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetInsurance(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { insuranceId },
    } = yield take(GET_INSURANCE.REQUEST)

    try {
      const data = yield call(http, `admin/insurance/${insuranceId}`, {
        method: 'GET',
      })

      yield put(getInsurance.success(data.data))
    } catch (e) {
      yield put(getInsurance.error(e.message))
    }
  }
}

export default [fork(watchGetInsurance)]
