import { memoize } from 'lodash'

export function paymentStatus(status) {
  switch (status) {
    case 'PAID':
      return 'Paid'
    case 'PENDING':
      return 'Pending'
    case 'REFUNDED':
      return 'Refunded'
    case 'NOT_PAID':
      return 'Not Paid'
    default:
      return ''
  }
}

export function PaymentType(value) {
  const types = {
    CARD: 'Card',
    ACH: 'ACH',
    CHECK: 'Check',
    LO_CREDIT: 'Credit',
    DISCOUNT: 'Discount',
  }
  return types[value] || ''
}

export function capitalize(str) {
  if (typeof str !== 'string') {
    str = String(str || '')
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function decamelize(input, separator = '_') {
  if (typeof input !== 'string') {
    throw new TypeError('Expected a string')
  }
  const CAMEL_CASE_REGEXP = /[a-z][A-Z]/g
  return input.replace(CAMEL_CASE_REGEXP, function(letter, pos) {
    return (`${letter[0]}${separator}${letter[1]}`).toLowerCase()
  })
}

function humanizeFn(input) {
  input = decamelize(input)
  input = input.toLowerCase().replace(/[_-]+/g, ' ').replace(/\s{2,}/g, ' ').trim()

  const WORD_REGEX = /\b.+?\b/g
  return input.replace(WORD_REGEX, capitalize)
};

export const humanize = memoize(humanizeFn)
