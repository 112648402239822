// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { submitOrderToVendor, SUBMIT_ORDER_TO_VENDOR, getOrder } from '../actions/bulkOrder/bulkOrder'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSubmitOrderToVendor(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { order_id, sendInvoiceEmail },
    } = yield take(SUBMIT_ORDER_TO_VENDOR.REQUEST)
    try {
      yield call(http, `admin/order/${order_id}/vendor-submit`, {
        body: JSON.stringify({
          sendInvoiceEmail,
        }),
        method: 'POST',
      })

      yield put(submitOrderToVendor.success())
      yield put(getOrder.request({ order_id }))
    } catch (e) {
      yield put(submitOrderToVendor.error(e.message))
    }
  }
}

export default [fork(watchSubmitOrderToVendor)]
