// @flow
import React from 'react'
import {
  ErrorBlock,
  ButtonPrimary,
} from 'src/common/components'

import { FailedLeagueVendorUpdateBlockStyle } from './failedLeagueVendorUpdateBlockStyle'

import type { failedLeagueVendorUpdateBlockType } from './failedLeagueVendorUpdateBlockTypes'

const showProceedBtn = type => {
  return type === 'UNPAID_ORDERS'
}

const extraText = type => {
  return type === 'UNPAID_ORDERS'
    ? ' They will be deleted if you change the vendor. Do you want to proceed anyway?'
    : ''
}
// league_id
const failedLeagueVendorUpdateBlock = (props: failedLeagueVendorUpdateBlockType) => {
  const { error: { type, message, league_name }, forceVendorUpdate } = props

  return (
    <FailedLeagueVendorUpdateBlockStyle>
      {league_name && <h4>League: {league_name}</h4>}
      {<ErrorBlock error={message + extraText(type)}/>}
      {showProceedBtn(type) && <ButtonPrimary size="small" action={forceVendorUpdate} buttonText={'Proceed'} />}
    </FailedLeagueVendorUpdateBlockStyle>

  )
}

export default failedLeagueVendorUpdateBlock
