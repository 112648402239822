// @flow

import {
  stopSubmit,
  destroy,
  change,
} from 'redux-form'
import { call, fork, put, take, select } from 'redux-saga/effects'

import {
  pick,
  findLast,
  each,
  flatten,
} from 'lodash'
import config from 'src/config/formsConfig'
import {
  MAKE_ORDER_TEAMS_CHANGE,
  makeOrderTeamsChange,
  // removeTeamFromMakeOrder,
  CHECKOUT_LATEST_ITEMS_QTY,
  checkoutLatestItemsQty,
  updateMakeOrder,
  restoreOrderProgress,
  GET_ORDER_SHIPPING_METHODS,
  RESTORE_ORDER_PROGRESS,
  getShippingMethods,
  SEND_SHIPPING_ADDRESS,
  SHIPPING_ADDRESS_FIELDS_CHANGE,
  sendOrderShippingAddress,
  setAvailableAddresses,
  VERIFICATION_CHECK_BEFORE_PAYMENT,
  verificationCheckBeforePayment,
  SEND_MAKE_ORDER_FILE,
  sendMakeOrderFile,
  SEND_SHIPPING_METHOD,
  sendShippingMethod,
  resetMakeOrder,
  SUBMIT_MAIN_MAKE_ORDER,
  submitMainMakeOrder,
  submitOrderCheckPayment,
  SUBMIT_ORDER_CHECK_PAYMENT,
  UPDATE_NOTE,
  updateNote,
} from 'src/redux/actions/makeOrder/makeOrder'
import { SET_PAYMENT_PAYLOAD, setPaymentPayload, getPaymentToken, resetPaymentsError } from 'src/redux/actions/payments/payments'
import { ORDER_MODE, VENDOR, ITEM_TYPES } from 'src/common/constants'

import http from 'src/services/http'
import { history } from 'src/services/history'
import {
  STEPS,
  STEPS_ORDER,
  STEP_VALUE,
  canUserEditOrder,
  canAdminEditOrder,
  getNextStep,
  isMatrixTeamOrder,
  hasAccessToPage,
} from 'src/modules/order/utils'
import { hasRightForThisStep } from 'src/helpers/restoreOrderStateHelpers'
import {
  ITEMS_WITH_META_DATA,
  getIdFromInputName,
  findOnWhichStepsItemIs,
  getJerseysCredit,
} from 'src/helpers/shapeOrder'

import { showToast } from 'src/redux/actions/ui/ui'
import { validateActualData } from 'src/helpers/sagaHelper'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchChosenTeamsChange(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { formValues, order },
    } = yield take(MAKE_ORDER_TEAMS_CHANGE.REQUEST)

    const formId = config.chooseTeamsForm.id
    try {
      const { data: { freeRules, saleItems } } = yield call(http, 'admin/order/sale-items', {
        body: JSON.stringify({
          league_id: order.league_id,
          season_id: order.season_id,
          order_mode: order.order_mode,
          vendor: order.vendor,
          teams: Object.entries(formValues).filter(([id, isChecked]) => isChecked).map(([id]) => id),
        }),
        method: 'POST',
      }, { disableToastr: true })

      yield put(makeOrderTeamsChange.success({
        freeRules,
        saleItems,
      }))
      const { makeOrder } = yield select()

      const steps = STEPS_ORDER.filter(step => hasAccessToPage(step, makeOrder))

      yield call(history.push, getNextStep(STEPS.CHOOSE_TEAMS, makeOrder, steps))
    } catch (e) {
      console.error('e', e)
      yield put(makeOrderTeamsChange.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

function * watchCheckoutLatestQty(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload,
    } = yield take(CHECKOUT_LATEST_ITEMS_QTY.REQUEST)
    let { makeOrder } = payload || {}

    if (!makeOrder) {
      const store = yield select();
      ({ makeOrder } = store)
    }

    const { order } = makeOrder
    const teams = makeOrder.items.forms.map(f => f.team_id).filter(Boolean)

    try {
      const { data: { freeRules, saleItems } } = yield call(http, 'admin/order/sale-items', {
        body: JSON.stringify({
          league_id: order.league_id,
          season_id: order.season_id,
          order_mode: order.order_mode,
          vendor: order.vendor,
          teams,
        }),
        method: 'POST',
      }, {
        disableToastr: true,
      })

      yield put(checkoutLatestItemsQty.success({
        freeRules,
        saleItems,
      }))
    } catch (e) {
      console.error('e', e)
      yield put(makeOrderTeamsChange.error(e.message))
    }
  }
}

function shapeOrderItems(arr, formsState, { mapped, forms }) {
  const itemsWithMetaData = {}
  Object.entries(formsState).forEach(([form_name, form]) => {
    // $FlowFixMe
    Object.entries(form).forEach(([id, quantity]) => {
      const ID = getIdFromInputName(id)
      if (!(ID && +quantity)) return

      if (!mapped[ID]) {
        return
      }
      const { sku } = mapped[ID]

      if (mapped[ID].type === ITEM_TYPES.FOOTBALL || mapped[ID].type === ITEM_TYPES.PREMIUM_FOOTBALL) {
        return
      }

      if (ITEMS_WITH_META_DATA.includes(mapped[ID].type)) {
        const groupedItems = itemsWithMetaData[ID] || {
          id: ID,
          quantity: 0,
          sku,
          user_meta: {
            forms: {},
            teams: new Set(),
          },
        }
        groupedItems.quantity += +quantity
        groupedItems.user_meta.forms[form_name] = +quantity
        groupedItems.user_meta.teams.add(forms.find(f => f.form_name === form_name).team_id)
        itemsWithMetaData[ID] = groupedItems
        return
      }
      arr.push({
        id: ID,
        quantity,
        sku,
      })
    })
  })

  Object.values(itemsWithMetaData).forEach((item: any) => arr.push({
    ...item,
    user_meta: {
      ...item.user_meta,
      teams: Array.from(item.user_meta.teams),
    },
  }))
}

function prepareOrderForSubmit(makeOrder) {
  const {
    paidFormsState,
    freeFormsState,
    footballsState,
    items: { mapped, forms },
  } = makeOrder
  let paid = []
  let free = []

  shapeOrderItems(paid, paidFormsState, { mapped, forms })
  shapeOrderItems(free, freeFormsState, { mapped, forms })
  const discountedBelts = paid.filter(i => {
    const item = mapped[i.id]
    return item.type === ITEM_TYPES.PREMIUM_BELT
  })
  paid = paid.filter(i => {
    const item = mapped[i.id]
    return item.type !== ITEM_TYPES.PREMIUM_BELT
  })
  const discount = [...discountedBelts, ...(footballsState?.discounted || [])]

  paid = [...paid, ...footballsState.paid]
  free = [...free, ...footballsState.free]
  return {
    paid,
    free,
    discount,
  }
}

const hasInvalidForms = (forms, toValidate) => {
  if (toValidate) {
    forms = pick(forms, toValidate.map(f => f.form_name))
  }
  // $FlowFixMe
  return Object.values(forms).some(val => !val.isValid)
}

function isAllFormsValid(makeOrder, formsToValidate) {
  const {
    paidFormsState,
    freeFormsState,
  } = makeOrder
  return !(hasInvalidForms(paidFormsState, formsToValidate) || hasInvalidForms(freeFormsState, formsToValidate))
}

function * watchSubmitMainMakeOrder(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { match, makeOrder, validateFormsType, nextStep },
    } = yield take(SUBMIT_MAIN_MAKE_ORDER.REQUEST)

    const {
      order: orderData,
      items: { forms },
      jerseysCredit,
    } = makeOrder

    const credit = getJerseysCredit(makeOrder)

    let jerseysCreditChanged = false
    if (typeof jerseysCredit !== 'undefined' && jerseysCredit > credit) {
      jerseysCreditChanged = true
    }

    const isValidationOnly = STEP_VALUE[nextStep] < STEP_VALUE[STEPS.ADDRESS_INFO]

    let toastMessage

    if (!isAllFormsValid(makeOrder, isValidationOnly ? forms.filter(f => f.form_type === validateFormsType) : null)) {
      toastMessage = 'Some fields are not valid, please correct them before proceeding'
    }

    if (toastMessage) {
      yield put(showToast.success({ title: toastMessage, messageType: 'error' }))
      yield put(submitMainMakeOrder.error(toastMessage))
      continue
    }

    if (isValidationOnly) {
      yield put(submitMainMakeOrder.success({ jerseysCredit: credit, jerseysCreditChanged }))
      yield call(
        history.push,
        nextStep,
      )
      continue
    }

    const items = prepareOrderForSubmit(makeOrder)

    if (!items.paid.length && !items.free.length) {
      toastMessage = 'Please add at least 1 item to the order to proceed'
    }

    if (toastMessage) {
      yield put(showToast.success({ title: toastMessage, messageType: 'error' }))
      yield put(submitMainMakeOrder.error(toastMessage))
      continue
    }

    const order = pick(orderData, [
      'id', 'season_id', 'league_id', 'order_mode', 'vendor',
    ])

    try {
      const {
        data: responceOrder,
      } = yield call(http, 'admin/order2', {
        body: JSON.stringify({
          order,
          items,
        }),
        method: 'POST',
      }, { disableToastr: true })

      yield put(updateMakeOrder.success(responceOrder))

      yield put(submitMainMakeOrder.success())
      if (match.params.orderId) {
        yield put(restoreOrderProgress.request({
          matchParams: match.params,
        }))
      }

      yield call(
        history.push,
        `/order/league/${
          responceOrder.league_id
        }/season/${
          responceOrder.season_id
        }/order/${
          responceOrder.id
        }/step/${STEPS.ADDRESS_INFO}`, { disableToastr: true },
      )
    } catch (e) {
      if (e && e.message) {
        yield put(showToast.success({ title: e.message, messageType: 'error' }))
        yield put(submitMainMakeOrder.error(e.message))
      } else {
        yield put(submitMainMakeOrder.error())
      }
      yield put(checkoutLatestItemsQty.request({ makeOrder }))
    }
  }
}

function * watchRestoreOrderProgress(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { matchParams: { orderId, leagueId, step }, data = {} },
    } = yield take(RESTORE_ORDER_PROGRESS.REQUEST)
    let redirect_league_id = leagueId

    if (!data.inital) {
      const { makeOrder } = yield select()
      data.check_received = makeOrder.check_received
    }

    yield put(resetMakeOrder.success({
      isRestoringLoading: true,
      check_received: data.check_received || false,
    }))

    try {
      const { data } = yield call(http, `admin/order/${orderId}/state`, {
        method: 'GET',
      })

      const { order } = data
      const { league_id } = order

      if (!canUserEditOrder(order) && !canAdminEditOrder(order)) {
        redirect_league_id = league_id
        throw new Error('Forced Exit')
      }

      let permittedStep = findLast(STEPS_ORDER, step => hasRightForThisStep(data, step), STEPS_ORDER.indexOf(step))

      const items = findOverlyExceedItems(data)

      if (items.length) {
        const step = getEarliestStepFromItemList(items, order)
        permittedStep = STEP_VALUE[permittedStep] > STEP_VALUE[step]
          ? step
          : permittedStep
      }

      if (permittedStep !== step) {
        yield call(history.replace, permittedStep)
      }

      yield put(restoreOrderProgress.success(data))
    } catch (e) {
      yield put(restoreOrderProgress.error())
      yield call(history.replace, `/leagues-list/${redirect_league_id}/league-details/preview-league`)
    }
  }
}

function * watchSendMakeOrderAddress(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { values, order_id },
    } = yield take(SEND_SHIPPING_ADDRESS.REQUEST)
    const formId = config.addressesInfoForm.id

    values.address_validated = values.address_validated || false

    try {
      const { data } = yield call(http, 'admin/order-address', {
        body: JSON.stringify({
          ...values,
          order_id,
        }),
        method: 'POST',
      }, { disableToastr: true })

      yield put(sendOrderShippingAddress.success(values))
      yield put(updateMakeOrder.success(data))
      yield put(resetPaymentsError.success())
      yield call(history.push, STEPS.SHIPPING)
    } catch (e) {
      if (e && Array.isArray(e.alternatives)) {
        yield put(sendOrderShippingAddress.success(values))
        yield put(setAvailableAddresses.success(e.alternatives))
        yield call(history.push, STEPS.ADDRESS_VALIDATION)
      } else if (e && e.errors?.validation) {
        yield put(sendOrderShippingAddress.success(values))
        yield put(sendOrderShippingAddress.error([]))
        yield put(
          stopSubmit(formId, e.errors.validation),
        )
      } else if (e && e.response?.status === 428 && e.json?.message) {
        yield put(sendOrderShippingAddress.success(values))
        yield put(setAvailableAddresses.success([]))
        yield call(history.push, STEPS.ADDRESS_VALIDATION)
        // yield put(sendOrderShippingAddress.error([e.json.message]))
      } else {
        let errors = []
        if (e && e.errors) {
          each(e.errors, val => {
            if (typeof val === 'string') {
              errors.push(val)
            }
          })
        } else {
          errors = ['Network error. Please try again']
        }
        yield put(sendOrderShippingAddress.error(errors))
      }
    }
  }
}

function * watchShippingAddressFieldsChange(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { newVal, oldVal },
    } = yield take(SHIPPING_ADDRESS_FIELDS_CHANGE)

    const { makeOrder } = yield select()

    const addresses = makeOrder.avilableShippingAddresses

    let formValue
    const updatedList = addresses.map(addr => {
      if (addr !== oldVal) return addr
      const upd = {
        ...oldVal,
        ...newVal,
      }
      formValue = JSON.stringify(upd)
      return upd
    })

    yield put(change(config.addressValidationForm.id, 'shipment_address_data', formValue))

    yield put(setAvailableAddresses.success(updatedList))
  }
}

function * watchGetShippingMethods(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(GET_ORDER_SHIPPING_METHODS.REQUEST)

    try {
      const data = yield call(http, 'admin/order/available-shipment', {
        body: JSON.stringify({
          order_id: values.order_id,
        }),
        method: 'POST',
      }, { disableToastr: true })

      yield put(getShippingMethods.success(data.data))
    } catch (e) {
      yield put(getShippingMethods.error(e.message))
    }
  }
}

function * watchSendShippingMethod(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { order_id, shipping_service_id },
    } = yield take(SEND_SHIPPING_METHOD.REQUEST)
    const formId = config.shippingForm.id
    const { makeOrder } = yield select()

    try {
      const { data: order } = yield call(http, 'admin/order-shipment', {
        body: JSON.stringify({ order_id, shipping_service_id }),
        method: 'POST',
      }, { disableToastr: true })
      yield put(sendShippingMethod.success())
      yield put(updateMakeOrder.success(order))
      yield call(history.push, `${
        STEPS.ORDER_SUMMARY
      }?check_received=${
        makeOrder.check_received // BC of /leagues-list/:leagueId/league-details/orders/:orderId/payment
      }`)
    } catch (e) {
      const error = e && e.message ? { error: e.message } : { error: e }
      yield put(sendShippingMethod.error(e.message))
      yield put(stopSubmit(formId, error))
    }
  }
}

function findOverlyExceedItems({ items, saleItems, order: { order_mode } }) {
  if (order_mode === ORDER_MODE.TEAM) return []

  const skuSum = items.reduce((acc, cur) => {
    const qty = acc[cur.sku] || 0
    acc[cur.sku] = qty + cur.quantity
    return acc
  }, {})
  const skuItemsMap = saleItems.reduce((acc, cur) => ({
    ...acc,
    [cur.sku]: cur,
  }), {})

  return items.filter(item => {
    const qty = skuSum[item.sku]
    const saleItem = skuItemsMap[item.sku]
    // TODO: Remove hardcode later, because conflicts now
    return saleItem.inventory_limited && saleItem.max_quantity < qty && item.type !== 'BELT'
  })
}

function getEarliestStepFromItemList(items, order) {
  let step

  if (isMatrixTeamOrder(order)) {
    step = STEPS.ADDRESS_INFO
  } else {
    const steps = flatten(items.map(findOnWhichStepsItemIs))
    steps.sort((a, b) => STEP_VALUE[a] - STEP_VALUE[b]);
    [step] = steps
  }

  return step
}

function redirectUserFromPaymentPage(oldOrder, newState) {
  const newOrder = newState.order

  const items = findOverlyExceedItems(newState)

  if (items.length) {
    return {
      step: getEarliestStepFromItemList(items, newOrder),
      title: 'Allowed Quantity was changed, and you exceeding it now. Please adjust values',
    }
  }

  if (!newOrder.wasShippingSelected) {
    return {
      step: STEPS.SHIPPING,
      title: 'Please select shipping method',
    }
  }

  if (String(oldOrder.shipping_amount) !== String(newOrder.shipping_amount)) {
    return {
      step: STEPS.SHIPPING,
      title: 'Shipping Cost was changed, take a look',
    }
  }

  if (oldOrder.total_amount !== newOrder.total_amount) {
    return {
      step: STEPS.ORDER_SUMMARY,
      title: 'Order Total was changed, take a look',
    }
  }
}

function * watchVerificationCheckBeforePayment(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { order },
    } = yield take(VERIFICATION_CHECK_BEFORE_PAYMENT.REQUEST)

    try {
      const { data } = yield call(http, `admin/order/${order.id}/state`, {
        method: 'GET',
      })

      const error = redirectUserFromPaymentPage(order, data)
      yield put(restoreOrderProgress.success(data))

      if (error) {
        yield put(showToast.success({ title: error.title, messageType: 'warning' }))
        yield call(history.replace, error.step)
      }
    } catch (e) {
      yield put(restoreOrderProgress.error())
      yield call(history.replace, `/leagues-list/${order.league_id}/league-details/preview-league`)
    }
    yield put(verificationCheckBeforePayment.success())
  }
}

function * watchSetPaymentPayload(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: {
        payload: { nonce, token, storeInVaultOnSuccess, paymentType },
        order_id,
        credit_amount,
        order_amount,
      },
    } = yield take(SET_PAYMENT_PAYLOAD.REQUEST)
    try {
      yield call(validateActualData, { order_amount, order_id })

      yield http('admin/order/payment', {
        body: JSON.stringify({
          ...(nonce ? { nonceFromTheClient: nonce } : { paymentMethodToken: token }),
          order_id: +order_id,
          credit_amount,
          payment_type: paymentType,
          storeInVaultOnSuccess,
        }),
        method: 'POST',
      })

      yield put(setPaymentPayload.success())
      const thxPath = history.location.pathname.replace(
        `step/${STEPS.PAYMENT}`,
        'thank-you',
      )
      yield call(history.replace, thxPath)
      yield put(resetMakeOrder.success())
    } catch (e) {
      yield put(setPaymentPayload.error(e.message || e))
      yield put(getPaymentToken.request())
    }
  }
}

function * watchSendMakeOrderFile(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { file, order },
    } = yield take(SEND_MAKE_ORDER_FILE.REQUEST)
    const formId = config.importSizesForm.id
    const mainOrderFormId = config.mainOrderForm.id

    const formData = new FormData()
    formData.append('file', file)
    formData.append('league_id', order.league_id)
    formData.append('vendor', order.vendor)

    try {
      const { data } = yield call(http, 'admin/order/available-quantity-excel', {
        body: formData,
        method: 'POST',
        headers: {
          'Content-Disposition': `attachment; filename=${file.name}`,
          'Content-Length': file.length,
        },
        withoutContentType: true,
      })

      const { makeOrder, myLeagues: { leagueData: { default_vendor } } } = yield select()
      const isMatrixWithFlowAugusta = (default_vendor === VENDOR.MATRIX && makeOrder?.order.vendor === VENDOR.AUGUSTA)
      yield put(sendMakeOrderFile.success({ ...data, isMatrixWithFlowAugusta }))
      yield put(destroy(mainOrderFormId))

      const step = STEPS.UNIFORM

      yield call(history.push, step)
    } catch (e) {
      yield put(sendMakeOrderFile.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

function * watchSubmitOrderCheckPayment(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { order_id, credit_amount, order_amount, emails } } = yield take(SUBMIT_ORDER_CHECK_PAYMENT.REQUEST)

    try {
      yield call(validateActualData, { order_amount, order_id })

      const { data } = yield call(http, 'admin/order/check-payment', {
        method: 'POST',
        body: JSON.stringify({
          order_id,
          credit_amount,
          emails,
        }),
      }, { disableToastr: true })

      yield put(submitOrderCheckPayment.success())

      const thxPath = history.location.pathname.replace(
        `step/${STEPS.PAYMENT}`,
        `thank-you?check=true&submitted_to_vendor=${data.submitted_to_vendor}`,
      )
      yield call(history.replace, thxPath)
    } catch (e) {
      yield put(submitOrderCheckPayment.error(e.message))
    }
  }
}

function * watchUpdateNote(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { order_id, note } } = yield take(UPDATE_NOTE.REQUEST)

    try {
      yield call(http, 'admin/order/note', {
        method: 'POST',
        body: JSON.stringify({
          order_id,
          note,
        }),
      }, { disableToastr: true })
      yield put(updateNote.success())

      const { data } = yield call(http, `admin/order/${order_id}/state`, {
        method: 'GET',
      })

      yield put(restoreOrderProgress.success(data))
    } catch (e) {
      yield put(updateNote.error(e.message))
    }
  }
}

export default [
  fork(watchChosenTeamsChange),
  fork(watchCheckoutLatestQty),
  fork(watchRestoreOrderProgress),
  fork(watchGetShippingMethods),
  fork(watchSendMakeOrderAddress),
  fork(watchShippingAddressFieldsChange),
  fork(watchSendMakeOrderFile),
  fork(watchSendShippingMethod),
  fork(watchSetPaymentPayload),
  fork(watchVerificationCheckBeforePayment),
  fork(watchSubmitMainMakeOrder),
  fork(watchSubmitOrderCheckPayment),
  fork(watchUpdateNote),
]
