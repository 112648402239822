// @flow

import { requestTypeCreator } from 'src/helpers'

import type {
  GetOrderRefundsActionTypes,
} from './refundsTypes'

export const GET_ORDER_REFUNDS = requestTypeCreator('GET_ORDER_REFUNDS')

export const getOrderRefunds = {
  error: (payload: any): GetOrderRefundsActionTypes => ({ type: GET_ORDER_REFUNDS.FAILURE, payload }),
  request: (payload: any): GetOrderRefundsActionTypes => ({ type: GET_ORDER_REFUNDS.REQUEST, payload }),
  success: (payload: any): GetOrderRefundsActionTypes => ({ type: GET_ORDER_REFUNDS.SUCCESS, payload }),
}
