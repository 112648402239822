// @flow
import styled from 'styled-components'

const handleBorderColor = (status: string) => {
  switch (status) {
    case 'pending':
      return '#ffb74d'
    case 'declined':
      return '#f44336'
    case 'approved':
      return '#4caf50'
    default:
      return '#4caf50'
  }
}

export const StyledInsurance = styled.div`
    .insurance-layout {
        border: 1px solid #ebedef;
        border-left: 5px solid ${({ status }) => handleBorderColor(status)};
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        
        :hover {
            text-decoration: none;
        }
    }
    .status {
        margin-left: 20px;
        margin-top: 2px;
        color: ${({ status }) => handleBorderColor(status)};
    }
    
    
    :hover {
        background-color: #F7F8FA;
    }
    
    .insurance-left {
        padding: 10px 15px;
    }
    
    .heading {
        align-items: center;
        display: flex;
        margin-bottom: 8px;
        
        h4  {
            margin-bottom: 0;
            text-align: left;
        }
    }
    
    .file-name {
        align-items: center;
        display: flex;
        font-size: 17px;
        margin-bottom: 10px;
        margin-left: -6px;
        text-decoration: underline;
        word-break: break-word;
        
        :hover {
            text-decoration: none;
        }
        
        svg {
            margin-right: 4px;
        }
    }
    
    .dates {
        display: flex;
    }
    
    .date {
        align-items: center;
        display: flex;
        padding-right: 8px; 
        margin-bottom: 10px;
        
        .value {
            padding-left: 4px;
        }
        
        .capitalize {
            text-transform: capitalize;
        }
        
        .label {
            font-weight: 300;
            opacity: 0.8;
        }
    }
    
    .error-block {
        align-items: center;
        display: flex;
        font-size: 17px;
        margin-bottom: 10px;
        margin-left: -6px;
        word-break: break-word;
        
        svg {
            margin-right: 4px;
            color: orange;
        }
    }

    .info-wrapper {
        p {
            margin: 0 0 0 20px;
        }
    }
`
