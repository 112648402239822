// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_PAYMENT_METHODS, getPaymentMethods } from 'src/redux/actions/payments/payments'
import { getPaymentMethods as fetchPaymentMethods, getOrderPaymentMethods as fetchOrderPaymentMethods } from 'src/services/backgroundChecks'

// types
import type { IOEffect } from 'redux-saga/effects'

// $FlowFixMe
export const shapePaymentMethods = data => {
  return data.reduce((acc, cur) => {
    if (cur.cardType) {
      return {
        ...acc,
        cards: [...acc.cards, cur],
      }
    } else {
      return {
        ...acc,
        ACH: [...acc.ACH, cur],
      }
    }
  }, { cards: [], ACH: [] })
}

function * watchGetPaymentMethods(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { orderId, userId } } = yield take(GET_PAYMENT_METHODS.REQUEST)
    try {
      const data = orderId
        ? yield call(fetchOrderPaymentMethods, orderId)
        : yield call(fetchPaymentMethods, userId)

      yield put(getPaymentMethods.success(data))
    } catch (e) {
      yield put(getPaymentMethods.error(e.message))
    }
  }
}

export default [fork(watchGetPaymentMethods)]
