// @flow
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { reducer as formReducer } from 'redux-form'
import { persistReducer } from 'redux-persist'
import user from './user'
import accounts from './accounts'
import adminAccounts from './adminAccounts'
import leagues from './leagues'
import ui from './ui'
import insurances from './insurances'
import orderSettings from './orderSettings'
import addresses from './addresses'
import orders from './orders'
import members from './members'
import payments from './payments'
import refunds from './refunds'
import bulkOrder from './bulkOrder'
import makeOrder from './makeOrder'
import publicLeagues from './publicLeagues'
import NFLTeams from './NFLTeams'
import globalSettings from './globalSettings'
import seasons from './seasons'
import messageCenter from './messageCenter'
import resources from './resources'
import featureManagement from './featureManagement'
import exemptionCertificates from './exemptionCertificates'

const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['userErrors', 'loginErrors', 'passwordErrors', 'isLoading'],
}

const makeOrderPersistConfig = {
  key: 'makeOrder',
  storage,
  blacklist: ['isLoading', 'makeOrderErrors', 'lastTouchedTeamForm', 'lastTouchedTeamFormField', 'hasOrderSubmitFailed'],
}

const reducers = {
  form: formReducer,
  user: persistReducer(userPersistConfig, user),
  accounts,
  adminAccounts,
  leagues,
  ui,
  insurances,
  orderSettings,
  orders,
  addresses,
  members,
  payments,
  refunds,
  bulkOrder,
  makeOrder: persistReducer(makeOrderPersistConfig, makeOrder),
  publicLeagues,
  NFLTeams,
  seasons,
  globalSettings,
  messageCenter,
  resources,
  featureManagement,
  exemptionCertificates,
}

export default combineReducers(reducers)
