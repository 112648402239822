// @flow

import React from 'react'
import PaymentService from 'src/services/payment'
import {
  Loader,
  ButtonPrimary,
  ErrorBlock,
} from 'src/common/components'

// types
import type { PaymentCardType } from './PaymentCardTypes'

class PaymentCard extends React.Component<PaymentCardType> {
  paymentService = new PaymentService()

  componentDidMount() {
    this.props.setPaymentPaylodError(null)
    this.props.getPaymentToken()
  }

  async componentDidUpdate(prevProps: PaymentCardType) {
    if (this.props.payments.paymentToken !== prevProps.payments.paymentToken) {
      const button = document.getElementById('dropin-container')
      this.props.showPaymnentLoader(true)
      try {
        if (prevProps.payments.paymentToken) {
          await this.paymentService.refreshInstance(this.props.payments.paymentToken, button)
        } else {
          await this.paymentService.createInstance(this.props.payments.paymentToken, button)
        }
      } catch (e) {
        this.props.setPaymentPaylodError(e.message)
      } finally {
        this.props.showPaymnentLoader(false)
      }
    }
  }

  onSubmit = async() => {
    try {
      const payload = this.paymentService.getInstance() && await this.paymentService.getInstance().requestPaymentMethod()
      this.props.onSubmit(payload)
    } catch (e) {
      this.props.setPaymentPaylodError(e.message)
    }
  }

  render() {
    const {
      payments: { isLoading, paymentsErrors },
    } = this.props
    return (
      <div>
        <Loader show={isLoading}/>
        <div id="dropin-container"></div>
        {paymentsErrors && <ErrorBlock error={paymentsErrors}/>}
        <div className="d-flex justify-content-center">
          <ButtonPrimary classname size="medium" buttonText="Submit Payment" action={this.onSubmit}/>
        </div>
      </div>
    )
  }
}

export default PaymentCard
