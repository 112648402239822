import http from 'src/services/http'

export const fetchExemptionCertificates = async params => {
  const response = await http('admin/exemptionCertificates', {
    method: 'GET',
    params,
  })
  return response?.data
}

export const fetchExemptionCertificateAttachment = async certificateId => {
  const response = await http(`admin/exemptionCertificates/${certificateId}/attachment`, {
    method: 'GET',
  }, {
    responseIsFile: true,
  })
  return response
}

export const fetchExemptionZones = async() => {
  const response = await http('admin/exposureZones', {
    method: 'GET',
  })
  return response?.data
}
