// @flow

import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { StyledError } from 'src/common/components/checkbox/checkboxStyles'
import { getBoolean } from 'src/helpers/formatHelpers'

// types
import type { FormCheckboxPropsType } from './FormCheckboxTypes'

const FormCheckbox = ({
  input,
  label,
  disabled,
  meta: { touched, error },
}: FormCheckboxPropsType) => {
  let errorBlock = null
  if (touched && error) {
    errorBlock = (
      <div>
        <StyledError className="error-message">{error}</StyledError>
      </div>
    )
  }
  return (
    <>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            checked={getBoolean(input.value)}
            onChange={input.onChange}
            color="primary"
          />
        }
        label={label}
      />
      {errorBlock}
    </>
  )
}

export default FormCheckbox
