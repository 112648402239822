// @flow
import styled from 'styled-components'

export const StyledFileButton = styled.div`
    max-width: 300px;
    position: relative;
  
    &:hover {
        opacity: 0.8;
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }
    
    ::-webkit-file-upload-button {
      visibility: hidden;
    }
    
    ::before {
        align-items: center;
        background-color: ${props => props.theme.main.colors.buttonPrimaryColor};
        border: 1px solid ${props => props.theme.main.colors.buttonPrimaryColor};
        border-radius: 3px;
        color: ${props => props.theme.main.colors.primaryColor};
        content: "${props => props.buttonText}";
        cursor: pointer;
        display: inline-flex;
        font-family: ${props => props.theme.main.fonts.secondaryFontFamily};
        font-size: 16px;
        height: 40px;
        justify-content: center;
        letter-spacing: 0.6px;
        padding: 14px 24px 12px;
        text-decoration: none;
        width: 100%;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
    }
  
    input {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 0;
    }
`

export const StyledPrimaryButton = styled.input`
    &:hover {
        opacity: 0.8;
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }
    
    ::-webkit-file-upload-button {
      visibility: hidden;
    }
    
    ::before {
        align-items: center;
        background-color: ${props => props.theme.main.colors.buttonPrimaryColor};
        border: 1px solid ${props => props.theme.main.colors.buttonPrimaryColor};
        border-radius: 3px;
        color: ${props => props.theme.main.colors.primaryColor};
        content: "${props => props.buttonText}";
        cursor: pointer;
        display: inline-flex;
        font-family: ${props => props.theme.main.fonts.secondaryFontFamily};
        font-size: 16px;
        height: 40px;
        justify-content: center;
        letter-spacing: 0.6px;
        padding: 14px 24px 12px;
        text-decoration: none;
        width: 100%;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
    }
`
