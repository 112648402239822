// @flow
import React, { Component } from 'react'
import { formValueSelector } from 'redux-form'

import {
  ButtonPrimary,
} from 'src/common/components'

// styles
import { StyledOrderItemPricesWrapper } from './orderItemPricesStyles'

// types
import type { OrderItemPricesWrapperPropsTypes } from './OrderItemPricesTypes'

import { connect } from 'react-redux'
import { compose } from 'redux'

class OrderItemPricesWrapper extends Component<OrderItemPricesWrapperPropsTypes> {
  render() {
    return (
      <StyledOrderItemPricesWrapper>
        <h4>Order Prices:</h4>
        {this.props.children}
        <div className="btn-wrapper">
          {this.props.action1Callback && (
            <ButtonPrimary
              disabled={this.props.disable}
              size="medium"
              action={() => this.props.action1Callback(this.props.formVals)}
              buttonText={this.props.action1Text}
            />
          )}
          {this.props.action2Callback && (
            <ButtonPrimary
              disabled={this.props.disable}
              size="medium"
              action={() => this.props.action2Callback(this.props.formVals)}
              buttonText={this.props.action2Text}
            />
          )}
        </div>
      </StyledOrderItemPricesWrapper>
    )
  }
}

export default compose(
  connect((state, props) => {
    const { formFields } = props

    const selectors = formFields.map(({ key }) => key)

    return {
      formVals: formValueSelector(props.formName)(state, ...selectors),
    }
  }),
)(OrderItemPricesWrapper)
