import styled from 'styled-components'

export const StyledHeader = styled.div`
    .header-content {
        background-color: ${props => props.theme.main.colors.backgroundLight};
        color: #424242;
        display: flex;
        justify-content: flex-end;
    }
    
    .header {
        box-shadow: none;
        transform: none;
    }
`
