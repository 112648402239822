// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_ACCOUNT_CHANGES, getAccountChanges } from 'src/redux/actions/accounts/accounts'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetAccountChanges(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(GET_ACCOUNT_CHANGES.REQUEST)
    try {
      const { data } = yield call(http, `admin/account-changes/${payload}`, {
        method: 'GET',
      })

      yield put(getAccountChanges.success(data))
    } catch (e) {
      yield put(getAccountChanges.error(e.message))
    }
  }
}

export default [fork(watchGetAccountChanges)]
