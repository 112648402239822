// @flow
import type { PaymentsActionTypes } from 'src/redux/actions/payments/paymentsTypes'
import {
  GET_PAYMENT_TOKEN,
  SET_PAYMENT_PAYLOAD,
  SHOW_PAYMENT_LOADER,
  SUBMIT_VIRTUAL_PAYMENT,
  SUBMIT_PENDING_ORDER_CARD_PAYMENT,
  GET_PAYMENT_METHODS,
  RESET_PAYMENTS_ERROR,
  GET_CURRENCY_RATE,
} from 'src/redux/actions/payments/payments'

export type PaymentsDataItem = any; // todo add type
export type PaymentPayload = any; // todo add type
export type PaymentsData = PaymentsDataItem[];

export type UsBancAccount = {
  token: string,
  last4: string,
  accountType: string,
  accountHolderName: string,
  bankName: string,
  routingNumber: string,
  verified: boolean,
  ownershipType: string
};

export type Verification = {
  status: string,
  gatewayRejectionReason: string | null,
  merchantAccountId: string,
  processorResponseCode: string,
  processorResponseText: string,
  id: string,
  verificationMethod: string,
  verificationDeterminedAt: string,
  createdAt: string,
  updatedAt: string,
  globalId: string,
  graphQLId: string,
  usBancAccount: UsBancAccount
};

export type ACHMethod = {
  accountHolderName: string,
  accountNumber: string,
  accountType: string,
  achMandate: {
    acceptedAt: string,
    text: string
  },
  bankName: string,
  businessName: string | null,
  createdAt: string,
  customerGlobalId: string,
  customerId: string,
  default: boolean,
  firstName: string | null,
  globalId: string,
  graphQLId: string,
  imageUrl: string,
  last4: string,
  lastName: string | null,
  ownershipType: string,
  routingNumber: string,
  token: string,
  updatedAt: string,
  vaultedInBlue: boolean,
  verifications: Verification[],
  verified: boolean,
  verifiedBy: string | null
};

export type VerificationCardType = {
  status: string,
  cvvResponseCode: string,
  avsErrorResponseCode: null | string,
  avsPostalCodeResponseCode: string,
  avsStreetAddressResponseCode: string
};

export type CreditCardType = {
  billingAddress: {
    id: string,
    customerId: string,
    firstName: null | string,
    lastName: null | string,
    company: null | string
  },
  bin: string,
  cardType: string,
  cardholderName: null | string,
  commercial: string,
  countryOfIssuance: string,
  createdAt: string,
  customerGlobalId: string,
  customerId: string,
  customerLocation: string,
  debit: string,
  default: boolean,
  durbinRegulated: string,
  expirationDate: string,
  expirationMonth: string,
  expirationYear: string,
  expired: boolean,
  globalId: string,
  graphQLId: string,
  healthcare: string,
  imageUrl: string,
  isNetworkTokenized: boolean,
  issuingBank: string,
  last4: string,
  maskedNumber: string,
  payroll: string,
  prepaid: string,
  productId: string,
  subscriptions: [],
  token: string,
  uniqueNumberIdentifier: string,
  updatedAt: string,
  venmoSdk: false,
  verification: VerificationCardType,
  verifications: VerificationCardType[]
};

export type PaymentsState = {
    isLoading: boolean,
    paymentsData: PaymentsData,
    paymentsErrors?: ?{ [key: string]: any } | ?string,
    paymentToken: ?string,
    paymentPayload: PaymentPayload,
    ACHInstance: any,
    paymentMethods: ?{
      cards: CreditCardType[],
      ACH: ACHMethod[]
    },
    selectedPaymentMethod: ?ACHMethod,
    currencyRate: ?number
};

const initialState = {
  isLoading: false,
  paymentsData: [],
  paymentsErrors: null,
  paymentToken: null,
  paymentPayload: null,
  paymentMethods: undefined,
  selectedPaymentMethod: undefined,
  ACHInstance: null,
  currencyRate: undefined,
}

const payments = (state: PaymentsState = initialState, action: PaymentsActionTypes): PaymentsState => {
  switch (action.type) {
    case GET_PAYMENT_TOKEN.REQUEST:
    case SET_PAYMENT_PAYLOAD.REQUEST:
    case SUBMIT_VIRTUAL_PAYMENT.REQUEST:
    case SUBMIT_PENDING_ORDER_CARD_PAYMENT.REQUEST:
    case GET_PAYMENT_METHODS.REQUEST:
    case GET_CURRENCY_RATE.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case GET_PAYMENT_TOKEN.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        paymentToken: action.payload,
      })
    }

    case GET_PAYMENT_METHODS.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        paymentMethods: action.payload,
      })
    }

    case GET_CURRENCY_RATE.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        currencyRate: action.payload,
      })
    }

    case SHOW_PAYMENT_LOADER:
    {
      return ({
        ...state,
        isLoading: action.payload,
      })
    }

    case RESET_PAYMENTS_ERROR:
    {
      return ({
        ...state,
        paymentsErrors: null,
      })
    }

    case SET_PAYMENT_PAYLOAD.SUCCESS:
    case SUBMIT_VIRTUAL_PAYMENT.SUCCESS:
    case SUBMIT_PENDING_ORDER_CARD_PAYMENT.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        paymentPayload: { ...action.payload },
      })
    }

    case GET_PAYMENT_TOKEN.FAILURE:
    case SET_PAYMENT_PAYLOAD.FAILURE:
    case SUBMIT_VIRTUAL_PAYMENT.FAILURE:
    case SUBMIT_PENDING_ORDER_CARD_PAYMENT.FAILURE:
    case GET_PAYMENT_METHODS.FAILURE:
    case GET_CURRENCY_RATE.FAILURE:
    {
      return { ...state, isLoading: false, paymentsErrors: action.payload }
    }
    default: {
      return state
    }
  }
}

export default payments
