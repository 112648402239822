import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledForm = styled.form`
    .form-body {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px; 
    }
    
    .form-column {
        padding: 0 20px;
        width: 50%;
        
        .title {
            font-size: 24px;
            margin-bottom: 16px;
        }
    }

    
    .form-action {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
    
    .list-item {
        margin-bottom: 10px;
    }
    
    .input-file-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .import-button {
        margin-right: 10px
    }
    
    .download-link {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      text-decoration: underline;
    }
    
    @media ${device.laptop} {
    
      .link-wrapper {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          
          input {
            max-width: 300px;
          }
          
          .download-link {
            order: 2;
            display: flex;
            justify-content: center;
            text-decoration: underline;
          }
        }
    }
    
    @media ${device.tabletL} {
        .list-item {
            width: 50%;
        }
        
    }
    
    @media ${device.tablet} {
        .form-body {
            flex-direction: column;
        }
        
        .form-column {
            text-align: center;
            width: 100%;
        }
        
        .input-file-wrapper {
            justify-content: center;
            margin-top: 20px;
        }
        
    }
    
    @media ${device.mobileL} {
        .list-item {
            width: 100%;
        }
    }
`

export const StyledBlock = styled.div`
    padding: 10px 0;
    
    .title {
        margin-bottom: 35px;
    }
`
