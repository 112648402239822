import http from 'src/services/http'

export const fetchDownloadOrderInvoicePDF = async id => {
  const response = await http(`admin/invoice/order/${id}/pdf`, {
    method: 'GET',
  }, {
    responseIsFile: true,
  })
  return response
}
