// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_ORDER_REFUNDS, getOrderRefunds } from '../actions/refunds/refunds'
import http from 'src/services/http'
import REFUNDS from 'src/config/refunds'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetOrderRefunds(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { orderId },
    } = yield take(GET_ORDER_REFUNDS.REQUEST)

    try {
      let { data } = yield call(http, `admin/order/${orderId}/refunds`, {
        method: 'GET',
      })
      const totalRefund = countTotalRefund(data)
      data = { totalRefund, refunds: data }

      yield put(getOrderRefunds.success(data))
    } catch (e) {
      yield put(getOrderRefunds.error(e.message))
    }
  }
}

function countTotalRefund(refunds: any): number {
  let sum: number = 0
  refunds.forEach(refund => {
    if (REFUNDS.SUCCESS_STATUSES.includes(refund.status)) {
      sum += +refund.amount
    }
  })
  return sum
}

export default [fork(watchGetOrderRefunds)]
