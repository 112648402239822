import styled from 'styled-components'

export const StyledLink = styled.div`
    width: 100%;

    .sidebar-link {
        align-items: center;
        background-color: ${props => (props.isCurrent ? '#53348d' : 'transparent')};
        border-left: 3px solid ${props => (props.isCurrent ? props.theme.main.colors.accentColor : props.theme.main.colors.primaryColor)};
        color: ${props => props.theme.main.colors.inverseColor};
        cursor: ${props => (props.isCurrent ? 'default' : 'cursor')};
        display: flex;
        font-size: 14px;
        font-weight: 300;
        padding: 12px 24px;
        pointer-event: ${props => (props.isCurrent ? 'none' : 'cursor')};
        width: 100%;
        
        :hover {
            color: ${props => props.theme.main.colors.accentColor};
            color: ${props => (props.isCurrent ? props.theme.main.colors.inverseColor : props.theme.main.colors.accentColor)};
            text-decoration: none;
        }
        
        :focus {
            outline: none;
        }
    }
    
    .icon-wrapper {
        margin-right: 15px;
    }
    
    .link {
        align-items: center;
    }
`
