// @flow

import { requestTypeCreator } from 'src/helpers'

import type { BasicRedux } from './adminAccountsTypes'

export const GET_ALL_ADMINS = requestTypeCreator('GET_ALL_ADMINS')
export const GET_ADMIN = requestTypeCreator('GET_ADMIN')
export const DISABLE_ADMIN = requestTypeCreator('DISABLE_ADMIN')
export const ENABLE_ADMIN = requestTypeCreator('ENABLE_ADMIN')
export const CREATE_ADMIN = requestTypeCreator('CREATE_ADMIN')

export const getAllAdmins = {
  error: (payload: any): BasicRedux => ({ type: GET_ALL_ADMINS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_ALL_ADMINS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_ALL_ADMINS.SUCCESS, payload }),
}

export const getAdmin = {
  error: (payload: any): BasicRedux => ({ type: GET_ADMIN.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_ADMIN.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_ADMIN.SUCCESS, payload }),
}

export const disableAdmin = {
  error: (payload: any): BasicRedux => ({ type: DISABLE_ADMIN.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: DISABLE_ADMIN.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: DISABLE_ADMIN.SUCCESS, payload }),
}

export const enableAdmin = {
  error: (payload: any): BasicRedux => ({ type: ENABLE_ADMIN.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: ENABLE_ADMIN.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: ENABLE_ADMIN.SUCCESS, payload }),
}

export const createAdmin = {
  error: (payload: any): BasicRedux => ({ type: CREATE_ADMIN.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: CREATE_ADMIN.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: CREATE_ADMIN.SUCCESS, payload }),
}
