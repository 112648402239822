// @flow

import { stopSubmit, stopAsyncValidation } from 'redux-form'
import { fork, put, take } from 'redux-saga/effects'
import config from 'src/config/formsConfig'
import { EDIT_USER, editUser } from 'src/redux/actions/user/user'
import http from 'src/services/http'
import { shapeRolesOnFormSubmit } from 'src/helpers/shapeRoles'
// types
import type { IOEffect } from 'redux-saga/effects'
import { setToken } from '../../services/user'

function * watchEditUser(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { redirectCb, ...values },
    } = yield take(EDIT_USER.REQUEST)
    const formId = config.editUserForm.id
    try {
      const { data: { token } } = yield http('admin/profile', {
        body: JSON.stringify({
          ...shapeRolesOnFormSubmit(values),
        }),
        method: 'PUT',
      })

      setToken(token)

      yield put(editUser.success(shapeRolesOnFormSubmit(values)))
    } catch (e) {
      yield put(editUser.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(stopAsyncValidation(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchEditUser)]
