// @flow
import React from 'react'
import FilterIcon from '@material-ui/icons/Filter'
import { DeleteIcon } from 'src/assets/icons'
import { Confirm, RenderIf } from 'src/common/components'
import { defaultSet } from 'src/modules/orders/ordersList/fragments/ordersSearch/orderSearchSetting'

// types
import type { OrderFiltersPropsType } from '../../TableExportTypes'

// styles
import { StyledOrdersFiltersList, StyledFilter } from './ordersFiltersListStyles'

const OrdersFiltersList = ({
  ordersFilters,
  setOrdersExportFilter,
  selectedOrdersFilter,
  deleteOrdersExportFilter,
}: OrderFiltersPropsType) => {
  const handleClick = (e, id) => {
    e.stopPropagation()
    setOrdersExportFilter({ id })
  }

  return (
    <Confirm
      title="Confirm"
      description={'Are you sure you want to delete this set ?'}
      closedQuestion={true}
      isDeleting={true}
    >
      {confirm => (
        <StyledOrdersFiltersList>
          {ordersFilters.map(({ name, id }) => (
            <StyledFilter
              selectedFilter={selectedOrdersFilter?.id === id}
              key={id}
              onClick={e => handleClick(e, id)}
            >
              <span className="filter-icon"><FilterIcon /></span>
              <span>{name}</span>
              <RenderIf condition={id !== defaultSet.id}>
                <span onClick={confirm({
                  handleSubmit: () => deleteOrdersExportFilter({ id }),
                  valid: true,
                })} className="delete-icon"><DeleteIcon /></span>
              </RenderIf>
            </StyledFilter>
          ))}
        </StyledOrdersFiltersList>
      )}
    </Confirm>
  )
}

export default OrdersFiltersList
