import styled from 'styled-components'

export const StyledFormInput = styled.input`
    color: ${props => props.theme.main.colors.inputColor};
    font-size: 14px;
    margin-right: 4px;
    padding: 12px;
    width: auto !important;
    
   
`

export const StyledRadioInput = styled.label`
    align-items: center;
    display: flex;
    padding-right: 20px;
    padding-top: 25px!important;
    position: relative;

    .edit-btns {
      position: absolute;
      right: 5px;
      top: 5px;
      .MuiSvgIcon-root {
        cursor: pointer;
      }

      .success, .cancel {
        cursor: pointer;
      }

      .success {
        color: ${props => props.theme.main.colors.greenColor}

        &.disabled {
          color: ${props => props.theme.main.colors.inputBorderColor};
          cursor: not-allowed;
        }
      }
      .cancel {
        color: ${props => props.theme.main.colors.errorColor}
      }
    }

    .address-fields {
      margin-bottom: 13px;
    }
    
    .text-container {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      
      span {
        margin-bottom: 10px;
        
        :last-child {
          margin-bottom: 0;
        }
       
        .item-container {
          display: flex;
          align-items: center;
          .free {
          
          color: #d43540;
         }
          .free-cost {
            text-decoration: line-through;
          }
          span {
            margin: 0;
          }
        }
      }
    }
`

export const StyledError = styled.span`
    color: ${props => props.theme.main.colors.errorColor};
    font-size: 13px;
`
