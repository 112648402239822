// @flow
import styled from 'styled-components'

export const StyledOrdersFilters = styled.div`
  button {
    background-color: ${props => props.theme.main.colors.primaryColor};
    color: ${props => props.theme.main.colors.backgroundLight};
    &:hover {
        background-color: ${props => props.theme.main.colors.buttonColor};
    }
  }
  .form-row {
    justify-content: space-between;
    .form-group {
      width: auto;
    }
  }
`
