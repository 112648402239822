// @flow
import React, { useEffect, useState } from 'react'

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core'

// type
import type { MultiSelectReduxFormPropsType } from './MultiSelectReduxFormTypes'

const MultiSelectReduxForm = ({
  id,
  label,
  variant,
  size,
  list,
  meta: { touched, error },
  fields,
  ...custom
}: MultiSelectReduxFormPropsType) => {
  const values = fields.getAll() || []
  const valuesSet = values.reduce((acc, cur) => acc.add(cur), new Set())

  // eslint-disable-next-line no-unused-vars
  const [itemsMap, updItemsMap] = useState(() => new Map())
  useEffect(() => {
    const newItemsMap = new Map()
    list.forEach(({ name, label }) => {
      newItemsMap.set(name, label)
    })
    updItemsMap(newItemsMap)
    fields.removeAll()
    values.forEach(v => {
      if (newItemsMap.get(v)) {
        fields.push(v)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  const _onChange = (ev, data) => {
    const dataKey = data.key.replace('.$', '')
    if (valuesSet.has(dataKey)) {
      fields.remove(values.indexOf(dataKey))
    } else {
      fields.push(dataKey)
    }
  }

  return (
    <FormControl variant={variant} size={size}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        label={label}
        id={id}
        multiple
        value={values}
        onChange={_onChange}
        renderValue={selected => selected.map(v => itemsMap.get(v)).join(', ')}
      >
        {list.map(data => (
          <MenuItem key={data.name} value={data.name}>
            <Checkbox color="primary" checked={valuesSet.has(data.name)} />
            <ListItemText primary={data.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const MultiSelect = ({ variant, size, id, label, values, list, onChange }: any) => {
  const valuesSet = new Set(values)
  return (
    <FormControl variant={variant} size={size}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        label={label}
        id={id}
        multiple
        value={values}
        onChange={onChange}
        renderValue={selected => {
          return selected.map(v => v.replace('.$', '')).join(',')
        }}
      >
        {list.map(data => (
          <MenuItem key={data.name} value={data.name}>
            <Checkbox color="primary" checked={valuesSet.has(data.name)} />
            <ListItemText primary={data.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
export default MultiSelectReduxForm

