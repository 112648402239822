// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'
const PublicLeaguesList = lazy(() => import('src/modules/leagueFinder/publicLeaguesList'))
const iframeTest = lazy(() => import('src/modules/leagueFinder/iframeTest/page'))

export default [
  <PrivateRoute exact={true} path="/league-finder" component={PublicLeaguesList} key="league-finder"/>,
  <PrivateRoute exact={true} path="/find-league-iframe" component={iframeTest} key="find-league-iframe"/>,
]
