// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_EXPOSURE_ZONES, getExposureZones } from 'src/redux/actions/exemptionCertificates/exemptionCertificates'
import { fetchExemptionZones } from 'src/services/exemptionCertificates'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetExposureZones(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_EXPOSURE_ZONES.REQUEST)

    try {
      const data = yield call(fetchExemptionZones)

      const shapedData = data.map(item => ({ ...item, label: item.name }))

      yield put(getExposureZones.success(shapedData))
    } catch (e) {
      yield put(getExposureZones.error(e.message))
    }
  }
}

export default [fork(watchGetExposureZones)]
