// @flow

import { call, fork, put, take, select } from 'redux-saga/effects'
import { DELETE_ORDERS_EXPORT_FILTER, deleteOrdersExportFilter, getOrdersExportFilters, setOrdersExportFilter, GET_ORDERS_EXPORT_FILTERS } from 'src/redux/actions/orders/orders'
import { fetchDeleteOrdersExportFilter } from 'src/services/orders'
import { defaultSet } from 'src/modules/orders/ordersList/fragments/ordersSearch/orderSearchSetting'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchDeleteOrdersExportFilter(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { id },
    } = yield take(DELETE_ORDERS_EXPORT_FILTER.REQUEST)

    try {
      yield call(fetchDeleteOrdersExportFilter, id)

      yield put(deleteOrdersExportFilter.success())
      yield put(getOrdersExportFilters.request())

      yield take([GET_ORDERS_EXPORT_FILTERS.SUCCESS, GET_ORDERS_EXPORT_FILTERS.FAILURE])

      const { orders: { selectedOrdersFilter } } = yield select()
      if (!selectedOrdersFilter || selectedOrdersFilter?.id === id) {
        yield put(setOrdersExportFilter.success({ id: defaultSet.id }))
      }
    } catch (e) {
      yield put(deleteOrdersExportFilter.error(e.message))
    }
  }
}

export default [fork(watchDeleteOrdersExportFilter)]
