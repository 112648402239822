// @flow

import React from 'react'
import { StyledError, StyledSelect } from './selectStyles'
import type { SelectPropsType } from './SelectTypes'

const select = ({
  label,
  input,
  selectData,
  placeholder,
  defaultChecked,
  meta: { touched, error },
  withOptGroup = false,
  withEmptyValue = true,
  asyncErrorCheck = false,
  ...rest
}: SelectPropsType) => {
  let labelBlock = null
  let errorBlock = null
  const placeholderBlock = withEmptyValue && <option value="">{ placeholder || 'Select...' }</option>

  if (label) {
    labelBlock = (
      <label>
        { label }
      </label>
    )
  }

  if ((touched || asyncErrorCheck) && error) {
    errorBlock = (
      <div>
        <StyledError className="error-message">{error}</StyledError>
      </div>
    )
  }

  const renderWithOptGroup = selectData => {
    const shapedData = selectData.reduce(function(acc, obj) {
      const key = obj.type
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
    return Object.keys(shapedData).map(key => {
      return (
        <optgroup label={key} key={key}>
          {shapedData[key].map(item => {
            return (
              <option value={item.id} key={item.id}>{item.name || item.address_1}</option>
            )
          })}
        </optgroup>
      )
    })
  }

  const renderOptions = ({ withOptGroup, selectData }) => {
    if (!withOptGroup &&
            selectData) {
      return selectData.map(function({ value, name, disabled, id, address_1 }) {
        return <option key={id || value} disabled={disabled} value={id || value}>{ name || address_1 }</option>
      })
    }
    if (withOptGroup &&
            selectData) {
      return renderWithOptGroup(selectData)
    } else {
      return null
    }
  }

  return (
    <div>
      { labelBlock }
      {defaultChecked
        ? <StyledSelect {...rest} {...input} value={defaultChecked}>
          { placeholderBlock }
          {renderOptions({ withOptGroup, selectData })}
        </StyledSelect>
        : <StyledSelect {...rest} {...input}>
          { placeholderBlock }
          {renderOptions({ withOptGroup, selectData })}
        </StyledSelect>
      }

      { errorBlock }
    </div>
  )
}

export default select
