// @flow
import React from 'react'
import { ButtonPrimary } from 'src/common/components'
import { CloseIcon } from 'src/assets/icons'
// styles
import { StyledWrapper, StyledModal, StyledCancelButton } from './ModalStyles'

// types
import type { ModalTypes } from './ModalTypes'

const Modal = (props: ModalTypes) => {
  const { title, subtitle, additionalText, closedQuestion, onConfirm, footerText, withCloseButton = true, zIndex = 1, absoluteCentering = false, bodyContent: BodyContent = null } = props
  return (
    <StyledWrapper zIndex={zIndex}>
      <StyledModal>
        <div className="modal-title">{title}</div>
        <div className="modal-subtitle">{subtitle}</div>
        {BodyContent && <div className="modal-subtitle"><BodyContent /></div>}
        {additionalText && <div className="modal-subtitle">{additionalText}</div>}
        <div className="modal-action">
          <ButtonPrimary buttonText={closedQuestion ? 'Yes' : 'Ok'} size="small" action={onConfirm}/>
          {withCloseButton && <StyledCancelButton onClick={props.onCancel} absolute={absoluteCentering}>
            <CloseIcon />
            {closedQuestion ? 'No' : 'Cancel'}
          </StyledCancelButton>}
        </div>
        {footerText && <div className="modal-subtitle modal-action">{footerText}</div>}
      </StyledModal>
    </StyledWrapper>
  )
}

export default Modal
