// @flow
import React, { lazy } from 'react'
import { PublicRoute, PrivateRoute } from 'src/common/components'

const EmailConfirmation = lazy(() => import('src/modules/userSettings/emailConfirmation'))
const PasswordActivation = lazy(() => import('src/modules/userSettings/passwordActivation'))
const ForgotPassword = lazy(() => import('src/modules/userSettings/forgotPassword'))
const ResetPassword = lazy(() => import('src/modules/userSettings/resetPassword'))
const ThankYouPage = lazy(() => import('src/modules/userSettings/thankYouPage'))
const EditPassword = lazy(() => import('src/modules/userSettings/editPassword'))
const ThankYouEditPasswordPage = lazy(() => import('src/modules/userSettings/thankYouEditPasswordPage'))

export default [
  <PublicRoute exact={true} path="/settings/email-confirmation" component={EmailConfirmation} key="email-confirmation"/>,
  <PublicRoute exact={true} path="/settings/password-activation/:token?" component={PasswordActivation} key="password-activation"/>,
  <PublicRoute exact={true} path="/forgot-password" component={ForgotPassword} key="forgot-password"/>,
  <PublicRoute exact={true} path="/reset-password/:token" component={ResetPassword} key="reset-password"/>,
  <PublicRoute exact={true} path="/settings/thank-you" component={ThankYouPage} key="thank-you" />,
  <PrivateRoute exact={true} path="/edit-password/:token" component={EditPassword} key="reset-password"/>,
  <PrivateRoute exact={true} path="/password/thank-you" component={ThankYouEditPasswordPage} key="password-thank-you"/>,
]
