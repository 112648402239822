// @flow
import React from 'react'
import { createPortal } from 'react-dom'
import { ButtonPrimary } from 'src/common/components'
import { CloseIcon } from 'src/assets/icons'

// styles
import {
  StyledWrapper,
  StyledInnerWrapper,
  StyledModal,
  StyledCancelButton,
} from './ModalStyles'

// types
import type { ModalTypes } from './ModalTypes'

const Modal = (props: ModalTypes) => {
  const {
    title,
    subtitle,
    additionalText,
    confirmBtnText,
    cancelBtnText,
    cancelBtnPrependContent,
    isCancelInPlainStyle,
    showHideBtn = false,
    showConfirmBtn = true,
    showCancelBtn = true,
    onConfirm,
    onCancel,
    onHide,
  } = props

  const cancelContent = (
    <>
      {cancelBtnPrependContent}
      {cancelBtnText}
    </>
  )

  const el = document.getElementById('modal-root')

  return createPortal(
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledModal>
          {showHideBtn && <div className="modal-close" onClick={onHide}><CloseIcon/></div>}
          <div className="modal-title">{title}</div>
          <div className="modal-subtitle">{subtitle}</div>
          {additionalText
            ? Array.isArray(additionalText) && additionalText.length
              ? <div>
                <table className="modal-table">
                  <tbody>
                    <tr>
                      <td>
                        {additionalText.map((str, key) => <li key={key}>{str}</li>)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              : <div className="modal-subtitle">{additionalText}</div>
            : ''
          }
          {(showConfirmBtn || showCancelBtn) && <div className="modal-action">
            {showConfirmBtn &&
            <ButtonPrimary buttonText={confirmBtnText} size="small" action={onConfirm}/>
            }
            {showCancelBtn
              ? isCancelInPlainStyle
                ? <StyledCancelButton onClick={onCancel}>
                  {cancelContent}
                </StyledCancelButton>
                : <ButtonPrimary buttonText={cancelContent} size="small" action={onCancel}/>
              : ''
            }
          </div>}
        </StyledModal>
      </StyledInnerWrapper>
    </StyledWrapper>,
    // $FlowFixMe
    el,
  )
}

export default Modal
