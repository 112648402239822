// @flow

import React from 'react'
import RenderIf from '../renderIf'
import { StyledFormInput, StyledError, StyledFormTextarea } from './formInputStyles'
import type { FormInputPropsType } from './FormInputTypes'

const FormInput = ({
  // $FlowFixMe
  input,
  meta: { touched, error },
  placeholder,
  validationErrors,
  id, // form field id equals to validationErrors.errors[key]
  type,
  children,
  ...rest
}: FormInputPropsType) => {
  let errorBlock = null

  if (touched && (error || (validationErrors && validationErrors.errors[id]))) {
    errorBlock = (
      <div>
        <StyledError className="error-message">{error || validationErrors.message}</StyledError>
      </div>
    )
  }

  return (
    <>
      {type === 'textarea'
        ? (
          <StyledFormTextarea {...rest} {...input} value={typeof (input.value) === 'string' ? input.value.trimLeft() : input.value} placeholder={placeholder} />
        )
        : (
          <StyledFormInput {...rest} {...input} type={type} value={typeof (input.value) === 'string' ? input.value.trimLeft() : input.value} placeholder={placeholder} />
        )}
      <RenderIf condition={children}>
        {children}
      </RenderIf>
      {errorBlock}
    </>
  )
}

export default FormInput
