// @flow
import * as React from 'react'
import {
  GET_INSURANCES,
  GET_INSURANCES_STATUSES,
  GET_INSURANCE,
  SUBMIT_INSURANCE_STATUS,
  GET_LEAGUE_INSURANCES,
  BIND_INSURANCE,
  CHECK_FILE_INSURANCE,
  GET_DECLINE_REASONS,
} from 'src/redux/actions/insurances/insurances'
import { INSURANCE_PAYMENT_STATUS } from 'src/common/constants'
import type { InsurancesActionTypes } from 'src/redux/actions/insurances/insurancesTypes'

export type PlayersGroupData = {
  minAge: number,
  maxAge: number,
  numberOfPlayers: number
};

export type JetfileMetaData = {
  coverage: number,
  qNum: number,
  qSuffix: string,
  gross: number,
  totalInsuranceNumberOfPlayers: number,
  costOnePlayer: number
};

export type InsuranceMetaData = JetfileMetaData;

export type FileType = {
  name: string,
  url: string,
  id: number,
  filename: string
};

export type CurrentInsuranceType = {
    id: number,
    status: string,
    name: string,
    file_name: string,
    start_date: string,
    end_date: string,
    created_at: string,
    approve_date: string,
    league_name: string,
    team_genders: string,
    file: string,
    type_insurance: string,
    org_type: string,
    website: string,
    first_name: string,
    last_name: string,
    email: string,
    min: string | number,
    max: string | number,
    league_zip: ?string,
    league_address_1: string,
    league_city: ?string,
    league_state: ?string,
    league_id: string | number,
    expiration_status: string,
    vendor: ?string,
    meta: ?InsuranceMetaData,
    policy_number: ?string,
    bound_at: ?string,
    payment_status: ?INSURANCE_PAYMENT_STATUS,
    players: PlayersGroupData[],
    statusesNode: ?React.Node,
    files: FileType[],
    status_updated_at?: string,
    last_updated_admin?: {
        first_name: string,
        last_name: string
    }
};

export type InsurancesItem = CurrentInsuranceType;
export type InsurancesData = {
    total: number,
    offset: number,
    limit: number,
    page: number,
    results: InsurancesItem[]
};

export type InsurancesStatuses = {
    approval_statuses: string[],
    expiration_statuses: string[]
};

export type InsurancesState = {
  isLoading: boolean,
  isLoadingStatuses: boolean,
  insurancesData?: ?InsurancesData,
  insurancesErrors?: ?{ [key: string]: any } | ?string,
  insurancesStatuses?: ?InsurancesStatuses,
  currentInsurance: ?CurrentInsuranceType,
  fileOpen: string,
  declineReasons: {
    id: number,
    name: string
  }[]
};

const initialState = {
  isLoading: false,
  isLoadingStatuses: false,
  insurancesData: null,
  insurancesErrors: null,
  insurancesStatuses: null,
  currentInsurance: null,
  fileOpen: '',
  declineReasons: [],
}

const insurances = (state: InsurancesState = initialState, action: InsurancesActionTypes): InsurancesState => {
  switch (action.type) {
    case GET_INSURANCES.REQUEST:
    case GET_INSURANCE.REQUEST:
    case SUBMIT_INSURANCE_STATUS.REQUEST:
    case GET_LEAGUE_INSURANCES.REQUEST:
    case BIND_INSURANCE.REQUEST:
    case GET_DECLINE_REASONS.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        insurancesErrors: null,
      }
    }
    case GET_INSURANCES_STATUSES.REQUEST:
    {
      return {
        ...state,
        isLoadingStatuses: true,
        insurancesErrors: null,
      }
    }

    case CHECK_FILE_INSURANCE.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        fileOpen: '',
        insurancesErrors: null,
      }
    }

    case GET_INSURANCES.SUCCESS:
    case GET_LEAGUE_INSURANCES.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        insurancesData: { ...action.payload },
        insurancesErrors: null,
      }
    }
    case SUBMIT_INSURANCE_STATUS.SUCCESS:
    case BIND_INSURANCE.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        insurancesErrors: null,
      }
    }
    case GET_INSURANCE.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        currentInsurance: { ...action.payload },
        insurancesErrors: null,
      }
    }
    case CHECK_FILE_INSURANCE.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        fileOpen: action.payload,
      }
    }
    case GET_INSURANCES_STATUSES.SUCCESS:
    {
      return {
        ...state,
        isLoadingStatuses: false,
        insurancesStatuses: { ...action.payload },
        insurancesErrors: null,
      }
    }

    case GET_DECLINE_REASONS.SUCCESS:
    {
      return {
        ...state,
        isLoadingStatuses: false,
        declineReasons: action.payload,
        insurancesErrors: null,
      }
    }

    case GET_INSURANCES.FAILURE:
    case GET_INSURANCE.FAILURE:
    case SUBMIT_INSURANCE_STATUS.FAILURE:
    case GET_LEAGUE_INSURANCES.FAILURE:
    case BIND_INSURANCE.FAILURE:
    case CHECK_FILE_INSURANCE.FAILURE:
    case GET_DECLINE_REASONS.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        insurancesErrors: action.payload,
      }
    }
    case GET_INSURANCES_STATUSES.FAILURE:
    {
      return {
        ...state,
        isLoadingStatuses: false,
        insurancesErrors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default insurances
