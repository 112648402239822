// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getAllAdmins,
  getAdmin,
  disableAdmin,
  enableAdmin,
  createAdmin,
} from 'src/redux/actions/adminAccounts/adminAccounts'

const withAdmins = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithAdminsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ adminAccounts }) => ({ adminAccounts })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllAdmins: () => dispatch(getAllAdmins.request()),
    getAdmin: (adminId: number) => dispatch(getAdmin.request({ adminId })),
    disableAdmin: (adminId: number) => dispatch(disableAdmin.request({ adminId })),
    enableAdmin: (adminId: number) => dispatch(enableAdmin.request({ adminId })),
    createAdmin: (payload: any) => dispatch(createAdmin.request(payload)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithAdminsComponent))
}

export default withAdmins
