// @flow
import React, { useState, useEffect } from 'react'
import { differenceBy } from 'lodash'

import IconButton from '@material-ui/core/IconButton'
import GetApp from '@material-ui/icons/GetApp'
import Info from '@material-ui/icons/Info'
import {
  UserConfirm,
  Loader,
} from 'src/common/components'
import StyledBanner, { BANNER_TYPES } from 'src/common/components/bannerBlockStyle'

import TableExportForm from './fragments/TableExportFormContainer'
import TableExportDragDrop from './fragments/TableExportDragDrop'
import TableExportSubmitBlock from './fragments/TableExportSubmitBlock'
import OrdersFiltersList from './fragments/ordersFiltersList'
import { defaultSet } from 'src/modules/orders/ordersList/fragments/ordersSearch/orderSearchSetting'

import OrdersFiltersForm from './fragments/ordersFiltersForm'

import { useRequestCallback } from 'src/hooks'

// styles
import {
  TableExportStyle,
  TableExportNoteStyle,
  TableExportIcon,
} from './TableExportStyles'

// types
import type { TableExportPropsType } from './TableExportTypes'

const TableExport = (props: TableExportPropsType) => {
  const {
    fields,
    fetchDataRequest,
    isUsingFilters,
    ordersFilters,
    setOrdersExportFilter,
    selectedOrdersFilter,
    deleteOrdersExportFilter,
    isLoadingFilters,
  } = props
  const [{
    data: fetchedResult,
    isSuccessful,
    isLoading,
  }, loadData, resetState] = useRequestCallback()

  const [outputArray, updateOutputArray] = useState(selectedOrdersFilter?.fields || [])
  const [removedArray, updateRemovedArray] = useState([])

  useEffect(() => {
    if (ordersFilters?.length && !selectedOrdersFilter?.id) {
      setOrdersExportFilter({ id: defaultSet.id })
    }
    const selected = selectedOrdersFilter?.fields || []
    const removed = differenceBy(fields, selected, 'name')
    updateOutputArray(selected)
    updateRemovedArray(removed)
  }, [ordersFilters?.length, selectedOrdersFilter?.id])

  useEffect(() => {
    setOrdersExportFilter({ id: defaultSet.id })
  }, [])

  return (
    <UserConfirm
      title="Export settings"
      showHideBtn={true}
      showCancelBtn={false}
      showConfirmBtn={false}
      description={
        <TableExportStyle>
          <Loader show={isLoading || isLoadingFilters} />

          {isUsingFilters && <StyledBanner type={BANNER_TYPES.WARNING} mb={20}>
            This export is using search filters of this page
          </StyledBanner>}

          <OrdersFiltersList
            ordersFilters={ordersFilters}
            setOrdersExportFilter={setOrdersExportFilter}
            selectedOrdersFilter={selectedOrdersFilter}
            deleteOrdersExportFilter={deleteOrdersExportFilter}
          />

          <OrdersFiltersForm outputArray={outputArray} />

          <TableExportForm />
          <TableExportNoteStyle>
            <Info style={{ fontSize: '1em' }} />
            Drag-and-drop columns to build export format. Sort columns in left list or move to right to remove column from export
          </TableExportNoteStyle>

          <TableExportDragDrop
            fields={fields}
            outputArray={outputArray}
            updateOutputArray={updateOutputArray}
            removedArray={removedArray}
            updateRemovedArray={updateRemovedArray}
          />
          <TableExportSubmitBlock
            disabled={!outputArray.length}
            outputArray={outputArray}
            fetchDataRequest={fetchDataRequest}
            fetchedResult={fetchedResult}
            isSuccessful={isSuccessful}
            loadData={loadData}
          />
        </TableExportStyle>
      }
    >
      {confirm => (
        <TableExportIcon>
          <IconButton
            onClick={confirm({
              valid: true,
              handleHide: resetState,
            })}
          >
            <GetApp fontSize="inherit" />
          </IconButton>
        </TableExportIcon>
      )}
    </UserConfirm>
  )
}

export default TableExport
