// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_LEAGUES_ORGANIZER, getLeaguesOrganizer } from '../actions/leagues/leagues'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetLeagueOrganizer(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { leagueId, ...values },
    } = yield take(GET_LEAGUES_ORGANIZER.REQUEST)

    const params = {
      limit: values.limit,
      offset: values.offset,
    }

    try {
      let { data } = yield call(http, `admin/league/${leagueId}/organizer`, {
        method: 'GET',
        params,
      })

      data = { ...data, page: values.page || 1 }
      yield put(getLeaguesOrganizer.success(data))
    } catch (e) {
      yield put(getLeaguesOrganizer.error(e.message))
    }
  }
}

export default [fork(watchGetLeagueOrganizer)]
