import http from 'src/services/http'

export const fetchEditSeason = async data => {
  const { season_id, formValues, league_id } = data
  const response = await http(`admin/league/${league_id}/season/${season_id}`, {
    method: 'PUT',
    body: JSON.stringify(formValues),
  })
  return response?.data
}

export const fetchDeleteSeason = async data => {
  const { leagueId, seasonId } = data
  const response = await http(`admin/league/${leagueId}/season/${seasonId}`, {
    method: 'DELETE',
  })
  return response?.data
}
