// @flow

import React from 'react'

import { Modal } from 'src/common/components'

// types
import type { ConfirmPropsType, ConfirmStateType } from './ConfirmTypes'

export default class ConfirmStatusChange extends React.Component<ConfirmPropsType, ConfirmStateType> {
  state = {
    open: false,
    callback: () => {},
  }

  show = ({ valid, handleSubmit, getDescriptionVariable }: {
      valid: boolean,
      handleSubmit: () => void,
    getDescriptionVariable: () => void
  }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
    if (event && event.preventDefault) {
      event.preventDefault()
      event.stopPropagation()
    }
    getDescriptionVariable && getDescriptionVariable()

    !valid && handleSubmit()

    valid && this.setState({
      open: true,
      callback: () => handleSubmit(),
    })
  }

  hide = () => this.setState({ open: false, callback: () => {} })

  confirm = () => {
    this.state.callback()
    this.hide()
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children(this.show)}

        {this.state.open && (
          <Modal
            zIndex={1205}
            title={this.props.title}
            subtitle={this.props.description}
            onCancel={this.hide}
            onConfirm={this.confirm}
            closedQuestion={this.props.closedQuestion}
            additionalText={this.props.additionalText}
            footerText={this.props.footerText}
            withCloseButton={this.props.withCloseButton}
          />
        )}
      </React.Fragment>
    )
  }
}
