// @flow
import { call, put, takeEvery, fork } from 'redux-saga/effects'
import { getLeagueItemTypes, GET_LEAGUE_ITEM_TYPES } from 'src/redux/actions/leagues/leagues'
import { fetchLeagueItemTypes } from 'src/services/leagues'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetLeagueItemTypesStart(): Generator<IOEffect, void, any> {
  yield takeEvery(
    GET_LEAGUE_ITEM_TYPES.REQUEST,
    getLeagueItemTypesRequest,
  )
}

function * getLeagueItemTypesRequest(): Generator<IOEffect, void, any> {
  try {
    const data = yield call(
      fetchLeagueItemTypes,
    )
    yield put(getLeagueItemTypes.success(data))
  } catch (e) {
    yield put(getLeagueItemTypes.error(e.message))
  }
}

export default [fork(watchGetLeagueItemTypesStart)]
