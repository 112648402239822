import React from 'react'
import { Field } from 'redux-form'
import FormInput from 'src/common/components/formInput'
import { COUNTRY_CODES } from 'src/common/constants'
import { normalizeZip, normalizeZipCA } from 'src/helpers/formNormalizers'
import { required, zipCodeFn } from 'src/helpers/formValidation'

const Input = props => {
  const { input, country } = props
  if (country === COUNTRY_CODES.CA) {
    input.value = normalizeZipCA(input.value) // format for disabled zip code: "A1A 1A1"
  }
  return (
    <FormInput {...props} input={input} />
  )
}

const InputZipByCountry = props => {
  const {
    id,
    name,
    country,
    validateZip = true,
    disabled = false,
  } = props
  return (
    <Field
      id={id}
      name={name}
      component={Input}
      placeholder={country === COUNTRY_CODES.CA ? 'A1A 1A1' : 'XXXXX OR XXXXX-XXXX'}
      normalize={country === COUNTRY_CODES.CA ? normalizeZipCA : normalizeZip}
      validate={validateZip ? [zipCodeFn(country), required] : [required]}
      disabled={disabled}
      {...props}
    />
  )
}

export default InputZipByCountry
