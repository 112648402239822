// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_ADMIN, GET_ALL_ADMINS, getAllAdmins, getAdmin } from 'src/redux/actions/adminAccounts/adminAccounts'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetAllAdmins(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_ALL_ADMINS.REQUEST)

    try {
      const { data } = yield call(http, 'admin/account', {
        method: 'GET',
      })

      yield put(getAllAdmins.success(data))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      yield put(getAllAdmins.error(e.message))
    }
  }
}

function * watchGetAdmin(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { adminId } } = yield take(GET_ADMIN.REQUEST)

    try {
      const { data } = yield call(http, `admin/account/${adminId}`, {
        method: 'GET',
      })

      yield put(getAdmin.success(data))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      yield put(getAdmin.error(e.message))
    }
  }
}

export default [
  fork(watchGetAllAdmins),
  fork(watchGetAdmin),
]
