import styled from 'styled-components'

const BaseFormInput = styled.input`
  border-radius: 2px;
  margin: 6px 0 4px;
  border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
  background: ${props => props.theme.main.colors.inputBackgroundColor};
  color: ${props => props.theme.main.colors.inputColor};
  font-size: 14px;
  padding: 12px;
  width: 100%;

  :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus {
    border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
    font-size: 14px;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${props => props.theme.main.colors.inputColor};
  }

  :focus {
    border-color: ${props => props.theme.main.colors.primaryColor};
  }
`

export const StyledFormInput = styled(BaseFormInput)`
  :disabled {
    border: 0;
    font-weight: 500;
    padding: 12px 0 0;
  }
`

export const StyledFormTextarea = styled.textarea`
    border-radius: 2px;
    margin: 6px 0 4px;
    border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
    background: ${props => props.theme.main.colors.inputBackgroundColor};
    color: ${props => props.theme.main.colors.inputColor};
    font-size: 14px;
    padding: 12px;
    width: 100%;
    
    :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus {
        border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
        font-size: 14px;
        transition: background-color 5000s ease-in-out 0s;
         -webkit-text-fill-color: ${props => props.theme.main.colors.inputColor};
    }
    
    :focus {
        border-color: ${props => props.theme.main.colors.primaryColor};
    }
    
    :disabled {
        border: 0;
        font-weight: 500;
        padding: 12px 0 0;
    }
`

export const StyledError = styled.span`
    font-size: 13px;
    color: ${props => props.theme.main.colors.errorColor};
`

export const StyledCommonFormInput = styled(BaseFormInput)`
    padding: 6px 12px;
    max-width: 100px;
    
    :disabled {
        font-weight: 500;
        background: ${props => props.theme.main.colors.disabledInputBgColor};
        color: #ccc;
    }
`
