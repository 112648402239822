// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getOrderSettings,
  editOrderSettings,
  updateOwnerLeaguesPrices,
  updateOwnerLeaguesVendor,
  forceLeagueVendorUpdate,
} from 'src/redux/actions/orderSettings/orderSettings'

const withOrderSettings = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithOrderSettingsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ orderSettings }) => ({ orderSettings })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getOrderSettings: owner_id => dispatch(getOrderSettings.request(owner_id)),
    editOrderSettings: values => dispatch(editOrderSettings.request(values)),
    updateOwnerLeaguesPrices: values => dispatch(updateOwnerLeaguesPrices.request(values)),
    updateOwnerLeaguesVendor: values => dispatch(updateOwnerLeaguesVendor.request(values)),
    forceLeagueVendorUpdate: values => dispatch(forceLeagueVendorUpdate.request(values)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithOrderSettingsComponent))
}

export default withOrderSettings
