// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'
const ThankYouPage = lazy(() => import('src/modules/payment/thankYouPage'))
const PendingOrderCardPayment = lazy(() => import('src/modules/payment/pendingOrderCardPayment'))

export default [
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/order/:orderId/thank-you" component={ThankYouPage} key="payment-thank-you"/>,
  <PrivateRoute exact={true} path="/leagues-list/:leagueId/league-details/orders/:orderId/payment" component={PendingOrderCardPayment} key="/leagues-list/leagueId/order/payment"/>,
]
