// @flow

import React from 'react'
import { CardLeague } from 'src/common/components'
import getLocation from 'src/helpers/getLocation'
import { withPublicLeagues } from 'src/decorators'

// types
import type { PublicLeaguesDataItem } from 'src/redux/reducers/publicLeagues'

// styles
import { StyledList } from './cardLeagueListStyles'

const getButtonData = ({ id }) => {
  const buttonData = {
    text: 'MANAGE LEAGUE',
    link: `leagues-list/${id}/league-details/preview-league`,
  }
  return buttonData
}

const List = ({ items, showButton, updatePublicLeague }: any) => {
  return items.map((item: PublicLeaguesDataItem) => {
    const buttonData = getButtonData({ id: item.id })
    return (
      <li className="list-item" key={`ist-item${item.id}`}>
        <CardLeague
          status={item.approved}
          updated_at={item.updated_at}
          showButton={showButton}
          id={item.id}
          leagueName={item.name}
          location={getLocation(item)}
          phone={item.phone}
          link={item.website}
          hasBoy={item.has_boys}
          hasGirl={item.has_girls}
          hasCoed={item.has_coed}
          distance={item.distance || null}
          activeSeasons={item.active_seasons}
          buttonText={buttonData.text}
          buttonLink={buttonData.link}
          onClickHandler={() => updatePublicLeague({ items, id: item.id })}
          program_logo={item.program_logo}
        />
      </li>
    )
  })
}

const CardLeagueList = ({ items, showButton = true, updatePublicLeague }: any) => {
  return (
    <StyledList className="list list-my-leagues"><List items={items} showButton={showButton} updatePublicLeague={updatePublicLeague}/></StyledList>
  )
}

export default withPublicLeagues(CardLeagueList)
