// @flow

export default {
  city: {
    name: 'City',
    min: 0,
    max: 40,
  },
  county: {
    name: 'County',
    min: 0,
    max: 40,
  },
  country: {
    name: 'Country',
    min: 0,
    max: 80,
  },
  state: {
    name: 'State/Province',
    min: 0,
    max: 80,
  },
  address: {
    name: 'Street',
    min: 0,
    max: 255,
  },
  zip: {
    name: 'Zip/Postal Code',
    min: 5,
    max: 5, // Salesforce max: 20
  },
  leagueName: {
    name: 'League Name',
    min: 0,
    max: 255,
  },
  description: {
    name: 'Description',
    min: 0,
    max: 255,
  },
  fax: {
    name: 'Fax',
    min: 0,
    max: 40,
  },
  phone: {
    name: 'Phone',
    min: 0,
    max: 40,
  },
  website: {
    name: 'Website',
    min: 0,
    max: 255,
  },
}
