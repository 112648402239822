
import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

const headerHeight = 40

export const TableExportStyle = styled.div`
  font-weight: normal;
`

export const TableExportFormStyle = styled.div`
  .form-row {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
  }

  .form-field {
    width: 250px;
    & > * {
      width: 100%;
    }
  }
`

export const TableExportNoteStyle = styled.div`
  color: #606266;
  font-size: .75em;
  padding-bottom: 10px;
  text-align: left;
`

export const TableExportDragDropStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`

export const TableExportDragDropColumnStyle = styled.div`
  height: auto;
  min-height: 100px;
`

export const TableExportDragDropColumnHeaderStyle = styled.div`
  height: ${headerHeight}px;
  line-height: ${headerHeight}px;
  overflow: hidden;
  padding: 0 15px;
  text-align: center;
  background: ${props => props.theme.main.colors.primaryColor};
  color: #fff;
  border-radius: 3px 3px 0 0;
`

export const TableExportDragDropMiddleArrowsStyle = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;

  .MuiSvgIcon-root {
    font-size: 24px;
  }

  @media ${device.mobileL} {
    .MuiButtonBase-root {
        padding: 0;
    }
  }
  .MuiButtonBase-root:first-child {
    transform: scaleX(-1);
  }
`

export const TableExportDragDropInnerColumnStyle = styled.div`
  background: #f0f0f0;
  padding: 4px 8px;
  max-width: 250px;
  height: auto;
  min-height: calc(100% - ${headerHeight}px);

  @media ${device.mobileL} {
    font-size: 0.9rem;
  }
`

export const TableExportDraggableItemStyle = styled.div`
  user-select: none;
  padding: 10px 15px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
  margin: 0;
  margin: 4px 0;
`

export const TableExportSubmitBlockStyle = styled.div`
  padding-top: 20px;
  text-align: center;
`

export const TableExportIcon = styled.div`
  span {
    width: 48px;
  }
`
