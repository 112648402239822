// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const withAddresses = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithAddressesComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ addresses }) => ({ addresses })

  const mapDispatchToProps = dispatch => ({})

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithAddressesComponent))
}

export default withAddresses
