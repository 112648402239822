
export const ITEM_TYPES = {
  BELT: 'BELT',
  PREMIUM_BELT: 'PREMIUM_BELT',
  FOOTBALL: 'FOOTBALL',
  UNIFORM: 'UNIFORM',
  SHORTS: 'SHORTS',
  SOCKS: 'SOCKS',
  SLEEVES: 'SLEEVES',
  MOUTHGUARD: 'MOUTHGUARD',
  FEE: 'FEE',
  GLOVES: 'GLOVES',
  HEADBAND: 'HEADBAND',
  COACH_SHIRTS: 'COACH_SHIRTS',
  APPAREL_WHITE_TEE: 'APPAREL_WHITE_TEE',
  APPAREL_SLEEVE_TEE: 'APPAREL_SLEEVE_TEE',
  APPAREL_HOODIE: 'APPAREL_HOODIE',
  PREMIUM_JERSEY: 'PREMIUM_JERSEY',
  PREMIUM_MOUTHGUARD: 'PREMIUM_MOUTHGUARD',
  PYLON_SETS: 'PYLON_SETS',
  PREMIUM_FOOTBALL: 'PREMIUM_FOOTBALL',
}

export const ITEM_SIZES = {
  MINI: 'MINI',
}

export const ADDITIONAL_ITEM_TYPES = [
  ITEM_TYPES.BELT,
  ITEM_TYPES.FOOTBALL,
  ITEM_TYPES.PREMIUM_FOOTBALL,
]

export const VENDOR = {
  AUGUSTA: 'AUGUSTA',
  MATRIX: 'MATRIX',
}

export const INSURANCE_VENDOR_TEXT = {
  MANUAL: 'Manual',
  JETFILE: 'JetFile',
}

export const ORDER_MODE = {
  BULK: 'BULK',
  TEAM: 'TEAM',
  VIRTUAL: 'VIRTUAL',
}

export const PAYMENT_STATUS = {
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID',
  PENDING: 'PENDING',
  REFUNDED: 'REFUNDED',
}

export const PAYMENT_TYPES = {
  CARD: 'CARD',
  CHECK: 'CHECK',
  LO_CREDIT: 'LO_CREDIT',
  ACH: 'ACH',
}

export const TYPE_ERROR_INSURANCES = {
  ERROR_BIND_INSURANCE: 'ERROR_BIND_INSURANCE',
}

export const VENDOR_INSURANCE_NAMES = {
  MANUAL: 'MANUAL',
  JETFILE: 'JETFILE',
}

export const FEE_ITEMS = {
  LETTERING: 'LETTERING',
  BUNDLING: 'BUNDLING',
}

export const INSURANCE_PAYMENT_STATUS = {
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID',
}

export const COUNTRY_CODES = {
  US: 'US',
  CA: 'CA',
}

export const ADDRESS_PREFIXES = {
  SHIPPING: 'shipping_',
  BILLING: 'billing_',
  LEAGUE: 'league_',
}

export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
}

export const CURRENCIES = {
  USD: 'USD',
  CAD: 'CAD',
}
