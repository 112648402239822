// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { DELETE_LEAGUE_SEASON, deleteSeason } from 'src/redux/actions/seasons/seasons'
import { getLeague } from 'src/redux/actions/leagues/leagues'
import { fetchDeleteSeason } from 'src/services/seasons'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchDeleteSeason(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { seasonId, leagueId },
    } = yield take(DELETE_LEAGUE_SEASON.REQUEST)

    try {
      yield call(fetchDeleteSeason, { seasonId, leagueId })

      yield put(deleteSeason.success())
      yield put(getLeague.request(leagueId))
    } catch (e) {
      yield put(deleteSeason.error(e.message))
    }
  }
}

export default [fork(watchDeleteSeason)]
