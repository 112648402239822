// @flow

import { requestTypeCreator } from 'src/helpers'

import type {
  BasicRedux,
  GetAccountsActionTypes,
  UserRolesActionTypes,
  GetAccountActionTypes,
  EditAccountActionTypes,
  DeleteAccountActionTypes,
} from './accountsTypes'

export const GET_ACCOUNTS = requestTypeCreator('GET_ACCOUNTS')
export const GET_USER_ROLES = requestTypeCreator('GET_USER_ROLES')
export const GET_ACCOUNT = requestTypeCreator('GET_ACCOUNT')
export const EDIT_ACCOUNT = requestTypeCreator('EDIT_ACCOUNT')
export const EDIT_USER_EMAIL = requestTypeCreator('EDIT_USER_EMAIL')
export const DELETE_ACCOUNT = requestTypeCreator('DELETE_ACCOUNT')
export const GET_ACCOUNT_CHANGES = requestTypeCreator('GET_ACCOUNT_CHANGES')

export const getAccounts = {
  error: (payload: any): GetAccountsActionTypes => ({ type: GET_ACCOUNTS.FAILURE, payload }),
  request: (payload: any): GetAccountsActionTypes => ({ type: GET_ACCOUNTS.REQUEST, payload }),
  success: (payload: any): GetAccountsActionTypes => ({ type: GET_ACCOUNTS.SUCCESS, payload }),
}

export const getUserRoles = {
  error: (payload: any): UserRolesActionTypes => ({ type: GET_USER_ROLES.FAILURE, payload }),
  request: (payload: any): UserRolesActionTypes => ({ type: GET_USER_ROLES.REQUEST, payload }),
  success: (payload: any): UserRolesActionTypes => ({ type: GET_USER_ROLES.SUCCESS, payload }),
}

export const getAccount = {
  error: (payload: any): GetAccountActionTypes => ({ type: GET_ACCOUNT.FAILURE, payload }),
  request: (payload: any): GetAccountActionTypes => ({ type: GET_ACCOUNT.REQUEST, payload }),
  success: (payload: any): GetAccountActionTypes => ({ type: GET_ACCOUNT.SUCCESS, payload }),
}
export const editAccount = {
  error: (payload: any): EditAccountActionTypes => ({ type: EDIT_ACCOUNT.FAILURE, payload }),
  request: (payload: any): EditAccountActionTypes => ({ type: EDIT_ACCOUNT.REQUEST, payload }),
  success: (payload: any): EditAccountActionTypes => ({ type: EDIT_ACCOUNT.SUCCESS, payload }),
}

export const editUserEmail = {
  error: (payload: any): BasicRedux => ({ type: EDIT_USER_EMAIL.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: EDIT_USER_EMAIL.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: EDIT_USER_EMAIL.SUCCESS, payload }),
}

export const deleteAccount = {
  error: (payload: any): DeleteAccountActionTypes => ({ type: DELETE_ACCOUNT.FAILURE, payload }),
  request: (payload: any): DeleteAccountActionTypes => ({ type: DELETE_ACCOUNT.REQUEST, payload }),
  success: (payload: any): DeleteAccountActionTypes => ({ type: DELETE_ACCOUNT.SUCCESS, payload }),
}

export const getAccountChanges = {
  error: (payload: any): GetAccountActionTypes => ({ type: GET_ACCOUNT_CHANGES.FAILURE, payload }),
  request: (payload: any): GetAccountActionTypes => ({ type: GET_ACCOUNT_CHANGES.REQUEST, payload }),
  success: (payload: any): GetAccountActionTypes => ({ type: GET_ACCOUNT_CHANGES.SUCCESS, payload }),
}
