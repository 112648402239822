// @flow
import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { Link } from 'react-router-dom'
import { withUser } from 'src/decorators'
import { logoutUser } from 'src/services/user'
// styles
import { StyledHeader } from './headerStyles'

const Header = ({ resetUserState }) => (
  <StyledHeader>
    <AppBar position="static" className="header">
      <Toolbar className="header-content">
        <Link to="/edit-profile">
          <IconButton edge="end" className="logout-button" color="inherit">
            <AccountBoxIcon />
          </IconButton>
        </Link>
        <IconButton edge="end" className="logout-button" color="inherit" onClick={() => logoutUser(resetUserState)}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>

  </StyledHeader>
)

export default withUser(Header)
