// @flow

import { fork, put, take, call } from 'redux-saga/effects'
import { SUBMIT_PENDING_ORDER_CARD_PAYMENT, submitPendingOrderCardPayment, getPaymentToken } from 'src/redux/actions/payments/payments'
import http from 'src/services/http'
import { history } from 'src/services/history'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSubmitPendingOrderCardPayment(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: {
        payload: { nonce, token, storeInVaultOnSuccess, paymentType },
        order_id,
      },
    } = yield take(SUBMIT_PENDING_ORDER_CARD_PAYMENT.REQUEST)

    try {
      yield http('admin/order/pending-card-payment', {
        body: JSON.stringify({
          ...(nonce ? { nonceFromTheClient: nonce } : { paymentMethodToken: token }),
          order_id: +order_id,
          payment_type: paymentType,
          storeInVaultOnSuccess,
        }),
        method: 'POST',
      })

      yield put(submitPendingOrderCardPayment.success())

      yield call(
        history.replace,
        history.location.pathname.replace('/payment', ''),
      )
    } catch (e) {
      yield put(getPaymentToken.request())
      yield put(submitPendingOrderCardPayment.error(e.message))
    }
  }
}

export default [fork(watchSubmitPendingOrderCardPayment)]
