import styled from 'styled-components'

export const StyledNav = styled.div`
    .list-item {
        li {
            padding: 0;
        }
    }
    
    .MuiDivider-root {
        background-color: #fff;
        opacity: 0.11;
    }
    
    .css-hide {
      display: none;
    }
`
