// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_ACCOUNTS, getAccounts } from 'src/redux/actions/accounts/accounts'
import http from 'src/services/http'
import config from '../../config/formsConfig'
import { stopAsyncValidation, stopSubmit } from 'redux-form'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetAccounts(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(GET_ACCOUNTS.REQUEST)

    const formId = config.searchUserForm.id
    const params = {
      limit: values.limit,
      offset: values.offset,
    }
    if (values.roles) {
      // $FlowFixMe
      params.filters = JSON.stringify({ roles: Array.isArray(values.roles) ? [...values.roles] : [values.roles] })
    }

    if (values.search) {
      // $FlowFixMe
      params.search = JSON.stringify({
        fields: ['email', 'first_name', 'last_name', 'roles', 'league_name', 'state', 'country', 'zip_code', 'full_name'],
        values: [values.search],
      })
    }

    try {
      let { data } = yield call(http, 'admin/users', {
        method: 'GET',
        params,
      })

      data = { ...data, search: values.search, page: values.page || 1 }
      if (values.roles) {
        // $FlowFixMe
        data = {
          ...data,
          filters: JSON.stringify({
            roles: Array.isArray(values.roles)
              ? [...values.roles]
              : [values.roles],
          }),
        }
      }

      yield put(getAccounts.success(data))
    } catch (e) {
      yield put(getAccounts.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(stopAsyncValidation(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchGetAccounts)]
