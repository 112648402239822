// @flow
import React from 'react'
// styles
import { StyledBlock } from './dashboardBlockStyles'

// types
import type { DashboardBlockTypes } from './DashboardBlockTypes'

const DashboardBlock = (props: DashboardBlockTypes) => {
  return (
    <StyledBlock>
      <div className="block-header">
        <h4 className="title">{props.title}</h4>
      </div>
      <div className="block-body">
        {props.children}
      </div>
    </StyledBlock>
  )
}

export default DashboardBlock
