// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import {
  getLeagueOrders,
  getOrders,
  getOrdersStatuses,
  getShippingMethods,
  getOrdersExportAvailableColumns,
  saveOrdersExportFilter,
  getOrdersExportFilters,
  setOrdersExportFilter,
  deleteOrdersExportFilter,
} from 'src/redux/actions/orders/orders'

const withOrders = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithOrdersComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ orders }) => ({ orders })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getLeagueOrders: values => dispatch(getLeagueOrders.request({ leagueId: +ownProps.match.params.leagueId, ...values })),
    getOrders: values => dispatch(getOrders.request({ ...values })),
    getOrdersStatuses: () => dispatch(getOrdersStatuses.request()),
    getShippingMethods: () => dispatch(getShippingMethods.request()),
    getOrdersExportAvailableColumns: values => dispatch(getOrdersExportAvailableColumns.request(values)),
    saveOrdersExportFilter: values => dispatch(saveOrdersExportFilter.request(values)),
    getOrdersExportFilters: values => dispatch(getOrdersExportFilters.request(values)),
    setOrdersExportFilter: values => dispatch(setOrdersExportFilter.success(values)),
    deleteOrdersExportFilter: values => dispatch(deleteOrdersExportFilter.request(values)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithOrdersComponent)
}

export default withOrders
