// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import {
  DISABLE_ADMIN,
  CREATE_ADMIN,
  ENABLE_ADMIN,
  enableAdmin,
  disableAdmin,
  getAllAdmins,
  createAdmin,
} from 'src/redux/actions/adminAccounts/adminAccounts'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchDisableAdmin(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { adminId } } = yield take(DISABLE_ADMIN.REQUEST)

    try {
      yield call(http, `admin/account/${adminId}`, {
        method: 'DELETE',
      })

      yield put(disableAdmin.success())
      yield put(getAllAdmins.request())
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      yield put(disableAdmin.error(e.message))
    }
  }
}

function * watchEnableAdmin(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { adminId } } = yield take(ENABLE_ADMIN.REQUEST)

    try {
      yield call(http, `admin/account/${adminId}/restore`, {
        method: 'PATCH',
      })

      yield put(enableAdmin.success())
      yield put(getAllAdmins.request())
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      yield put(enableAdmin.error(e.message))
    }
  }
}

function * watchCreateAdmin(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(CREATE_ADMIN.REQUEST)

    const data = { zip: '10001', country: 'US', state: 'NM', ...payload }

    try {
      yield call(http, 'admin/account', {
        body: JSON.stringify(data),
        method: 'POST',
      })

      yield put(createAdmin.success())
      yield put(getAllAdmins.request())
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      yield put(createAdmin.error(e.errors || []))
    }
  }
}

export default [
  fork(watchCreateAdmin),
  fork(watchDisableAdmin),
  fork(watchEnableAdmin),
]
