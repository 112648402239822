
// @flow
import React, { useEffect } from 'react'
import { getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'

import config from 'src/config/formsConfig'

import { ButtonPrimary } from 'src/common/components'

// styles
import {
  TableExportSubmitBlockStyle,
} from '../TableExportStyles'

// types
import type {
  TableExportSubmitBlockType,
} from '../TableExportTypes'

const TableExportSubmitBlock = (props: TableExportSubmitBlockType) => {
  const {
    disabled,
    formVals,
    outputArray,
    fetchDataRequest,
    fetchedResult,
    isSuccessful,
    loadData,
  } = props

  useEffect(() => {
    if (!isSuccessful) return
    const [{ data }, { exportJsonToExcel }] = fetchedResult

    outputArray.forEach(({ name, valueFormat }) => {
      if (valueFormat) {
        data.forEach(row => {
          row[name] = valueFormat(row[name], row)
        })
      }
    })

    exportJsonToExcel({
      data,
      aliases: outputArray,
      filename: formVals.filename,
      bookType: formVals.fileformat,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessful])

  return (
    <TableExportSubmitBlockStyle>
      <ButtonPrimary
        buttonText="Export"
        size="small"
        disabled={disabled}
        action={() => {
          loadData(() => Promise.all([
            fetchDataRequest(outputArray.map(({ name }) => name)),
            import('src/services/export2Excel'),
          ]).then(data => ({ data })))
        }}/>
    </TableExportSubmitBlockStyle>
  )
}

// Container
const mapStateToProps = state => {
  const formVals = getFormValues(config.tableExportForm.id)(state) || {}
  return {
    formVals,
  }
}

export default compose(
  connect(mapStateToProps),
)(TableExportSubmitBlock)

