// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_PAYMENT_TOKEN, getPaymentToken } from 'src/redux/actions/payments/payments'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetPymentToken(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_PAYMENT_TOKEN.REQUEST)

    try {
      const { data: { clientToken } } = yield call(http, 'admin/payment/client-token', {
        method: 'GET',
      })

      yield put(getPaymentToken.success(clientToken))
    } catch (e) {
      yield put(getPaymentToken.error(e.message))
    }
  }
}

export default [fork(watchGetPymentToken)]
