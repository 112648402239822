import styled from 'styled-components'

export const StyledProgress = styled.div`
    display: flex;
    margin: 10px 0 30px;
    
    .step {
        background-color: #ebedef;
        padding: 10px 12px 10px 28px;
        position: relative;
        margin-right: 20px;
        margin-left: -20px;
        
        :first-child {
            margin-left: 0;
            padding: 10px 12px;
        }
        
        svg {
            margin-right: 8px;
        }
        
        :after {
            border-color: transparent transparent transparent #ebedef;
            border-style: solid;
            border-width: 18px 0 18px 15px;
            content: '';
            display: inline-flex;
            height: 0;
            position: absolute;
            width: 0;
            right: -15px;
            top: 0;
            z-index: 99;
        }
        
        &.passed-step {
            background-color: #45bb7e;
            color: #fff;
            
            :after {
                border-color: transparent transparent transparent #45bb7e;
            }
            
            svg {
                
                path {
                    fill: #fff;
                }
                polygon {
                    fill: #fff;
                }
            }
        }
        
        &.current-step {
            background-color: #81c8e1;
            color: #fff;
            
            :after {
                border-color: transparent transparent transparent #81c8e1;
            }
        }
    }
    
    @media (max-width: 1060px) {
        flex-direction: column;
        
        .step {
            margin-bottom: 5px;
            margin-left: 0;
            margin-right: 12px;
            padding: 10px 12px;
        }
    }
`
