// @flow
import React from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import StarIcon from '@material-ui/icons/Star'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import { Link } from 'react-router-dom'
import getRole from 'src/helpers/getRolesTitle'
// styles
import { StyledCard } from './userCardStyles'

// types
import type { UserCardTypes } from './UserCardTypes'

const UserCard = (props: UserCardTypes) => (
  <StyledCard>
    <Link to={`/users/${props.id}/user-settings`} className="card-layout">
      <div className="card-header">
        <div className="title">
          <h5>{props.userFirstName} {props.userLastName}</h5>
        </div>
      </div>
      <div className="card-body">
        <div className="email">
          <span className="label">
            <EmailIcon />
            Email: </span>
          <span className="value">{props.email}</span>
        </div>
        <div className="role">
          <span className="label">
            <AccountCircleIcon />
            Role: </span>
          {props.roles && props.roles.map((role, index) => (
            <span
              key={index}
              className="value"
            >
              {getRole(role)}{index < props.roles.length - 1 ? ',' : ''}
            </span>
          ))}
        </div>
        <div className="league">
          <span className="label">
            <StarIcon />
            League: </span>
          {props.leagueName && props.leagueName.map((leagueName, index) => (
            <span
              key={index}
              className="value"
            >
              {leagueName}{index < props.leagueName.length - 1 ? ',' : ''}
            </span>
          ))}
        </div>
        <div className="location">
          <div className="location-item">
            <span className="label">
              <LocationOnIcon />
              State: </span>
            <span className="value">{props.state}</span>
          </div>
          <div className="location-item">
            <span className="label">Region: </span>
            <span className="value">{props.region}</span>
          </div>
          <div className="location-item">
            <span className="label">Zip Code: </span>
            <span className="value">{props.zip}</span>
          </div>
        </div>
        <div>
          {props.phone && props.roles && props.roles.includes('LEAGUE_OWNER') && (
            <div className="location">
              <div className="location-item">
                <span className="label">
                  <PhoneIcon />
                  Phone: </span>
                <span className="value">{props.phone}</span>
              </div>
            </div>
          )
          }
        </div>
      </div>
    </Link>
  </StyledCard>
)

export default UserCard

