// @flow
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withUser } from 'src/decorators'
import { getToken } from 'src/services/user'

// types
import type { PublicRouteProps } from './PublicRouteTypes'

const PublicRoute = ({
  component: Component,
  user,
  ...rest

}: PublicRouteProps) => {
  return (
    <Route
      {...rest}
      render={props =>
        <>
          {user && getToken()
            ? (
              <Redirect to="/" />
            )
            : (
              <div>
                <Component {...props} />
              </div>
            )}
        </>
      }
    />
  )
}

export default withUser(PublicRoute)
