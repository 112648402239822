// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { findIndex, isRegExp } from 'lodash'
import { GET_SHIPPING_METHODS, getShippingMethods } from 'src/redux/actions/orders/orders'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

const shippingMethodsOrder = [
  /FedEx Ground/i,
  /FedEx 2.*Day/i,
  /FedEx Standard Overnight/i,
  /\bFedEx\b/i,
  'UPS Ground',
  /UPS 2.*Day/i,
  /UPS 1.*Day/i,
  /\bUPS\b/i,
  /VT.*Ground/i,
  /VT.*Overnight/i,
  /\bVT\b/,
  'Other',
  'Unknown',
  'None',
]

const getMethodsPositionIdx = method => {
  const idx = findIndex(shippingMethodsOrder, val => {
    if (isRegExp(val)) {
      return val.test(method)
    }
    return method.toLowerCase() === val.toLowerCase()
  })

  return idx === -1
    ? shippingMethodsOrder.length
    : idx
}

function * watchGetShippingMethods(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_SHIPPING_METHODS.REQUEST)

    try {
      const { data } = yield call(http, 'admin/orders/shipping-methods', {
        method: 'GET',
      })

      const orderredData = data.sort((a, b) => {
        const sortDiff = getMethodsPositionIdx(a) - getMethodsPositionIdx(b)
        return sortDiff === 0
          ? Number(a > b) - 0.5
          : sortDiff
      })

      yield put(getShippingMethods.success(orderredData))
    } catch (e) {
      yield put(getShippingMethods.error(e.message))
    }
  }
}

export default [fork(watchGetShippingMethods)]
