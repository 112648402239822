import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledPagination = styled.div`
    margin: 30px 0; 

    .pagination {
        display: flex;
        justify-content: center;
    }
    .children {
        margin-left: 15px;
    }
  
    .page-link {
        border: 1px solid #ebedef;
        color: inherit;
        padding: 8px 10px;
    
        &.is-current {
            background-color: rgb(218,229,251);
            
            :hover {
                cursor: default;
                text-decoration: none;
            }
        }
        
        :hover {
            filter: brightness(1);
        }
    }

    .page-num {
        flex: 0 0 auto;
    }

    .page-link {
        color: inherit;
    }
  
    .page-item {
      display: flex;
      align-items: center;
    }

    @media ${device.tablet} {
      .pagination {
        justify-content: center;
      }

      .page-item {
        display: inline-table;
      }
      
      .page-item.edge-jump {
        display: none;
      }

      .page-item.flex {
        display: flex;
        min-width: 80px;
      }
    }
  
    @media ${device.mobileL} {
      .page-item.hide-mobile {
        display: none;
      }
    }
`
