// @flow
import type { BasicRedux } from 'src/redux/actions/bulkOrder/bulkOrderTypes'
import {
  GET_ORDER,
  UPDATE_ORDER,
  RESET_BULK_ORDER,
  MANUAL_ORDER_SUBMIT,
  DOWNLOAD_ORDER_PDF,
  CANCEL_PENDING_ORDER,
  SUBMIT_ORDER_TO_VENDOR,
  GET_ORDER_TRACKING_NUMBER,
  DOWNLOAD_ORDER_INVOICE_PDF,
} from 'src/redux/actions/bulkOrder/bulkOrder'

import type { OrderType, OrderedItemType } from 'src/common/constants.types'
import type { ItemsSummaryType } from 'src/redux/reducers/makeOrder'

export type PaymentOrderType = {
    card: ?string,
    card_type: ?string,
    type: ?string,
    status: ?string,
    amount: ?string,
    description: ?string,
    descriptor: ?string,
    type_description: ?string,
    invoice_number: ?string,
    created_at: string,
    currency: ?string
};

export type BulkOrderState = {
    isLoading: boolean,
    order: ?OrderType,
    payments: PaymentOrderType[],
    items: OrderedItemType[],
    summary: ItemsSummaryType,
    orderTrackingNumberError: any,
    submitToVendorError: ?string
};

const initialState = {
  isLoading: false,
  order: null,
  items: [],
  payments: [],
  summary: {
    total_amount: {},
    total_cost: {},
    additional: {
      brown_balls: null,
      blue_balls: null,
      mini_balls: null,
      belts: null,
      cost: 0,
    },
    custom_info: {
      sublimated_jersey_amount: 0,
      core_jersey_amount: 0,
      core_shorts_amount: 0,
      sublimated_shorts_amount: 0,
    },
  },
  orderTrackingNumberError: null,
  submitToVendorError: null,
  balance: null,
}

const bulkOrder = (state: BulkOrderState = initialState, action: BasicRedux): BulkOrderState => {
  switch (action.type) {
    case CANCEL_PENDING_ORDER.REQUEST:
    case SUBMIT_ORDER_TO_VENDOR.REQUEST:
    case MANUAL_ORDER_SUBMIT.REQUEST:
    case GET_ORDER.REQUEST:
    case DOWNLOAD_ORDER_PDF.REQUEST:
    case DOWNLOAD_ORDER_INVOICE_PDF.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        submitToVendorError: null,
      }
    }

    case GET_ORDER_TRACKING_NUMBER.REQUEST:
    {
      return {
        ...state,
        orderTrackingNumberError: null,
      }
    }

    case GET_ORDER_TRACKING_NUMBER.FAILURE:
    {
      return {
        ...state,
        orderTrackingNumberError: action.payload,
      }
    }

    case GET_ORDER.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        order: { ...action.payload.order },
        items: [...action.payload.items],
        payments: [...action.payload.payments],
        summary: { ...action.payload.summary },
      })
    }

    case UPDATE_ORDER.SUCCESS:
    {
      return ({
        ...state,
        order: {
          ...state.order,
          ...action.payload,
        },
      })
    }

    case SUBMIT_ORDER_TO_VENDOR.FAILURE:
    {
      return {
        ...state,
        submitToVendorError: action.payload,
        isLoading: false,
      }
    }
    case SUBMIT_ORDER_TO_VENDOR.ERROR_RESET:
    {
      return {
        ...state,
        submitToVendorError: null,
      }
    }

    case RESET_BULK_ORDER: {
      return { ...initialState }
    }

    case SUBMIT_ORDER_TO_VENDOR.SUCCESS:
    case CANCEL_PENDING_ORDER.SUCCESS:
    case CANCEL_PENDING_ORDER.FAILURE:
    case DOWNLOAD_ORDER_PDF.SUCCESS:
    case DOWNLOAD_ORDER_PDF.FAILURE:
    case DOWNLOAD_ORDER_INVOICE_PDF.SUCCESS:
    case DOWNLOAD_ORDER_INVOICE_PDF.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
      }
    }

    default: {
      return state
    }
  }
}

export default bulkOrder
