// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { BasicRedux } from 'src/redux/actions/globalSettings/globalSettingsTypes'

export const EDIT_SEASON_FREE_SHIPPING_COUNT = requestTypeCreator('EDIT_SEASON_FREE_SHIPPING_COUNT')
export const GET_LEAGUE_SEASONS = requestTypeCreator('EDIT_SEASON_FREE_SHIPPING_COUNT')
export const EDIT_LEAGUE_SEASON = requestTypeCreator('EDIT_LEAGUE_SEASON')
export const DELETE_LEAGUE_SEASON = requestTypeCreator('DELETE_LEAGUE_SEASON')

export const editSeasonFreeShippingCount = {
  error: (payload: any): BasicRedux => ({ type: EDIT_SEASON_FREE_SHIPPING_COUNT.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: EDIT_SEASON_FREE_SHIPPING_COUNT.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: EDIT_SEASON_FREE_SHIPPING_COUNT.SUCCESS, payload }),
}

export const getLeagueSeasons = {
  error: (payload: any): BasicRedux => ({ type: GET_LEAGUE_SEASONS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_LEAGUE_SEASONS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_LEAGUE_SEASONS.SUCCESS, payload }),
}

export const editSeason = {
  error: (payload: any): BasicRedux => ({ type: EDIT_LEAGUE_SEASON.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: EDIT_LEAGUE_SEASON.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: EDIT_LEAGUE_SEASON.SUCCESS, payload }),
}

export const deleteSeason = {
  error: (payload: any): BasicRedux => ({ type: DELETE_LEAGUE_SEASON.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: DELETE_LEAGUE_SEASON.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: DELETE_LEAGUE_SEASON.SUCCESS, payload }),
}
