// @flow

import { stopSubmit } from 'redux-form'
import { fork, put, take } from 'redux-saga/effects'
import config from 'src/config/formsConfig'
import { EDIT_USER_EMAIL, editUserEmail } from 'src/redux/actions/accounts/accounts'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchEditUserEmail(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { email, id },
    } = yield take(EDIT_USER_EMAIL.REQUEST)
    const formId = config.changeUserEmail.id
    try {
      yield http(`admin/user/${id}/change-email`, {
        body: JSON.stringify({
          email,
        }),
        method: 'POST',
      })
      yield put(editUserEmail.success(true))
      yield put(editUserEmail.success(false))
    } catch (e) {
      yield put(editUserEmail.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchEditUserEmail)]
