// @flow
import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { ACHIcon } from 'src/assets/icons'
import Checkbox from '@material-ui/core/Checkbox'
import Options from './fragments/options'

// styles
import {
  StyledACHMethods,
  StyledPrimaryText,
  StyledSecondaryText,
  StyledWarning,
  StyledCircleCheckedFilled,
  StyledCircleChecked,
} from './ACHMethodsStyles'

// types
import type { ACHMethodsPropsType } from './ACHMethodsTypes'

export default function ACHMethods({
  paymentMethods,
  checked,
  setChecked,
  deletePaymentMethod,
  editable,
}: ACHMethodsPropsType) {
  return (
    <StyledACHMethods>
      {Array.isArray(paymentMethods?.ACH) && paymentMethods?.ACH?.length
        ? (
          <List>
            <Options
              checked={checked}
              editable={editable}
              deletePaymentMethod={deletePaymentMethod}
            >
              <div className="list">
                {/* $FlowFixMe */}
                {paymentMethods?.ACH.map(item => {
                  const { globalId, last4, accountHolderName, verified } = item
                  const labelId = `checkbox-list-label-${globalId}`
                  const isChecked = checked?.globalId === globalId
                  const canClick = editable || verified
                  return (
                    <div key={labelId} className="list-item-wrapper">
                      <ListItem
                        className={
                          isChecked ? 'left-wrapper braintree-method active' : ' left-wrapper braintree-method'
                        }
                        key={globalId}
                        role={undefined}
                        dense
                        button
                        onClick={canClick ? setChecked(item) : undefined}
                        disableRipple
                      >
                        <ACHIcon />
                        <ListItemText
                          id={labelId}
                          primary={
                            <StyledPrimaryText>Ending in {last4}</StyledPrimaryText>
                          }
                          secondary={
                            <StyledSecondaryText>
                              {accountHolderName}
                            </StyledSecondaryText>
                          }
                        />
                        {!verified && (
                          <ListItemText
                            id={labelId}
                            primary={<StyledWarning>Not verified</StyledWarning>}
                          />
                        )}
                        {isChecked && (
                          <ListItemIcon>
                            <Checkbox
                              style={{
                                backgroundColor: 'transparent',
                                transition:
                            'transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                              }}
                              checked={isChecked}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              icon={<StyledCircleChecked />}
                              checkedIcon={<StyledCircleCheckedFilled />}
                            />
                          </ListItemIcon>
                        )}
                      </ListItem>
                    </div>
                  )
                })}
              </div>
            </Options>
          </List>
        ) : (
          <>
            <h4>
              You can now complete payment directly from your bank account. To pay
              via ACH, please configure your account details.
            </h4>
            <Options
              checked={checked}
              editable={editable}
              deletePaymentMethod={deletePaymentMethod}
            />
          </>
        )}
    </StyledACHMethods>
  )
}
